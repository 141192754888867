import { Box, Heading } from 'grommet';
import type { EdgeType } from 'grommet/utils';
import type { CSSProperties } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router';

import config from '../config';
import robo404 from '../images/robo_404_800px.png';
import { ErrorReporter, isSSR } from '../utils';

const headingMargin: EdgeType = { vertical: 'small' };
const linkStyle: CSSProperties = { fontSize: '24px' };

function NotFoundBanner() {
  useEffect(() => {
    if (!isSSR) {
      ErrorReporter.error(`Client-side 404 at ${window?.location?.href}`);
    }
  }, []);

  return (
    <Box gap="xsmall" justify="center" align="center" margin="xlarge">
      <img src={robo404} alt="Not found" width={350} loading="eager" />
      <Heading level="2" margin={headingMargin}>404</Heading>
      <Link to={config.defaultPath} style={linkStyle}>Return to Board</Link>
    </Box>
  );
}

export default NotFoundBanner;
