import {
  Box,
  Button,
  DropButton,
  Heading,
  Image,
  Text,
} from 'grommet';
import {
  CatalogOption,
  Checkbox,
  CheckboxSelected,
  Configure,
  Edit,
  FormClose,
  More,
  Pin,
  Share,
  Update,
} from 'grommet-icons';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { dispatch } from 'use-bus';

import { usePlatformMutation, usePlatformQueryClient, useSessionStore } from '../../hooks';
import { isInIframe, isMobilePlatform, track } from '../../utils';
import TimeAgo from '../utils/TimeAgo';

const HoverBox = styled(Box)`
  height: 36px;
`;

const HoverDropButton = styled(DropButton)`
  display: ${(props) => (props.open || !props.hideMenuButton ? 'block' : 'none')};
  ${HoverBox}:hover & {
    display: block;
  }
`;

function CardTop({
  id,
  title,
  settings,
  imageUrl,
  metadata,
  notification = false,
  closeToNotifications = false,
  dismissable = false,
  hideMenuButton = false,
  iframe = false,
}) {
  const navigate = useNavigate();

  const { mutateAsync: pin } = usePlatformMutation('/userworkplace/changestatus', {
    method: 'POST',
  });

  const { mutateAsync: subscribe } = usePlatformMutation('/userworkplace/subscription', {
    method: 'POST',
  });

  const session = useSessionStore((state) => state.session);

  const [open, setOpen] = useState();

  const toggleSidebar = () => {
    if (closeToNotifications) {
      dispatch({
        type: 'sidebar.toggle', title: 'Notifications', open: true, wipeDefault: true,
      });
    } else {
      dispatch({ type: 'sidebar.toggle' });
    }
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const queryClient = usePlatformQueryClient();

  const togglePinned = async () => {
    track('Card_Menu_Board', metadata.UserActions.pinned ? 'Unpin' : 'Pin');

    setOpen(false); // close drop down

    queryClient.setQueryData('/cardcatalog', (oldData) => oldData?.Data?.items?.filter((item) => item.id !== id));

    await pin({ id, pinned: !metadata.UserActions.pinned });

    await queryClient.invalidateQueries('/userworkplace/usercards');
    await queryClient.invalidateQueries('/cardcatalog');
  };

  const toggleSubscribed = async () => {
    track('Card_Menu_Board', metadata.UserActions.hasUnread ? 'Unsubscribe' : 'Subscribe');

    setOpen(false);

    await subscribe({
      id: metadata.id,
      subscription: !metadata.UserActions.hasUnread,
    });

    await queryClient.invalidateQueries('/userworkplace/usercards');
    await queryClient.invalidateQueries('/cardcatalog');
  };

  const configure = (newId, newTitle) => {
    setOpen(false); // close drop down before opening sidebar
    dispatch({
      type: 'sidebar.toggle', newId, newTitle, settings, component: metadata.configuration?.settingsPage, open: true,
    });
  };

  const share = () => {
    track('Card_Menu_Share', metadata.Name);
    setOpen(false);
    dispatch({ type: 'modal.share.toggle', metadata });
  };

  const openDesigner = () => {
    dispatch({ type: 'sidebar.toggle', open: false });
    track('Card_Menu_Designer', metadata.Name);

    const url = `/app/designer/card/${metadata.Id}`;

    if (isInIframe()) {
      window.open(url);
    } else {
      navigate(url);
    }
  };

  const editNotebook = () => {
    dispatch({ type: 'sidebar.toggle', open: false });
    track('Card_Menu_Notebook', metadata.Name);

    const url = `/app/notebook/${metadata.Id}`;

    if (isInIframe()) {
      window.open(url);
    } else {
      navigate(url);
    }
  };

  const refresh = async () => {
    await queryClient.invalidateQueries(`/gateway/card/${id}`);
    setOpen(false);
  };

  // Store date reference for each card
  const dateRef = metadata.expressions?._date || (new Date()).toJSON();

  // don't show title for qacards
  const hide = metadata?.Type === 'qacard';
  const connector = metadata?.connector || metadata?.contentItem?.Properties?.connector2?.connector;

  const isIframeOrMobile = isMobilePlatform || isInIframe();
  const hideTitle = metadata.__compact;

  return (
    !hide && (
      <HoverBox align="center" justify="start" direction="row" alignSelf="start" fill="horizontal">
        <Box direction="row" align="center" fill="horizontal" className="card-drag-handle">
          {imageUrl && <Image src={imageUrl} style={{ height: '24px', paddingRight: '6px' }} />}
          {!hideTitle && (
            <Heading
              size="16px"
              margin="none"
              textAlign="center"
              weight={500}
              style={{
                overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
              }}
            >
              {title}
            </Heading>
          )}
        </Box>
        {!notification && !iframe && (
          <HoverDropButton
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            dropAlign={{ top: 'bottom', right: 'right' }}
            icon={<More />}
            hideMenuButton={hideMenuButton}
            dropContent={(
              <Box background="background-front">
                <Box gap="xsmall" margin={{ top: 'xsmall', bottom: 'xsmall' }}>
                  <Button onClick={togglePinned} hoverIndicator margin={{ left: 'xsmall', right: 'xsmall' }}>
                    <Box align="center" gap="small" margin="xsmall" direction="row">
                      <Pin />
                      {metadata.UserActions?.pinned
                        ? (
                            <Text size="small" weight={500}>
                              Unpin from Board
                            </Text>
                          )
                        : (
                            <Text size="small" weight={500}>
                              Pin to Board
                            </Text>
                          )}
                    </Box>
                  </Button>
                  <Button onClick={toggleSubscribed} hoverIndicator margin={{ left: 'xsmall', right: 'xsmall' }}>
                    <Box align="center" gap="small" margin="xsmall" direction="row">
                      {metadata.UserActions?.hasUnread ? <CheckboxSelected /> : <Checkbox />}
                      <Text size="small" weight={500}>
                        {metadata.UserActions?.hasUnread ? 'Subscribed ' : 'Subscribe '}
                        to notifications
                      </Text>
                    </Box>
                  </Button>
                  <Button onClick={refresh} hoverIndicator margin={{ left: 'xsmall', right: 'xsmall' }}>
                    <Box style={{ minWidth: '220px' }} align="center" gap="small" margin="xsmall" direction="row">
                      <Update />
                      <Text size="small" weight={500}>
                        Refresh now
                      </Text>
                      <TimeAgo date={dateRef} size="12px" weight="bold" margin={{ left: 'xsmall' }} />
                    </Box>
                  </Button>
                  {metadata.configuration?.settingsPage && (
                    <Button onClick={() => configure(id, title)} hoverIndicator margin={{ left: 'xsmall', right: 'xsmall' }}>
                      <Box align="center" gap="small" margin="xsmall" direction="row">
                        <Configure />
                        <Text size="small" weight={500}>
                          Configure this Card
                        </Text>
                      </Box>
                    </Button>
                  )}
                  {!isIframeOrMobile && (
                    <Button onClick={share} hoverIndicator margin={{ left: 'xsmall', right: 'xsmall' }} className="shareButton">
                      <Box align="center" gap="small" margin="xsmall" direction="row">
                        <Share />
                        <Text size="small" weight={500}>
                          Share
                        </Text>
                      </Box>
                    </Button>
                  )}
                  {session?.Roles?.includes('Administrator') && !isIframeOrMobile && (
                    <>
                      <Button
                        onClick={openDesigner}
                        hoverIndicator
                        margin={{ left: 'xsmall', right: 'xsmall' }}
                      >
                        <Box align="center" gap="small" margin="xsmall" direction="row">
                          <Edit />
                          <Text size="small" weight={500}>
                            Edit in Designer
                          </Text>
                        </Box>
                      </Button>
                      {connector && connector.startsWith('notebook') && (
                        <Button
                          onClick={editNotebook}
                          hoverIndicator
                          margin={{ left: 'xsmall', right: 'xsmall' }}
                        >
                          <Box align="center" gap="small" margin="xsmall" direction="row">
                            <CatalogOption />
                            <Text size="small" weight={500} margin={{ right: 'small' }}>
                              Edit Notebook
                            </Text>
                          </Box>
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            )}
          />
        )}
        {dismissable && <Button icon={<FormClose />} margin={{ left: 'xsmall' }} onClick={toggleSidebar} />}
      </HoverBox>
    )
  );
}

export default CardTop;
