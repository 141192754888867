import { memo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';

import { apiUrl, defaultFetchOpts } from '../../config';
import { useToken } from '../../hooks';

function Start() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = useToken();

  const { search } = location;

  let id = '';
  let returnUrl = window.location.origin;

  if (search) {
    const params = new URLSearchParams(search);

    if (params.get('path')) {
      returnUrl += params.get('path');
    }

    if (params.get('id')) {
      id = params.get('id');
    }
  }

  const fetchAndRedirect = useCallback(async () => {
    if (!token) {
      return;
    }

    // eslint-disable-next-line react-compiler/react-compiler
    defaultFetchOpts.headers.Authorization = `Bearer ${token.access_token}`;

    const result = await fetch(`${apiUrl}/oauth2/redirect/${id}?returnUrl=${encodeURIComponent(returnUrl)}&redirect_uri=${window.location.origin}/app/auth/exchange`, defaultFetchOpts);

    if (!result.ok) {
      navigate(returnUrl);
    }

    const json = await result.json();

    if (json.ErrorCode !== 0) {
      navigate(returnUrl);
    }

    navigate(json.Data.url);
  }, [id, navigate, returnUrl, token]);

  useEffect(() => {
    fetchAndRedirect();
  }, [fetchAndRedirect, token?.access_token]);

  return null;
}

export default memo(Start);
