import { Text } from 'grommet';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';

import { apiUrl, defaultFetchOpts } from '../config';
import { useSessionStore } from '../hooks';

const opts = defaultFetchOpts;

function EmbedSession({ children }) {
  const location = useLocation();

  const session = useSessionStore((state) => state.session);
  const setSession = useSessionStore((state) => state.setSession);

  const checkAuthentication = useCallback(async () => {
    const params = new URLSearchParams(location.search);
    const tokensrc = params.get('tokensrc');
    let token = params.get('token');

    if (tokensrc) {
      token = `${tokensrc}:${token}`;
    }

    opts.headers.Authorization = `Bearer ${token}`;

    const response = await fetch(`${apiUrl}/session/myprofile`, opts);
    const data = await response.json();

    if (!response.ok || !data.Data?.authenticated) {
      return;
    }

    setSession(data.Data);
  }, [location.search, setSession]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  if (session.authenticated) {
    return children;
  }

  if (session.authenticated === false) {
    return <Text>Authentication failed</Text>;
  }

  return null;
}

export default EmbedSession;

export const withSession = (Component) => function ComponentWithSession(props) {
  return (
    <EmbedSession>
      <Component {...props} />
    </EmbedSession>
  );
};
