import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { isSSR } from '../utils';

// Color Scheme handler
export const useColorScheme = () => {
  // Check if a color mode is stored in local storage
  if (isSSR ? null : !window.localStorage.getItem('colorMode')) {
    // Set auto mode to local storage
    window.localStorage.setItem('colorMode', 'auto');
  }

  // Store current mode from local storage
  const localStorageColorMode = isSSR ? null : window.localStorage.getItem('colorMode');

  // Set initial state from local storage value or auto as a fallback
  const [colorMode, setColorMode] = useState(localStorageColorMode || 'auto');

  // Detect changes to colorMode
  useEffect(() => {
    if (isSSR) {
      return;
    }

    // Watch local storage for changes
    const storageListener = () => {
      // Store selected mode from storage
      const savedMode = isSSR ? null : window.localStorage.getItem('colorMode');
      // Update mode state
      setColorMode(savedMode);
    };

    window.addEventListener('storage', storageListener);

    // Watch brower window for changes to preference on auto mode
    const changeListener = (e) => {
      // Store current mode for checking
      const isAutoMode = isSSR ? null : window.localStorage.getItem('colorMode');
      // Check if auto mode
      if (isAutoMode === 'auto') {
        // Match color mode from change event
        const autoColorMode = e.matches ? 'dark' : 'light';
        // Update to new color mode
        setColorMode(autoColorMode);
      }
    };

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', changeListener);

    return () => {
      // Remove listeners
      window.removeEventListener('storage', storageListener);
      window.removeEventListener('change', changeListener);
    };
    // Dependencies
  }, [colorMode]);

  const location = useLocation();

  if (location.pathname.includes('app/designer')) {
    return true;
  }

  // Check for auto mode preference in account.jsx
  if (colorMode === 'auto') {
    // Check browser/system preference
    const userMode = isSSR ? null : window.matchMedia('(prefers-color-scheme: dark)');
    // Check for null (ssr)
    if (userMode !== null) {
      // If dark mode is matched, set dark
      if (userMode.matches) {
        const darkMode = 'dark';
        // Set mode, return through mode checkers again
        setColorMode(darkMode);
        // Else set light mode as default
      } else {
        const defaultMode = 'light';
        // Set mode, return through mode checkers again
        setColorMode(defaultMode);
      }
    }
  }
  // Check for light mode preference in account.jsx
  if (colorMode === 'light') {
    window.localStorage.setItem('selectedColorMode', 'light');
    // Config colorScheme in app.jsx - True = Light mode
    return true;
  }
  // Check for dark mode preference in account.jsx
  if (colorMode === 'dark') {
    window.localStorage.setItem('selectedColorMode', 'dark');
    // Config colorScheme in app.jsx - False = Dark mode
    return false;
  }
};
