import { Anchor, Sidebar } from 'grommet';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import useBus, { dispatch } from 'use-bus';

import { hostUrl } from '../../config';
import { isSSR } from '../../utils';

const StyledSidebar = styled(Sidebar)`
  padding: 2rem 0.5rem;
  border-right: 1px solid #d5d5d5;
`;

const StyledAnchor = styled(Anchor)`
  color: black;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 400;
  padding: 4px 28px;
  margin: 8px 0px;
  ${(props) => props.active && 'background-color: rgba(0,0,0,0.15);'}
  &:hover {
    color: black;
    background-color: rgba(0,0,0,0.25);
  }
  &:focus {
    background-color: rgba(0,0,0,0.3);
  }
`;

export const ADMIN_PAGES = {
  // GetStarted: '/app/admin/getstarted',
  // Cards: '/app/cards',
  // Channels: '/app/channels',
  // Directory: '/app/directory',
  Connectors: '/app/grid/connector',
  Flows: '/app/workflow',
  AiTraining: '/app/feedback/beta/aiengine',
  // CardTemplates: '/app/cardtemplates',
  Roles: '/app/roles',
  // InviteUsers: '/app/users#add',
  Users: '/app/users',
  ApiLog: '/app/usageinformationrecords',
  CorsSetting: '/app/grid/cors',
  Settings: '/app/settings',
  PostmanConnectors: '/app/postmancollections',
};

export const isAdminPage = (url) => Object.values(ADMIN_PAGES).filter((p) => url.toLowerCase().includes(p)).length > 0;

function AdminMenu() {
  const [modalPath, setModalPath] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSSR && (location.search.includes('frame=1') || location.pathname.includes('app/modal'))) {
      document.querySelectorAll('a').forEach((el) => {
        if (!el.href || el.href.includes('frame')) {
          return;
        }

        const parts = el.href.split('#');
        let newHref = parts[0];

        if (el.href.includes('?')) {
          newHref += '&frame=1';
        } else {
          newHref += '?frame=1';
        }

        if (parts.length > 1) {
          newHref += `#${parts[1]}`;
        }

        el.href = newHref;
      });
    }
  }, [location.pathname, location.search]);

  const onNavigate = (href) => {
    if (!isSSR && (location.search.includes('frame=1') || location.pathname.includes('app/modal'))) {
      const parts = (hostUrl + href).split('#');
      let modal = parts[0];

      if (modal.includes('?')) {
        modal += '&frame=1';
      } else {
        modal += '?frame=1';
      }

      if (parts.length > 1) {
        modal += `#${parts[1]}`;
      }

      dispatch({
        type: 'iframe.open',
        url: modal,
      });

      return;
    }

    navigate(hostUrl + href);
  };

  useBus('modalpath.change', (e) => {
    setModalPath(e.path);
  });

  const checkPath = modalPath || location.pathname;

  return (
    <StyledSidebar width="215px" fill="vertical" justify="start">
      <StyledAnchor onClick={() => onNavigate(ADMIN_PAGES.Connectors)} label="Connectors" />
      <StyledAnchor onClick={() => onNavigate(ADMIN_PAGES.Flows)} label="Flows" />
      <StyledAnchor onClick={() => onNavigate(ADMIN_PAGES.AiTraining)} label="AI Training" />
      {/* <StyledAnchor onClick={() => onNavigate(ADMIN_PAGES.CardTemplates)} label="Card Templates" /> */}
      <StyledAnchor onClick={() => onNavigate(ADMIN_PAGES.Roles)} label="Roles" />
      <StyledAnchor onClick={() => onNavigate(ADMIN_PAGES.Users)} label="Users" />
      <StyledAnchor onClick={() => onNavigate(ADMIN_PAGES.ApiLog)} label="API Log" />
      <StyledAnchor onClick={() => onNavigate(ADMIN_PAGES.CorsSetting)} active={checkPath.includes(ADMIN_PAGES.CorsSetting)} label="CORS Setting" />
      <StyledAnchor onClick={() => onNavigate(ADMIN_PAGES.Settings)} label="Settings" />
      <StyledAnchor onClick={() => onNavigate(ADMIN_PAGES.PostmanConnectors)} label="Postman Connectors" />
    </StyledSidebar>
  );
}

export default AdminMenu;
