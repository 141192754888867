import { Close } from 'grommet-icons';
import {
  Box,
  Button,
  Form,
  FormField,
  Layer,
  Select,
  Spinner,
  Text,
  TextArea,
  TextInput,
} from 'grommet/components';
import { useEffect, useMemo, useState } from 'react';
import useBus from 'use-bus';

import { usePlatformMutation } from '../../hooks';
import Bug from './Bug.svg?react';
import Feedback from './Feedback.svg?react';
import Support from './Support.svg?react';

function convertCategory(category: string) {
  return category.toUpperCase().replaceAll(' ', '_');
}

function RenderOption(val: string) {
  return (
    <Box direction="row" gap="small" pad="small" align="center" fill="horizontal" background="background">
      <Box width="16px" height="16px">
        {val === 'Support' && <Support />}
        {val === 'Bug' && <Bug />}
        {val === 'Feedback' && <Feedback />}
      </Box>
      <Text>{val}</Text>
    </Box>
  );
}

export default function HelpdeskModal() {
  const [show, setShow] = useState(false);
  const [submitted, setSubmitted] = useState(true);
  const [type, setType] = useState('Support');

  const { mutateAsync: submit, isPending } = usePlatformMutation('/onboarding/contacthelpdesk', {
    method: 'POST',
  });

  const onSubmit = async ({ value }: { value: any }) => {
    await submit({ ...value, hs_ticket_category: convertCategory(value.hs_ticket_category) });
    setSubmitted(true);
  };

  useBus('helpdesk.open', (ev) => {
    if (ev.title) {
      setType(ev.title);
    }

    setShow(true);
  });

  useEffect(() => {
    if (!show) {
      setSubmitted(false);
    }
  }, [show]);

  const title = useMemo(() => {
    if (type === 'Bug') {
      return 'Report a bug';
    }

    if (type === 'Feedback') {
      return 'Give feedback';
    }

    return 'Get Support';
  }, [type]);

  if (!show) return null;

  return (
    <Layer modal position="center" responsive onClickOutside={() => setShow(false)}>
      <Box pad="medium" direction="row" align="center" justify="between">
        <Text size="large" className="shareModalTitle">
          {title}
        </Text>
        <Button icon={<Close size="18px" />} onClick={() => setShow(false)} />
      </Box>
      <Box pad="medium" width="medium">
        {submitted ? (
          <Box gap="medium">
            <Text weight="bold" textAlign="center">Thank you for your feedback. We will contact within a business day.</Text>
            <Box justify="center" direction="row">
              <Button primary label="Close" onClick={() => setShow(false)} />
            </Box>
          </Box>
        ) : (
          <Form onSubmit={onSubmit}>
            <FormField label="What type of message is this?" name="hs_ticket_category" htmlFor="category" required>
              <Select
                id="category"
                name="hs_ticket_category"
                value={type}
                valueLabel={RenderOption}
                options={[
                  'Support',
                  'Bug',
                  'Feedback',
                ]}
                onChange={(e) => setType(e.value)}
              >
                {RenderOption}
              </Select>
            </FormField>
            <FormField label="Subject" name="subject" htmlFor="ticket-name" required>
              <TextInput id="ticket-name" name="subject" placeholder="Subject" />
            </FormField>
            <FormField label="Your message" name="content" htmlFor="ticket-description" required>
              <TextArea id="ticket-description" name="content" placeholder="Description" fill style={{ height: '240px' }} />
            </FormField>
            {/* <FormField label="Upload a screenshot (recommended)" name="hs_file_upload" htmlFor="file">
              <FileInput id="file" name="hs_file_upload" />
            </FormField> */}
            <Box direction="row" gap="small" justify="end" pad={{ top: 'small' }}>
              <Button type="submit" primary label={isPending ? <Spinner /> : 'Submit'} />
            </Box>
          </Form>
        )}
      </Box>
    </Layer>
  );
}
