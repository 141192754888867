import type { TextExtendedProps } from 'grommet';
import { Text } from 'grommet';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';

import { useSessionStore } from '../../hooks/store';

type TimeAgoProps = { date: string | Date } & TextExtendedProps;

function TimeAgo({ date, ...props }: TimeAgoProps) {
  const [timeAgo, setTimeAgo] = useState('');
  const session = useSessionStore((state: any) => state.session);

  const time = typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date);

  const formatTimeAgo = useCallback(() => {
    const locale = session?.lang ? session.lang.substring(0, session.lang.indexOf('-')) : undefined;
    const rtf = new Intl.RelativeTimeFormat(locale, {
      numeric: 'auto',
    });

    const diffNow = time.diffNow(['second', 'minute', 'hour', 'day', 'month', 'year']);

    if (diffNow.years !== 0) {
      setTimeAgo(rtf.format(diffNow.years, 'year'));
    } else if (diffNow.months !== 0) {
      setTimeAgo(rtf.format(diffNow.months, 'month'));
    } else if (diffNow.days !== 0) {
      setTimeAgo(rtf.format(diffNow.days, 'day'));
    } else if (diffNow.hours !== 0) {
      setTimeAgo(rtf.format(diffNow.hours, 'hour'));
    } else if (diffNow.minutes !== 0) {
      // setTimeAgo(rtf.format(diffNow.minutes, 'minute'));
      if (diffNow.minutes === -1) {
        setTimeAgo(`${Math.abs(diffNow.minutes)} min ago`);
      } else {
        setTimeAgo(`${Math.abs(diffNow.minutes)} mins ago`);
      }
    } else {
      // setTimeAgo(rtf.format(0, 'second'));
      setTimeAgo('Just now');
    }
  }, [session?.lang, time]);

  useEffect(() => {
    formatTimeAgo();
    const tick = setInterval(formatTimeAgo, 10000);
    return () => clearInterval(tick);
  }, [date, formatTimeAgo]);

  return <Text {...props}>{timeAgo}</Text>;
}

export default TimeAgo;
