import type {
  AvatarExtendedProps,
  ImageExtendedProps } from 'grommet';
import {
  Avatar,
  Box,
  Image as GrommetImage,
} from 'grommet';
import type {
  CSSProperties } from 'react';
import {
  memo,
  useEffect,
  useState,
} from 'react';

import config from '../../config';
import { useToken } from '../../hooks';
import PlaceholderLoading from '../PlaceholderLoading';
import type { IPlaceholderLoadingProps } from '../PlaceholderLoading/types';

type ImageLoaderProps = ImageExtendedProps & AvatarExtendedProps & IPlaceholderLoadingProps & {
  secure?: boolean
  avatar?: boolean
};

function ImageLoader(props: ImageLoaderProps) {
  const [loading, setLoading] = useState(true);
  const [currentSrc, updateSrc] = useState('');

  const token = useToken();

  const {
    src, style, width, height, shape, avatar, margin, size,
  } = props;

  useEffect(() => {
    if (typeof src !== 'string') {
      return;
    }

    let srcCopy = src;

    if (token?.access_token) {
      srcCopy += `?token=${token.access_token}${config.pkceSettings.name !== 'platform' ? `&bearerType=${config.pkceSettings.name}` : ''}`;
    }

    const imageToLoad = new Image();

    imageToLoad.src = srcCopy;
    imageToLoad.onload = () => {
      setLoading(false);
      updateSrc(srcCopy);
    };
  }, [src, token.access_token]);

  const styleCopy: CSSProperties = { ...style };

  if (typeof height === 'number') {
    styleCopy.height = height;
  }

  if (typeof width === 'number') {
    styleCopy.width = width;
  }

  if (loading) {
    return (
      <Box margin={margin}>
        <PlaceholderLoading shape={shape} width={width} height={height} pad={false} />
      </Box>
    );
  }

  return avatar
    ? (
        <Avatar {...{
          size, shape, avatar, margin, src: currentSrc, style: styleCopy,
        }}
        />
      )
    : (
        <GrommetImage {...{
          width, height, shape, avatar, margin, src: currentSrc, style: styleCopy,
        }}
        />
      );
}

export default memo(ImageLoader);
