import type { AnchorExtendedProps } from 'grommet';
import { Box, Notification } from 'grommet';
import { DateTime } from 'luxon';
import type { CSSProperties } from 'react';
import { memo, useCallback, useMemo, useState } from 'react';

import { hostUrl } from '../config';
import { useIsBannerShowing, useSessionStore } from '../hooks';

const containerStyle: CSSProperties = { top: '0', zIndex: '10' };

function TrialBanner() {
  const session = useSessionStore((state) => state.session);

  const [show, setShow] = useState(true);

  const isTrialActive = typeof session?.usageStatus?.Plan?.TrialDaysRemaining === 'number';
  const isTrialExpired = isTrialActive && session?.usageStatus?.Plan?.TrialDaysRemaining && session?.usageStatus?.Plan?.TrialDaysRemaining <= 0;
  const disableCheckout = session?.usageStatus?.Plan?.DisableCheckout === true;
  const paymentPending = !!session?.usageStatus?.Plan?.PaymentPending;
  const overused = !!session?.usageStatus?.OverusageDate;
  const showPromotion = false;// activePlanId === 7 || activePlanId === 20;

  const setShowing = useIsBannerShowing((state: any) => state.setShowing);

  const message = useMemo(() => {
    if (showPromotion) {
      return '🎃 LIMITED TIME OFFER 🎃 Get 40% off App Passes until 31st October by using code HALLOWEEN24.';
    } else if (overused) {
      return `You have exceeded your plan's usage limits on ${DateTime.fromISO(session.usageStatus!.OverusageDate).toLocaleString()}`;
    } else if (isTrialExpired) {
      return 'Your trial expired.';
    } else {
      return `You have ${session?.usageStatus?.Plan?.TrialDaysRemaining} day${session?.usageStatus?.Plan?.TrialDaysRemaining === 1 ? '' : 's'} remaining in your trial.`;
    }
  }, [showPromotion, overused, isTrialExpired, session]);

  const action: AnchorExtendedProps[] = useMemo(() => {
    if (showPromotion) {
      return [{
        href: 'https://app.adenin.com/redirect/promotion_halloween-in-webpart?redirectUrl=https%3A%2F%2Fapp.adenin.com%2Fapp%2Fsubscription',
        label: 'Subscribe now',
        target: '_blank',
      }];
    }

    return [{
      href: `${hostUrl}/app/billing`,
      label: 'Click here to select a plan',
      target: '_blank',
    }];
  }, [showPromotion]);

  const onClose = useCallback(() => setShow(false), []);

  if (!showPromotion && (disableCheckout || (!overused && (!isTrialActive || !show || paymentPending || isTrialExpired)))) {
    setShowing(false);
    return null;
  }

  setShowing(true);

  return (
    <Box background="background-front" style={containerStyle}>
      <Notification
        status={showPromotion ? 'info' : 'warning'}
        message={message}
        onClose={onClose}
        actions={action}
        global
      />
    </Box>
  );
}

export default memo(TrialBanner);
