import { usePostHog } from 'posthog-js/react';
import { memo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
import { dispatch } from 'use-bus';

import config, {
  apiUrl,
  defaultFetchOpts,
  hostUrl,
  tenantId,
} from '../config';
import {
  usePlatformQueryClient,
  useSessionStore,
  useToken,
  withPkce,
} from '../hooks';
import { getCookie, isE2E, isSSR } from '../utils';

const opts = defaultFetchOpts;

function SessionCheck({ children }) {
  const session = useSessionStore((state) => state.session);
  const location = useLocation();
  const setSession = useSessionStore((state) => state.setSession);
  const posthog = usePostHog();
  const navigate = useNavigate();

  const { invalidateQueries } = usePlatformQueryClient();
  const { access_token: accessToken } = useToken();

  // eslint-disable-next-line react-compiler/react-compiler
  if (config.useTokenAuth) {
    opts.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    opts.credentials = 'include';
  }

  const checkAuthentication = useCallback(async (forceRefetch = false) => {
    if (!forceRefetch && window.$at.session?.authenticated === true) {
      return;
    }

    if (config.useTokenAuth && window.location.hostname === 'localhost') {
      console.log('PKCE session requested with token ', accessToken);
    }

    const response = await fetch(`${apiUrl}/session/myprofile`, opts);
    const data = await response.json();

    console.log('session check response was', data, 'request opts were', opts);

    let authenticated = false;

    if (config.useTokenAuth) {
      if (!accessToken) return;

      if (window.location.hostname === 'localhost') {
        console.log('PKCE session response was authenticated?' + data.Data?.authenticated);
      }

      if (!data.Data?.authenticated) {
        dispatch('pkce.clear');
        return;
      }

      window.$at.session = data.Data;
      setSession(data.Data);
      authenticated = true;
    }

    if (data.Data?.authenticated) {
      window.$at.session = data.Data;
      setSession(data.Data);
      authenticated = true;
    }

    if (authenticated) {
      const oidcCookie = getCookie('oidc_start');
      const tenantCookie = getCookie('tenant_start');

      document.cookie = 'oidc_start=;path=/';
      document.cookie = 'tenant_start=;path=/';

      const now = new Date();

      // add 30 days
      now.setTime(now.getTime() + (30 * 24 * 60 * 60 * 1000));

      const expires = `expires=${now.toUTCString()};`;

      if (oidcCookie) {
        document.cookie = `oidc=${oidcCookie};path=/;${expires}`;
      }

      if (tenantCookie) {
        document.cookie = `tenant=${tenantCookie};path=/;${expires}`;
      }

      const params = new URLSearchParams(location.search);

      if (params.get('mode') === 'designer' || params.has('newcard')) {
        await invalidateQueries('/userworkplace/usercards');
      }

      if (!params.has('newcard')) {
        return;
      }

      if (params.has('title')) {
        dispatch({
          type: 'toast.open',
          title: `Your ${params.get('title').replaceAll('-', ' ')} Cards are now available on your Board`,
        });

        return;
      }

      const cardResponse = await fetch(`${apiUrl}/card/adaptive/${params.get('newcard')}`, opts);
      const cardData = await cardResponse.json();

      dispatch({
        type: 'toast.open',
        title: `Your new Card '${cardData.Data.title}' is now available on your Board`,
      });

      return;
    }

    console.log('clearing cookies and returning to login as session response was not authenticated');

    if (config.oidcProvider) {
      window.location.href = `${hostUrl}/oauth2connector/signin/${config.oidcProvider}?returnUrl=${encodeURIComponent(window.location.href)}${tenantId ? `&tid=${tenantId}` : ''}`;
      return;
    }

    const params = new URLSearchParams(location.search);
    const returnUrl = params.get('returnUrl') || window.location.href;

    navigate(`/login?returnUrl=${encodeURIComponent(returnUrl)}`);
  }, [location.search, navigate, accessToken, setSession, invalidateQueries]);

  useEffect(() => {
    if (!isE2E && session.authenticated) {
      posthog?.identify(session.Email, {
        email: session.Email,
        userAgent: navigator?.userAgent,
      });

      if (window.Intercom && !location.pathname.includes('google-cse-demo') && !location.pathname.includes('embedcardframe') && config.intercomAppId) {
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: config.intercomAppId,
          name: `${session.FirstName} ${session.LastName}`, // Full name
          email: session.Email, // Email address
          created_at: new Date(session.userCreatedAt).getTime() / 1000, // Signup date as a Unix timestamp
        });
      }

      /*
      if (config.engageKey && session.Email !== 'e2e.a@adenin.com') {
        import('@engage_so/js').then(({ default: Engage }) => {
          Engage.init(config.engageKey);
          Engage.identify({
            id: session.Email,
            first_name: session.FirstName,
            last_name: session.LastName,
            email: session.Email,
            created_at: session.userCreatedAt,
          });
        });
      }
      */
    }
  }, [location.pathname, posthog, session?.Email, session?.FirstName, session?.LastName, session?.authenticated, session?.userCreatedAt]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  if (!isSSR && !window.$at.session?.authenticated && !session?.authenticated) {
    return null;
  }

  return children;
}

const MemoizedSessionCheckWithPkce = withPkce(memo(SessionCheck));

export default MemoizedSessionCheckWithPkce;
