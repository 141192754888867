import { Box, Header } from 'grommet';

import PlaceholderLoading from '../PlaceholderLoading';

function AppHeaderSkeleton() {
  return (
    <Header align="center" direction="row" flex={false} justify="between" gap="medium" fill="horizontal" pad="small">
      <Box align="center" justify="center" direction="row" gap="small">
        <Box align="start" justify="center" direction="column" gap="none" wrap={false} responsive={false}>
          <PlaceholderLoading shape="rect" width={160} height={48} />
        </Box>
      </Box>
      <Box align="center" justify="center" direction="row">
        <PlaceholderLoading shape="circle" width={48} height={48} />
      </Box>
    </Header>
  );
}

export default AppHeaderSkeleton;
