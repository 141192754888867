import {
  Box,
  Button,
  DropButton,
  Footer,
  Image,
  Main,
} from 'grommet';
import styled from 'styled-components';

export const LandingHeroContainer = styled(Main)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: auto;
`;

export const LandingFooterContainer = styled(Footer)`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Logo = styled(Image)`
  object-fit: contain;
  height: 33px;
  width: 60px;
`;

export const Separator = styled(Box)`
  display: block;
  width: 100%;
  position: relative;
  text-align: center;
  margin: ${(props) => props.theme.global.edgeSize.medium} 0;
  background: ${(props) => props.theme.global.colors.background.light};

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 0.5px);
    width: calc(50% - 3ch);
    height: 1px;
    background: ${(props) => props.theme.global.colors.border.light};
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
`;

export const LandingButton = styled(Button)`
  display: block;
  width: 100%;
  background: ${(props) => (props.primary ? props.theme.global.colors['orange!'] : props.theme.global.colors.background.light)};
  border: 1px solid ${(props) => (props.primary ? props.theme.global.colors['orange!'] : props.theme.global.colors.border.light)};
  color: ${(props) => (props.primary ? props.theme.global.colors.background.light : props.theme.global.colors.text.light)};
  text-align: center;
  font-weight: 600;
  height: 55px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: ${(props) => props.theme.global.edgeSize.small};
  &:hover {
    background: ${(props) => (props.primary ? props.theme.global.colors['red!'] : props.theme.global.colors['background-back'].light)};
    color: ${(props) => (props.primary ? props.theme.global.colors.background.light : props.theme.global.colors.text.light)};
  }
`;

export const HelpDropButton = styled(DropButton)`
  text-align: end;
  &:hover {
    color: ${(props) => props.theme.global.colors['blue-900']};
  }
`;
