import { Box } from 'grommet';

function EmptyState(props) {
  const {
    className, heading, caption, action, illustration, ...rest
  } = props;

  return (
    <Box align="center" className={className}>
      {illustration}
      {heading}
      {caption}
      {action}
    </Box>
  );
}

export default EmptyState;
