export function iframeURLChange(iframe, callback) {
  let lastDispatched = null;

  function dispatchChange() {
    if (!iframe.contentWindow) {
      return;
    }

    const newHref = iframe.contentWindow.location.href;

    if (newHref !== lastDispatched) {
      callback(newHref);
      lastDispatched = newHref;
    }
  }

  function unloadHandler() {
    setTimeout(dispatchChange, 0);
  }

  function attachUnload() {
    iframe.contentWindow.removeEventListener('unload', unloadHandler);
    iframe.contentWindow.addEventListener('unload', unloadHandler);
  }

  iframe.addEventListener('load', () => {
    attachUnload();
    dispatchChange();
  });

  attachUnload();
}
