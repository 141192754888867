import { lazy } from 'react';

const Assistant = lazy(() => import(/* webpackChunkName: "Sidebars" */ './Assistant'));
const Account = lazy(() => import(/* webpackChunkName: "Sidebars" */ './Account'));
const Card = lazy(() => import(/* webpackChunkName: "Sidebars" */ './Card'));
const CardLoader = lazy(() => import(/* webpackChunkName: "Sidebars" */ './CardLoader'));
const ManageAssistant = lazy(() => import(/* webpackChunkName: "Sidebars" */ './ManageAssistant'));
const Notifications = lazy(() => import(/* webpackChunkName: "Sidebars" */ './Notifications'));

const catalog = {};

catalog.Assistant = Assistant;
catalog.Account = Account;
catalog.Card = Card;
catalog.CardLoader = CardLoader;
catalog.ManageAssistant = ManageAssistant;
catalog.Notifications = Notifications;

export const sidebarCatalog = catalog;
