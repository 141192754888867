import type { AnchorExtendedProps } from 'grommet';
import type { LinkProps } from 'react-router';

import GrommetGatsbyLinkUntyped from './GrommetGatsbyLink';

type GrommetGatsbyLinkProps = Omit<AnchorExtendedProps, 'href'> & LinkProps;

export default function GrommetGatsbyLink(props: GrommetGatsbyLinkProps) {
  return <GrommetGatsbyLinkUntyped {...props} />;
}
