import { Box } from 'grommet';
import styled, { keyframes } from 'styled-components';

const indeterminateFrames = keyframes`
    0% {
      left: -50%;
      width: 50%;
    }
    75%,
    100% {
      left: 100%;
      width: 50%;
    }
  }
`;

const Bar = styled(Box)`
  position: relative;
  height: 2px;
  overflow: hidden;
`;

const Indicator = styled(Box)`
  height: 100%;
  overflow: hidden;
  transition: width 200ms ease 0s, background-color 200ms ease 0s;
  position: absolute;
  animation: 1.25s cubic-bezier(0.31, 0.67, 0.85, 0.57) 0s infinite normal none running ${indeterminateFrames};
  color: rgb(68, 68, 68);
`;

function LoadingBar({ background = 'background', color = 'highlight', loading }) {
  return (
    <Bar background={background}>
      {loading && <Indicator background={color} />}
    </Bar>
  );
}

export default LoadingBar;
