import { Box, Grommet, Text } from 'grommet';
import { Checkmark } from 'grommet-icons';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import theme from '../theme';
import { isSSR } from '../utils';

export default function AuthComplete() {
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    if (!isSSR && window.opener) {
      window.opener.postMessage('closeAuthPopup', window.location.origin);
      window.close();
    }
  }, []);

  return (
    <Grommet full theme={theme} themeMode="light" background="page-background" id="pageContainer">
      <Box fill flex align="center" justify="center" gap="small" pad="medium">
        <Checkmark color="status-ok" size="xlarge" />
        <Text weight={500} size="large" textAlign="center">
          {searchParams.get('title')}
          {' '}
          authorized successfully. You can close this window now
        </Text>
      </Box>
    </Grommet>
  );
}
