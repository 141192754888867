import {
  Anchor,
  Box,
  Button,
  Image,
  Spinner,
  Text,
} from 'grommet';
import { FormClose } from 'grommet-icons';
import { useEffect, useMemo } from 'react';

import ProcessedCard from '../components/board-cards/AssistantCard/ProcessedCard';
import {
  usePlatformMutation,
  usePlatformQueries,
  usePlatformQuery,
  usePlatformQueryClient,
  useSidebarOpen,
} from '../hooks';
import roboLaunch from '../images/robo_launch_800px.png';
import { TRACKING_EVENTS, hasNotificationDot, track } from '../utils';
import { expandBody } from '../utils/templating';

export default function Notifications({ hideFormClose = false }) {
  const { data: notificationCards } = usePlatformQuery('/userworkplace/usercards');

  const allCards = useMemo(() => {
    if (notificationCards?.ErrorCode || !Array.isArray(notificationCards.Data)) {
      return [];
    }

    return notificationCards.Data;
  }, [notificationCards]);

  const boardCards = useMemo(() => allCards.filter((c) => c.UserActions?.pinned === true), [allCards]);
  const boardResults = usePlatformQueries(boardCards.map((item) => `/gateway/card/${item.Id}`));

  const notificationDots = useMemo(() => {
    const r = [];

    for (let i = 0; i < boardResults.length; i++) {
      const result = boardResults[i];

      if (!result.isFetched) {
        continue;
      }

      const metadata = boardCards[i];

      if (hasNotificationDot(result.data, metadata)) {
        r.push({ data: result.data, metadata });
      }
    }

    return r;
  }, [boardCards, boardResults]);

  const { mutateAsync } = usePlatformMutation('/briefing/updatecardsstatus', {
    method: 'POST',
  });

  const queryClient = usePlatformQueryClient();
  const setSidebarOpen = useSidebarOpen((state) => state.setSidebarOpen);

  const readAll = async () => {
    const updateStatusParameters = [];

    for (let i = 0; i < boardCards.length; i++) {
      const resultMetadata = boardCards[i];
      const result = boardResults[i];

      if (!result.isFetched) {
        continue;
      }

      const expanded = expandBody(JSON.parse(resultMetadata.AdaptiveCardDefinition || '{}').body, result.data.Data);

      if (!result.data?.Data?.actionable && !expanded.notify) {
        continue;
      }

      const resultData = result.data.Data;

      const updatePayload = {
        Id: resultMetadata.Id,
        ReadVersion: resultData._hash,
        ReadDate: new Date().toISOString(),
      };

      console.log('Marking notification as read with payload', updatePayload);

      updateStatusParameters.push(updatePayload);
    }

    await mutateAsync(updateStatusParameters);
    await queryClient.invalidateQueries('/userworkplace/usercards');

    const promises = [];

    for (let i = 0; i < updateStatusParameters.length; i++) {
      promises.push(queryClient.invalidateQueries(`/gateway/card/${updateStatusParameters[i].Id}`));
    }

    await Promise.all(promises);
  };

  const toggleSidebar = () => setSidebarOpen(false);

  useEffect(() => {
    track(TRACKING_EVENTS.NotificationsOpened);
  }, []);

  let content;

  if (notificationCards) {
    content = notificationDots.length
      ? (
          notificationDots.map((result) => (
            <Box key={result.metadata.Id} flex={{ shrink: 0 }} style={{ cursor: 'pointer' }}>
              <ProcessedCard metadata={result.metadata} data={result.data} notification shadow={false} />
            </Box>
          ))
        )
      : (
          <Box gap="medium" pad="medium">
            <Text size="large" weight="bold" textAlign="center">
              Woo-hoo! No new notifications
            </Text>
            <Image src={roboLaunch} height={800} fit="contain" />
            <Text textAlign="center">You can subscribe to Cards by clicking the three dots menu and then Subscribe</Text>
          </Box>
        );
  } else {
    content = <Spinner alignSelf="center" />;
  }

  return (
    <Box direction="column" fill="horizontal">
      <Box direction="row" align="center" justify="between" flex={{ shrink: 0 }} pad="medium" background="background-front">
        <Box direction="column">
          <Text weight="bold">Notifications</Text>
          {notificationDots.length > 0 && <Anchor type="reset" label="Mark all as read" onClick={readAll} />}
        </Box>
        {!hideFormClose && (
          <Button icon={<FormClose />} onClick={toggleSidebar} />
        )}
      </Box>
      <Box gap="small" overflow="auto">
        {content}
      </Box>
    </Box>
  );
}
