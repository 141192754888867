import { useCallback } from 'react';
import { dispatch } from 'use-bus';

import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import config, { apiUrl, defaultFetchOpts, hostUrl } from '../config';
import { useToken } from './auth';
import { useSessionStore } from './store';

const statusCodesForceLogin = config.statusCodesForceLogin || [401];

export const createQueryKey = (url, userId) => ((url && userId) ? [`${url}:${userId}`] : [undefined]);

export const queryFn = async (queryUrl, fetchOpts, token, filter = false) => {
  const logout = () => {
    let logoutUrl = `${hostUrl}/account/logout?returnUrl=${encodeURIComponent(window.location.href)}`;

    if (config.useTokenAuth && token?.access_token) {
      logoutUrl += `&revoke_token=${token?.access_token}`;
    }

    const msg = {
      type: 'pkce.clear',
    };

    if (!config.useTokenAuth) {
      msg.logoutUrl = logoutUrl;
    }

    dispatch(msg);
  };

  if (config.useTokenAuth && !!config.clientTokenExpiration && token?.expires_at) {
    const now = new Date();
    const expiresAt = new Date(token?.expires_at);

    if (expiresAt <= now) {
      logout();
      return;
    }
  }

  if (!queryUrl.includes('?')) {
    queryUrl += '?channel=board-spa';
  }

  const response = await fetch(queryUrl, fetchOpts);

  if (!response.ok) {
    if (filter && config.clientAuthFlow && statusCodesForceLogin.includes(response.status)) {
      logout();
    } else {
      console.error(`Network response from ${queryUrl} failed with status ${response.statusText}`);
    }

    throw new Error(`Network response was not ok. Status ${response.status}`, {
      cause: response.status,
    });
  }

  const json = await response.json();

  if (filter && statusCodesForceLogin.includes(json?.ErrorCode)) {
    logout();
  }

  if (json?.ErrorCode === 429 || json?.ErrorCode === 504) {
    throw new Error(`Network response was not ok. Status ${json.ErrorCode}`, {
      cause: json.ErrorCode,
    });
  }

  return new Promise((resolve) => {
    resolve(json);
  });
};

export const usePlatformQuery = (url, opts, pagination) => {
  const session = useSessionStore((state) => state.session);
  const token = useToken();

  const queryKey = createQueryKey(url, session.UserId);
  let queryUrl = `${apiUrl}${url}`;

  // use seperate cards-api host in prod
  if (url.indexOf('gateway/card') > 0) {
    queryUrl = `${config.cardsAPIUrl}${url}`;
  }

  if (config.useTokenAuth && token?.access_token) {
    defaultFetchOpts.headers.Authorization = `Bearer ${token.access_token}`;
  } else {
    defaultFetchOpts.credentials = 'include';
  }

  if (pagination?.page && pagination?.pageSize) {
    if (queryUrl.includes('?')) {
      queryUrl += '&';
    } else {
      queryUrl += '?';
    }

    queryUrl += `page=${pagination.page}&pageSize=${pagination.pageSize}`;
  }

  const result = useQuery({
    queryKey: pagination ? [...queryKey, pagination.page] : queryKey,
    queryFn: async () => queryFn(queryUrl, defaultFetchOpts, token, true),
    staleTime: 300000 * 3,
    refetchInterval: 300000 * 3,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    ...opts,
    enabled: (typeof opts?.enabled !== 'undefined') ? (opts.enabled && !!session) : !!session,
  });

  return result;
};

export const usePlatformQueries = (urls) => {
  const session = useSessionStore((state) => state.session);
  const token = useToken();

  if (config.useTokenAuth && token?.access_token) {
    defaultFetchOpts.headers.Authorization = `Bearer ${token.access_token}`;
  } else {
    defaultFetchOpts.credentials = 'include';
  }

  const queries = urls.map((url) => {
    const queryKey = createQueryKey(url, session.UserId);
    let queryUrl = `${apiUrl}${url}`;
    if (url.indexOf('gateway/card') > 0) queryUrl = `${config.cardsAPIUrl}${url}`; // use seperate cards-api host in prod

    return {
      queryKey,
      queryFn: async () => queryFn(queryUrl, defaultFetchOpts, token, true),
      enabled: !!session,
      staleTime: 300000 * 3,
      refetchInterval: 300000 * 3,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      keepPreviousData: true,
    };
  });

  return useQueries({ queries });
};

export const usePlatformQueryUnfiltered = (url, opts) => {
  const session = useSessionStore((state) => state.session);
  const token = useToken();

  const queryKey = createQueryKey(url, session.UserId);
  let queryUrl = `${apiUrl}${url}`;

  // use seperate cards-api host in prod
  if (url.indexOf('gateway/card') > 0) {
    queryUrl = `${config.cardsAPIUrl}${url}`;
  }

  if (config.useTokenAuth && token?.access_token) {
    defaultFetchOpts.headers.Authorization = `Bearer ${token.access_token}`;
  } else {
    defaultFetchOpts.credentials = 'include';
  }

  const result = useQuery({
    queryKey,
    queryFn: async () => queryFn(queryUrl, defaultFetchOpts, token, false),
    staleTime: 300000 * 3,
    refetchInterval: 300000 * 3,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    ...opts,
    enabled: (typeof opts?.enabled !== 'undefined') ? (opts.enabled && !!session) : !!session,
  });

  return result;
};

export const usePlatformQueriesUnfiltered = (urls) => {
  const session = useSessionStore((state) => state.session);
  const token = useToken();

  if (config.useTokenAuth && token?.access_token) {
    defaultFetchOpts.headers.Authorization = `Bearer ${token.access_token}`;
  } else {
    defaultFetchOpts.credentials = 'include';
  }

  const queries = urls.map((url) => {
    const queryKey = createQueryKey(url, session.UserId);
    const queryUrl = `${apiUrl}${url}`;

    return {
      queryKey,
      queryFn: async () => queryFn(queryUrl, defaultFetchOpts, token, false),
      enabled: !!session,
      staleTime: 300000 * 3,
      refetchInterval: 300000 * 3,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      keepPreviousData: true,
    };
  });

  return useQueries({ queries });
};

export const usePlatformMutation = (url, opts) => {
  const session = useSessionStore((state) => state.session);
  const token = useToken();

  const queryKey = createQueryKey(url, session.UserId);
  const queryUrl = `${apiUrl}${url}`;

  const mergedOpts = {
    ...defaultFetchOpts,
    ...opts,
  };

  if (!mergedOpts.headers['Content-Type']) {
    mergedOpts.headers['Content-Type'] = 'application/json';
  }

  if (config.useTokenAuth && token?.access_token) {
    mergedOpts.headers.Authorization = `Bearer ${token.access_token}`;
  } else {
    mergedOpts.credentials = 'include';
  }

  const result = useMutation({
    mutationKey: queryKey,
    mutationFn: async (body) => {
      if (mergedOpts.method === 'POST') {
        if (mergedOpts.headers['Content-Type'] !== 'application/json') {
          mergedOpts.body = body;
        } else {
          mergedOpts.body = JSON.stringify(body);
        }
      }

      const response = await fetch(queryUrl, mergedOpts);

      if (!response.ok) {
        dispatch({
          type: 'error.network',
          response,
        });

        throw new Error('Network response was not ok');
      }

      return response.json();
    },
  });

  return result;
};

export const usePlatformQueryClient = () => {
  const session = useSessionStore((state) => state.session);
  const queryClient = useQueryClient();

  return {
    ...queryClient,
    invalidateQueries: useCallback((url, opts) => {
      const queryKey = createQueryKey(url, session.UserId);
      return queryClient.invalidateQueries({ queryKey }, opts);
    }, [session.UserId, queryClient]),
    cancelQueries: useCallback((url, opts) => {
      const queryKey = createQueryKey(url, session.UserId);
      return queryClient.cancelQueries({ queryKey }, opts);
    }, [session.UserId, queryClient]),
    setQueryData: useCallback((url, updater) => {
      const queryKey = createQueryKey(url, session.UserId);
      return queryClient.setQueryData(queryKey, updater);
    }, [session.UserId, queryClient]),
    refetchQueries: useCallback((url, opts) => {
      const queryKey = createQueryKey(url, session.UserId);
      return queryClient.refetchQueries({ queryKey }, opts);
    }, [session.UserId, queryClient]),
  };
};
