import {
  Box,
  Button,
  Image,
  Spinner,
  Text,
  TextInput,
  ThemeContext,
} from 'grommet';
import { FormClose, Microphone } from 'grommet-icons';
import {
  lazy,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import styled from 'styled-components';
import { dispatch } from 'use-bus';

import BotSuggestionList from '../../components/BotSuggestionList';
import ProcessedCard from '../../components/board-cards/AssistantCard/ProcessedCard';
import RenderedCard from '../../components/board-cards/AssistantCard/RenderedCard';
import {
  usePlatformMutation,
  usePlatformQueryClient,
  useSessionStore,
} from '../../hooks';
import { useDebounce } from '../../hooks/debounce';
import helix from '../../images/icon-512x512.png';
import { TRACKING_EVENTS, track } from '../../utils';
import phrases from './phrases';

const PinTip = styled.div`
  background-color: #1967d2;
  margin-top: 16px;
  &::after {
    content: '';
    position: absolute;
    margin-left: 25px;
    border-width: 7px;
    border-style: solid;
    transform: rotate(45deg);
    color: #1967d2;
    margin-top: -98px;
  }
`;

const AdaptiveCard = lazy(() => import('../../components/board-cards/AdaptiveCard'));

const getRandomPhrase = (phraseSet) => phraseSet[Math.floor(Math.random() * phraseSet.length)];

const AssistantContainer = styled.div`
  height: 100%;
  min-height: 500px;
  width: 100%;
  background-size: 32px 32px;
  overflow-y: auto;
`;

const HeaderContainer = styled.div`
  width: 100%;
`;

function Assistant({ iframe, value: _value = '' }) {
  const session = useSessionStore((state) => state.session);

  const [value, setValue] = useState(_value);
  const [searchValue, setSearchValue] = useState(_value);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [result, setResult] = useState(null);
  const [showPin, setShowPin] = useState(true);

  const debouncedValue = useDebounce(value, 400);

  useEffect(() => {
    if (_value && _value !== value) {
      setSearchValue(_value);
      setValue(_value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_value]);

  useEffect(() => {
    track(TRACKING_EVENTS.AssistantChatStart);
  }, []);

  const queryClient = usePlatformQueryClient();

  const searchMutation = usePlatformMutation('/botchat', {
    method: 'POST',
  });

  const statusMutation = usePlatformMutation('/userworkplace/changestatus', {
    method: 'POST',
  });

  const {
    transcript, listening, resetTranscript, browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const toggleSidebar = () => dispatch({ type: 'sidebar.toggle', title: 'Assistant' });

  const onChange = (event) => {
    setResult(null);
    setValue(event.target.value);
    setShowSuggestions(true);
  };

  const onSearch = useCallback(
    (query, key = null) => {
      if (key && key !== 'Enter') {
        return;
      }

      setResult(null);
      setValue(query);
      setShowSuggestions(false);
      searchMutation.mutate(
        { query },
        {
          onSuccess: (response) => {
            setResult(response.Data.result);
          },
        },
      );
    },
    [searchMutation],
  );

  const onSpeech = async () => {
    if (listening) return;

    resetTranscript();
    setResult(null);
    setValue('');

    await SpeechRecognition.startListening();
  };

  const pinCard = (id) => {
    statusMutation.mutate(
      {
        id,
        pinned: true,
      },
      {
        onSuccess: async () => {
          setShowPin(false);
          result.card.UserActions.pinned = true;
          await queryClient.invalidateQueries('/userworkplace/usercards');
          await queryClient.invalidateQueries('/cardcatalog');
        },
      },
    );
  };

  useEffect(() => {
    setValue(transcript);

    if (!transcript?.trim() || listening) return;

    onSearch(transcript);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcript, listening]);

  useEffect(() => {
    if (!searchValue) {
      return;
    }

    onSearch(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const { dark, global } = useContext(ThemeContext);

  const assistantContainerBackground = dark
    ? `radial-gradient(rgb(64 64 64 / 12%) 2.75px, 
                                         rgb(255 255 255 / 6%) 0.75px), 
                         linear-gradient(${global.colors['background-back'][dark ? 'dark' : 'light']},
                                         ${global.colors['background-back'][dark ? 'dark' : 'light']})`
    : `radial-gradient(rgb(64 64 64 / 7%) 2.75px,
                                         rgb(255 255 255 / 7%) 0.75px)`;

  const headerContainerBackground = dark
    ? `radial-gradient(rgb(64 64 64 / 12%) 2.75px, 
                                         rgb(255 255 255 / 6%) 0.75px), 
                         linear-gradient(${global.colors['background-back'][dark ? 'dark' : 'light']},
                                         ${global.colors['background-back'][dark ? 'dark' : 'light']})`
    : `radial-gradient(rgb(64 64 64 / 7%) 2.75px,
                                         rgb(255 255 255 / 7%) 0.75px)`;

  return (
    <Box fill="vertical" className="assistant-panel">
      <AssistantContainer style={{ backgroundImage: assistantContainerBackground }}>
        <HeaderContainer style={{ backgroundImage: headerContainerBackground }}>
          <Box direction="row" fill="horizontal" pad="small" align="center" as="header" justify="between">
            {!iframe && (
              <Box width="xsmall">
                <Image src={helix} width={64} height={64} />
              </Box>
            )}
            <Box direction="column" fill>
              <Text weight="bold" color="text" size="small" margin={{ left: 'small' }}>
                Hi
                {' '}
                {session.FirstName}
                , how can I help you?
              </Text>
            </Box>
            {!iframe && <Button id="chat-close-button" icon={<FormClose />} onClick={toggleSidebar} />}
          </Box>
          <Box direction="row" fill="horizontal" align="center" pad={{ left: 'small', right: 'small', top: 'small' }}>
            <TextInput
              autoFocus
              placeholder="Enter your question or command"
              value={value}
              onChange={onChange}
              onKeyPress={(event) => onSearch(value, event.key)}
              className="utteranceInput"
              style={{ borderRadius: '0' }}
            />
            {browserSupportsSpeechRecognition && (
              <Button
                icon={<Microphone color={(!dark && listening) ? 'white' : 'text'} />}
                onClick={onSpeech}
                fill="vertical"
                margin={{ left: 'xsmall' }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '36px',
                  width: '36px',
                  borderRadius: '50%',
                  backgroundColor: listening ? 'var(--at-adenin-red)' : '',
                }}
              />
            )}
          </Box>
        </HeaderContainer>
        {showSuggestions && debouncedValue && (
          <Box margin={{ left: 'small', right: 'small', top: 'small' }} className="suggestionsList">
            <BotSuggestionList query={debouncedValue} onSearch={onSearch} showNumbers={false} />
          </Box>
        )}
        {result && (
          <Box pad="small">
            <Box direction="row" pad={{ top: 'small', bottom: 'small' }} className="responseIntro">
              <span style={{ color: 'var(--at-adenin-red)', fontWeight: 'bold' }}>
                <Image
                  src={helix}
                  style={{
                    width: '20px', position: 'relative', top: '3px', marginRight: '4px',
                  }}
                />
                {result.intent?.unknown && getRandomPhrase(phrases.failed)}
                {result.intent?.type === 'multiple-matched-cards' && getRandomPhrase(phrases.ambiguous)}
                {result.card && !result.intent?.unknown && getRandomPhrase(phrases.success)}
              </span>
            </Box>
            {result.card && result.intent.type === 'unknown-utterance' && (
              <RenderedCard
                metadata={{ Id: result.intent.requestId, AdaptiveCardDefinition: result.intent.view }}
                data={result.intent.data}
                content={AdaptiveCard}
                notification
              />
            )}
            {result.card && result.intent.type !== 'unknown-utterance' && (
              <>
                <ProcessedCard metadata={result.card} data={{ Data: result.intent.data, ErrorCode: result.intent.data.ErrorCode ? parseInt(result.intent.data.ErrorCode, 10) : 0 }} content={AdaptiveCard} sidebar />
                {!result.card?.UserActions.pinned && showPin && (
                  <PinTip>
                    <Box direction="row" pad="small" align="center">
                      <Box direction="column">
                        <Text size="small" color="white">
                          You frequently look for this Card.
                        </Text>
                        <Text size="small" color="white">
                          Would you like to pin it to your Board?
                        </Text>
                      </Box>
                      <Button label="Pin it" primary color="blue" hoverIndicator onClick={() => pinCard(result.card.Id)} style={{ width: '96px', height: '48px' }} />
                    </Box>
                  </PinTip>
                )}
              </>
            )}
            {result.intent?.type === 'multiple-matched-cards' && debouncedValue && <BotSuggestionList query={debouncedValue} onSearch={onSearch} className="suggestionsList" />}
          </Box>
        )}
        {value && !result && !showSuggestions && (
          <Box height="small" fill="horizontal" justify="center" align="center">
            <Spinner color="brand" />
          </Box>
        )}
      </AssistantContainer>
    </Box>
  );
}

export default Assistant;
