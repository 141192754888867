import { useId } from 'react';

import { usePlatformQueryUnfiltered } from '../../../hooks';
import ProcessedCard from './ProcessedCard';

function AssistantCard({
  metadata, draggable, drag, iframe = false, shadow = true,
}) {
  const id = useId();
  const { data, error } = usePlatformQueryUnfiltered(`/gateway/card/${metadata.Id}`);

  return (
    <ProcessedCard
      key={data?.Data?._hash || id}
      metadata={metadata}
      data={data}
      draggable={draggable}
      drag={drag}
      iframe={iframe}
      shadow={shadow}
      error={error}
    />
  );
}

export default AssistantCard;
