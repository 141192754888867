import {
  Box,
  Button,
  Header,
  Text,
  ThemeContext,
} from 'grommet';
import { Notification, SettingsOption, Tip } from 'grommet-icons';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
import { dispatch } from 'use-bus';

import { useIsFetching, useIsMutating, useQueryClient } from '@tanstack/react-query';

import config from '../../config';
import { usePlatformQueries, usePlatformQuery, useSessionStore } from '../../hooks';
import Notifications from '../../sidebars/Notifications';
import {
  hasNotificationDot,
  isMobilePlatform,
  isSSR,
  setAppBadge,
} from '../../utils';
import { expandBody } from '../../utils/templating';
import Board from '../../views/assistant/Board';
import ImageLoader from '../utils/ImageLoader';
import LoadingBar from '../utils/LoadingBar';

const Logo = styled(ImageLoader)`
  max-width: calc(100vw - 270px);
  max-height: 40px;
  object-fit: contain;
`;

const StickyBox = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 5;
`;

function NotificationBadge({ notificationCards, toggleSidebar }) {
  const allCards = useMemo(() => {
    if (notificationCards?.ErrorCode || !Array.isArray(notificationCards)) {
      return [];
    }

    return notificationCards;
  }, [notificationCards]);

  const boardCards = useMemo(() => allCards.filter((c) => c.UserActions?.pinned === true), [allCards]);
  const boardResults = usePlatformQueries(boardCards.map((item) => `/gateway/card/${item.Id}`));

  const notificationDots = useMemo(() => {
    const r = [];

    for (let i = 0; i < boardResults.length; i++) {
      const result = boardResults[i];

      if (!result.isFetched) {
        continue;
      }

      const metadata = boardCards[i];

      if (hasNotificationDot(result.data, metadata)) {
        r.push({ data: result.data, metadata });
      }
    }

    return r;
  }, [boardCards, boardResults]);

  useEffect(() => {
    if (isSSR) {
      return;
    }

    const count = notificationDots.length;

    if (count < 1) {
      setAppBadge(0);
    } else {
      setAppBadge(count);
    }
  }, [notificationDots.length]);

  const onClick = () => {
    toggleSidebar('Notifications', true);
  };

  return (
    <Button
      title="Notifications"
      a11yTitle="Notifications"
      icon={<Notification />}
      badge={
        (notificationDots.length)
          ? {
              value: notificationDots.length,
              max: 99,
            }
          : null
      }
      onClick={onClick}
      style={{ padding: '0 18px' }}
    />
  );
}

const MemoizedNotificationBadge = memo(NotificationBadge);

function AppHeader() {
  const { data } = usePlatformQuery('/userworkplace/usercards');
  const session = useSessionStore((state) => state.session);
  const isDarkMode = useContext(ThemeContext).dark;
  const queryClient = useQueryClient();
  const activeMutationCount = useIsMutating();
  const activeQueryCount = useIsFetching();

  queryClient.removeQueries({ predicate: ({ queryKey }) => (!queryKey || (Array.isArray(queryKey) && queryKey.length && !queryKey[0])) });

  const logoStyle = useMemo(() => {
    if (isDarkMode) {
      return {
        filter: 'brightness(0) invert(1)',
      };
    }

    return undefined;
  }, [isDarkMode]);

  const logoMargin = useMemo(() => ({ left: 'small' }), []);
  const avatarMargin = useMemo(() => ({ right: 'small' }), []);
  const notificationCards = useMemo(() => (Array.isArray(data?.Data) ? data.Data : []), [data?.Data]);

  const toggleSidebar = useCallback((title, wipeDefault = false) => {
    dispatch({ type: 'sidebar.toggle', title, wipeDefault });
  }, []);

  if (!session?.authenticated) return null;

  const header = (
    <StickyBox>
      <Header height="60px" background="navigation-bar" align="center" direction="row" justify="between" gap="medium" position="sticky" elevation="small">
        <Box fill="vertical" justify="center">
          <Link to={config.defaultPath || '/app/assistant/board'}>
            <Logo src={session.logoUrl} shape="rect" height={40} margin={logoMargin} style={logoStyle} />
          </Link>
        </Box>
        <Box direction="row" fill="vertical">
          <Button
            title="Settings"
            a11yTitle="Settings"
            fill="vertical"
            icon={<SettingsOption />}
            onClick={() => toggleSidebar('ManageAssistant', true)}
            className="manage-btn"
            style={isDarkMode ? { borderRadius: '0px', borderRight: '1px solid var(--at-monument-slate)', padding: '0 18px' } : { borderRadius: '0%', borderRight: '1px solid var(--at-pale-pebble)', padding: '0 18px' }}
          />
          {data && <MemoizedNotificationBadge notificationCards={notificationCards} toggleSidebar={toggleSidebar} />}
          <Button
            title="Assistant"
            a11yTitle="Assistant"
            fill="vertical"
            icon={<Tip />}
            onClick={() => toggleSidebar('Assistant', true)}
            className="chat-btn"
            margin={{ right: 'small' }}
            style={
              isDarkMode
                ? {
                    borderRadius: '0px', borderLeft: '1px solid var(--at-monument-slate)', borderRight: '1px solid var(--at-monument-slate)', padding: '0 18px',
                  }
                : {
                    borderRadius: '0px', borderLeft: '1px solid var(--at-pale-pebble)', borderRight: '1px solid var(--at-pale-pebble)', padding: '0 18px',
                  }
            }
          />
          <Button
            title="Profile"
            a11yTitle="Profile"
            fill="vertical"
            onClick={() => toggleSidebar('Account', true)}
            className="account-btn"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <ImageLoader
              align="center"
              flex={false}
              src={session.avatarUrl}
              height={44}
              width={44}
              shape="circle"
              avatar
              justify="center"
              overflow="hidden"
              round="full"
              size="44px"
              margin={avatarMargin}
            />
            {!isMobilePlatform && (
              <Text size="small" margin={{ right: 'small' }}>
                {session.FirstName || 'Your Profile'}
              </Text>
            )}
          </Button>
        </Box>
      </Header>
      <LoadingBar background="rgba(0,0,0,0)" color="brand-primary" loading={activeQueryCount > 0 || activeMutationCount > 0} />
    </StickyBox>
  );

  const nonGridBoard = location.pathname.includes('board') && !location.pathname.includes('grid-board');

  if (!isSSR && (location.pathname.includes('board') || location.pathname.includes('modal') || location.pathname.includes('app/assistant/card'))) {
    return (
      <Box direction="column" fill="horizontal">
        {header}
        <Box direction="row" width="100%">
          <Box direction="column" width={nonGridBoard ? '75%' : '100%'}>
            <Board />
          </Box>
          {nonGridBoard && (
            <Box width="25%">
              <Notifications hideFormClose />
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  return header;
}

export default AppHeader;
