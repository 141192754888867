import { StatusWarning } from 'grommet-icons';
import { Anchor, Box, Text } from 'grommet/components';
import { useNavigate } from 'react-router';
import { dispatch } from 'use-bus';

import config, { hostUrl } from '../../config';
import { useToken } from '../../hooks';

function NotFoundCard({ data }) {
  const token = useToken();
  const navigate = useNavigate();

  const onLogOut = () => {
    let logoutUrl = `${hostUrl}/account/logout?returnUrl=${encodeURIComponent(window.location.href)}`;

    if (config.useTokenAuth && token?.access_token) {
      logoutUrl += `&revoke_token=${token?.access_token}`;
    }

    document.cookie = 'oidc=;path=/';
    document.cookie = 'tenant=;path=/';

    if (config.useTokenAuth && token?.access_token) {
      dispatch({ type: 'pkce.clear', logoutUrl });
      return;
    }

    navigate(logoutUrl);
  };

  const restriction = data?.ErrorCodeDetail === '404.1';
  return (
    <Box align="center" justify="center" pad={{ horizontal: 'small', top: 'small', bottom: 'medium' }} fill>
      <Box direction="row" align="center">
        <StatusWarning size="30px" />
        <Text size="xlarge" margin={{ left: 'small' }}>
          {restriction ? 'We cannot show this' : 'Card not found'}
        </Text>
      </Box>
      <Text margin={{ vertical: 'medium', horizontal: 'small' }} color="text-weak" textAlign="center">
        {restriction
          ? (
              'This Card was created by another adenin account. It cannot be shown to users of this tenant.'
            )
          : (
              'This Card doesn\'t exist or it has been deleted. Check you have the correct Card Share URL.'
            )}
      </Text>
      {restriction && <Anchor label="Sign in with a different account" onClick={onLogOut} />}
    </Box>
  );
}

export default NotFoundCard;
