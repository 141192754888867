import { Box } from 'grommet';

import { withSession } from '../components/EmbedSession';
import Assistant from '../sidebars/Assistant';

function EmbedAssistant() {
  return (
    <Box margin="xsmall">
      <Assistant iframe />
    </Box>
  );
}

export default withSession(EmbedAssistant);
