import { Close } from 'grommet-icons';
import {
  Box,
  Button,
  Grommet,
  Heading,
  Image,
  Layer,
  Notification,
  Spinner,
  Text,
} from 'grommet/components';
import { deepMerge } from 'grommet/utils';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { TourRoot } from 'react-rtg';
import useBus, { dispatch } from 'use-bus';

import { useIsMutating, useQueryClient } from '@tanstack/react-query';

import ConfirmEmailBanner from '../components/ConfirmEmailBanner';
import GlobalError from '../components/GlobalError';
import HelpdeskModal from '../components/HelpdeskModal/HelpdeskModal';
import IFrameLayer from '../components/IFrameLayer';
import SessionCheck from '../components/SessionCheck';
import ShareModal from '../components/ShareModal';
import TrialBanner from '../components/TrialBanner';
import NotFoundCard from '../components/board-cards/NotFoundCard';
import AppHeader from '../components/layout/AppHeader';
import AppHeaderSkeleton from '../components/layout/AppHeaderSkeleton';
import AppSidebar from '../components/layout/AppSidebar';
import config, { hostUrl } from '../config';
import {
  AuthContext,
  Authenticated,
  createQueryKey,
  useColorScheme,
  useReportInfiniteRender,
  useSessionStore,
  useSidebarOpen,
} from '../hooks';
import robboLaunch from '../images/robo_launch_800px.png';
import baseTheme from '../theme';
import {
  ErrorReporter,
  TRACKING_EVENTS,
  isMobilePlatform,
  isSSR,
  setAppBadge,
  track,
} from '../utils';
import Exchange from '../views/auth/Exchange';
import Start from '../views/auth/Start';

const containerStyle = { minHeight: '100%' };

function App() {
  const [loading, setLoading] = useState(true);
  const session = useSessionStore((state) => state.session);
  const navigate = useNavigate();

  const sidebarOpen = useSidebarOpen((state) => state.sidebarOpen);
  const setSidebarOpen = useSidebarOpen((state) => state.setSidebarOpen);

  const [sidebarConfig, setSidebarConfig] = useState({});

  const colorScheme = useColorScheme(baseTheme.defaultMode);

  const [theme, setTheme] = useState(baseTheme);

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState('');

  const [notification, setNotification] = useState(false);

  const queryClient = useQueryClient();
  const activeMutationCount = useIsMutating();

  useReportInfiniteRender(1000);

  const location = useLocation();

  useEffect(() => {
    const authListener = async (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      if (event.data === 'closeAuthPopup') {
        queryClient.setQueryData(createQueryKey(`/gateway/card/${window.__AT_TMP_AUTH_CARD}`, session.UserId), () => null);
        await queryClient.invalidateQueries();
        dispatch('oauth.status.invalidate');
      }
    };

    window.addEventListener('message', authListener);

    if (window.location.href.includes('popup=true') && window.opener) {
      window.opener.postMessage('closeAuthPopup', window.location.origin);
      window.close();
    }

    return () => window.removeEventListener('message', authListener);
  }, [queryClient, session.UserId]);

  useEffect(() => {
    const unloadHandler = () => {
      setAppBadge(undefined);
    };

    window.addEventListener('beforeunload', unloadHandler);

    return () => window.removeEventListener('beforeunload', unloadHandler);
  }, []);

  useEffect(() => {
    if (!isSSR) {
      const errorListener = (e) => {
        if (e.target?.tagName === 'IMG') {
          ErrorReporter.warn(`[Listener] ${e.target.src}, tagName ${e.target.tagName}`);
        } else if (e.message) {
          ErrorReporter.error(`[Listener] ${e.message} ${e.filename} L${e.lineno}:${e.colno}`);
        }
      };

      window.addEventListener('error', errorListener, true);

      return () => window.removeEventListener('error', errorListener);
    }
  }, []);

  useEffect(() => {
    if (isSSR) {
      return;
    }

    const pwa = window.location.search.includes('pwa=1');

    if (isMobilePlatform) {
      track(pwa ? TRACKING_EVENTS.AssistantStartMobilePWA : TRACKING_EVENTS.AssistantStartMobile);
    } else {
      track(pwa ? TRACKING_EVENTS.AssistantStartDesktopPWA : TRACKING_EVENTS.AssistantStartDesktop);
    }
  }, []);

  useEffect(() => {
    if (typeof session.customTheme === 'object') {
      setTheme(deepMerge(baseTheme, session?.customTheme));
    }
  }, [session?.customTheme]);

  useEffect(() => {
    if (colorScheme) {
      document.documentElement.classList.remove('theme-dark');
    } else {
      document.documentElement.classList.add('theme-dark');
    }
  }, [colorScheme]);

  const changeSidebar = useCallback((event) => {
    let sbOpen = !sidebarOpen; // default: toggle current state
    if (event.open !== undefined) sbOpen = event.open === true; // set specific state when provided in event
    setSidebarConfig(event);
    setSidebarOpen(sbOpen);
  }, [setSidebarOpen, sidebarOpen]);

  const [iFrameState, setIFrameState] = useState();

  useBus('iframe.open', (event) => {
    const url = iFrameState?.url;
    let newUrl = event.url;

    if (url && newUrl.startsWith('?')) {
      if (url.indexOf('?') > 0) {
        newUrl = `${url.substring(0, url.indexOf('?'))}${newUrl}`;
      } else {
        newUrl = url + newUrl;
      }
    }

    if (url === newUrl) {
      return;
    }

    setIFrameState({
      show: true,
      url: newUrl,
    });
  }, [iFrameState?.url]);

  useBus('iframe.close', () => {
    setIFrameState({
      show: false,
    });
  });

  /* useEffect(() => {
    if (window.Intercom) {
      window.Intercom('update', { hide_default_launcher: false });
    }
  }); */

  useEffect(() => {
    dispatch('overlays.close');
    if (location.pathname.includes('app/modal')) {
      console.log('Modal path change detected');

      const path = location.pathname + location.search;
      let modal = path.replace('app/modal/', '');

      if (modal.includes('?')) {
        modal += '&frame=1';
      } else {
        modal += '?frame=1';
      }

      dispatch({
        type: 'iframe.open',
        url: `${hostUrl}/${modal}${location.hash}`,
      });
    }

    if (location.pathname.includes('app/assistant/card/')) {
      console.log('Sidebar card change detected');
      const id = location.pathname.replace('/app/assistant/card/', '');
      changeSidebar({
        type: 'sidebar.toggle', component: 'CardLoader', id, wipeDefault: true,
      });
    }
  }, [location.hash, location.pathname, location.search]);

  useEffect(() => {
    if (session?.authenticated) {
      setLoading(false);
    }
  }, [session?.authenticated]);

  useBus('sidebar.toggle', changeSidebar, [sidebarOpen]);

  const gotoBoard = () => {
    setShowError(false);
    navigate(config.defaultPath);
  };

  const refresh = () => window.location.reload(false);

  useBus('error.network', async (event) => {
    const { response } = event;
    let errorJsx;

    try {
      const errorJson = response.data;
      if (errorJson?.Data?.ErrorCodeDetail === '404.1') {
        errorJsx = (
          <Box margin="small">
            <NotFoundCard data={errorJson.Data} />
          </Box>
        );
      }
    } catch { /* empty */ }

    if (!errorJsx) {
      errorJsx = (
        <Box direction="column" width="medium" justify="between" pad="medium" margin="medium" gap="small">
          <Text>
            <Box gap="small">
              <Text weight="bold">A network error occurred</Text>
              <Text>{`${response.status} ${response.statusText}`}</Text>
            </Box>
          </Text>
          <Box direction="row" gap="small">
            <Button label="Go to Board" secondary onClick={gotoBoard} />
            <Button label="Refresh" primary onClick={refresh} />
          </Box>
        </Box>
      );
    }

    setError(errorJsx);
    setShowError(true);
  });

  useBus('toast.open', (event) => {
    setNotification(event);
  });

  const [showSplash, setShowSplash] = useState((new URLSearchParams(location.search)).get('splash') === 'answer-cards-link');
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (session?.authenticated) {
      setIsExpired(!!session.usageStatus?.Plan?.IsExpired);
    }
  }, [session]);

  const backgroundImage = theme.global.colors['background-image'] || undefined;
  const grommetStyle = backgroundImage
    ? {
        backgroundImage: `${backgroundImage}`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed',
      }
    : {};

  const headerLessPaths = [
    'app/loader',
    'app/designer',
    'app/cardcreator',
    'app/embedboard',
    'app/testing',
    'app/embedcardframe',
    'app/billing',
    'app/upgrade',
    'app/subscription',
    'app/subscription-test',
    'app/subscription-test2',
    'app/assistant/themedesigner',
    'app/assistant/chat',
    'app/admin',
  ];

  const dontRenderHeader = headerLessPaths.some((path) => location.pathname.includes(path));

  return (
    <AuthContext>
      <Grommet
        full={location.pathname.includes('embedcardframe') ? undefined : 'min'}
        theme={theme}
        themeMode={colorScheme ? 'light' : 'dark'}
        background={(location.pathname.includes('admin') || location.pathname.includes('embedcardframe')) ? 'white' : 'page-background'}
        id="pageContainer"
        style={grommetStyle}
      >
        <ErrorBoundary FallbackComponent={GlobalError}>
          <SessionCheck>
            <ConfirmEmailBanner />
            {isExpired && (
              <Notification
                status="warning"
                message="Plan is expired and no payment happened"
                global
              />
            )}
            <TrialBanner />
            <TourRoot>
              <Box fill="horizontal" style={containerStyle}>
                {dontRenderHeader && <div />}
                {!dontRenderHeader && (loading ? <AppHeaderSkeleton /> : <AppHeader />)}
                <Box flex direction="row" fill>
                  <Box fill="horizontal">
                    <Outlet />
                  </Box>
                  {sidebarOpen && (
                    <Layer animate modal full="vertical" position="right" responsive onClickOutside={() => dispatch({ type: 'sidebar.toggle', open: false })}>
                      <AppSidebar {...sidebarConfig} />
                    </Layer>
                  )}
                  {showError && (
                    <Layer modal position="center" responsive>
                      {error}
                    </Layer>
                  )}
                  {notification && (
                    <Notification
                      toast
                      status={notification.status || 'normal'}
                      title={notification.title}
                      message={notification.message}
                      onClose={() => setNotification(false)}
                    />
                  )}
                  <IFrameLayer {...iFrameState} />
                  <ShareModal />
                  <HelpdeskModal />
                </Box>
              </Box>
            </TourRoot>
          </SessionCheck>
          <Authenticated>
            {location.pathname.includes('app/callback') && <div />}
            {location.pathname.includes('app/auth/start') && <Start />}
            {location.pathname.includes('app/auth/exchange') && <Exchange />}
          </Authenticated>
          {(location.pathname.includes('app/admin') || location.pathname.includes('app/grid')) && !location.pathname.includes('modal') && activeMutationCount > 0 && (
            <Layer background="rgba(0,0,0,0)" full animate={false}>
              <Box fill align="center" justify="center">
                <Spinner size="large" color="brand" />
              </Box>
            </Layer>
          )}
          {showSplash && (
            <Layer modal onClickOutside={() => setShowSplash(false)}>
              <Box width="large" overflow="auto" gap="medium">
                <Box pad={{ horizontal: 'medium', top: 'medium' }} direction="row" fill="horizontal" justify="between" gap="medium" flex="grow">
                  <Heading level="4" margin="none">We&apos;ve created a space for your Answer Cards</Heading>
                  <Button icon={<Close />} onClick={() => setShowSplash(false)} />
                </Box>
                <Box direction="row" flex="grow" gap="medium">
                  <Box height="300px" pad={{ left: 'medium' }} overflow="hidden">
                    <Image
                      src={robboLaunch}
                      width="100%"
                    />
                  </Box>
                  <Box gap="small" pad={{ right: 'medium', bottom: 'medium' }}>
                    <Text>Your SharePoint web part is now linked to this space.</Text>
                    <Text>Simply add new Adaptive Cards to this Board, by clicking on the Settings icon in the top right-hand corner.</Text>
                    <Text>Don&apos;t forget to give them a memorable title, so that the web part can find them.</Text>
                  </Box>
                </Box>
              </Box>
            </Layer>
          )}
        </ErrorBoundary>
      </Grommet>
    </AuthContext>
  );
}

export default App;
