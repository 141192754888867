import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';

import config, { apiUrl, defaultFetchOpts as opts } from '../config';
import { ErrorReporter } from '../utils';

function Authenticate() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const checkToken = useCallback(async (token, returnUrl) => {
    opts.headers.Authorization = `Bearer ${token}`;

    let url = `${apiUrl}/session/myprofile?signIn=true`;

    if (searchParams.has('channel')) {
      url += `&channel=${searchParams.get('channel')}`;
    }

    const response = await fetch(url, opts);
    const data = await response.json();

    if (!response.ok || !data.Data?.authenticated) {
      ErrorReporter.error(`Token response was not valid when trying to authenticate embed route for ${returnUrl}`);
      window.location.href = `${window.location.origin}/login?returnUrl=${encodeURIComponent(returnUrl)}`;
      return;
    }

    sessionStorage.setItem('pkce_token', JSON.stringify({
      access_token: token,
    }));

    const normReturn = returnUrl.toLowerCase();

    if (normReturn.includes('copynotebook') || normReturn.includes('oauth2connector')) {
      window.location.href = returnUrl;
      return;
    }

    navigate(returnUrl);
  }, [navigate, searchParams]);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) {
      return;
    }

    const returnUrl = searchParams.get('returnUrl');

    if (!returnUrl) {
      navigate(config.defaultPath);
      return;
    }

    if (!returnUrl.startsWith('http')) {
      // returnUrl = `${window.location.origin}${returnUrl}`;
    }

    let token = searchParams.get('token');

    if (!token) {
      window.location.href = `${window.location.origin}/login?returnUrl=${encodeURIComponent(returnUrl)}`;
      return;
    }

    const src = searchParams.get('tokensrc');

    if (src) {
      token = `${src}:${token}`;
    }

    checkToken(token, returnUrl);
    setChecked(true);
  }, [checkToken, checked, navigate, searchParams]);

  return null;
}

export default Authenticate;
