import { useEffect, useRef, useState } from 'react';

import { ErrorReporter } from '../utils';

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();
  const [id, setId] = useState();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (delay !== null) {
      const newId = setInterval(tick, delay);
      setId(newId);

      return () => clearInterval(newId);
    }
  }, [delay]);

  return () => clearInterval(id);
};

export const useRenderCount = () => {
  const renderCount = useRef(0);
  let renderCountLocal = renderCount.current;

  useEffect(() => {
    renderCount.current = renderCountLocal;
  });

  renderCountLocal++;

  return {
    count: renderCount.current,
    reset() {
      renderCountLocal = 0;
      renderCount.current = 0;
    },
  };
};

export const useReportInfiniteRender = (maxRenders) => {
  const { count, reset } = useRenderCount();
  // useInterval(reset, 10000);

  if (count > maxRenders) {
    const message = `Infinite Renders limit of ${maxRenders} reached at ${new Date().toISOString()}`;
    console.warn(message);
    ErrorReporter.warn(message);
    reset();
  }
};
