import { create } from 'zustand';

export const useSessionStore = create<SessionState>()((set) => ({
  session: {},
  setSession: (data: Session) => {
    set({ session: data });
  },
}));

export const useSidebarOpen = create((set) => ({
  sidebarOpen: false,
  setSidebarOpen: (open: boolean) => {
    set({ sidebarOpen: open });
  },
}));

export const useCurrentColumns = create((set) => ({
  currentColumns: 0,
  setCurrentColumns: (currentColumns: number) => {
    set({ currentColumns });
  },
}));

export const useBoardScale = create((set) => ({
  scale: 1,
  setScale: (scale: number) => {
    set({ scale });
  },
}));

export const useIsBannerShowing = create((set) => ({
  showing: false,
  setShowing: (showing: boolean) => {
    set({ showing });
  },
}));

export interface SessionState {
  session: Session
  setSession: (data: Session) => void
}

export interface Session {
  services?: Services
  useInstrumentation?: boolean
  isHost?: boolean
  companyName?: string
  customCssId?: any
  customTheme?: any
  usageStatus?: UsageStatus
  tenantId?: string
  tenantIdRaw?: number
  tenantCreatedAt?: number
  customLogoId?: any
  logoUrl?: string
  customFrontendURL?: string
  hasAvatar?: boolean
  userInitials?: string
  userCreatedAt?: number
  tourStatus?: any
  isEmailConfirmed?: boolean
  avatarUrl?: string
  isOIDC?: boolean
  demoMode?: boolean
  DisplayName?: string
  Email?: string
  Roles?: string[]
  UserId?: number
  UserName?: string
  UserCacheKey?: string
  authenticated?: boolean
  lang?: string
  FirstName?: string
  LastName?: string
  slot?: string
  oidcProvider?: string
  _hash?: string
}

export interface Services {
  version: number
  api: string
  APIHost: string
  login: string
  eventlog: string
  chatbot: string
  cardDefinition: string
  cardData: string
  recognizeIntent: string
  workplaceCards: string
  workplaceChangeStatus: string
  notificationSubscription: string
  mybriefing: string
  briefingUpdateStatus: string
  briefingUpdateCardsStatus: string
  cardCatalog: string
}

export interface UsageStatus {
  Plan: Plan
  LastUpdate: string
  PeriodStart: string
  PeriodEnd: string
  UsedCardPasses: number
  UsedCards: number
  UsedUsers: number
  UsedConnectors: number
  MaxCardPasses: number
  OverusageDate: string
  UsageSuspensionDate: any
  RedCard: boolean
  Status: string
}

export interface Plan {
  Id: number
  Name: string
  IsFree: boolean
  IsExpired: boolean
  IsTrialActive: boolean
  PaymentPending: boolean
  TrialDaysRemaining?: number
  LastTransaction: LastTransaction
  PersistedPlan?: PersistedPlan
  Features: Features
  SubscriptionEndDate: string
  ExternalSubscriptionId: string
  DisableCheckout: boolean
  PlanInterval: string
  PlanQuantity: number
  AppsQuantity: number
  UsersQuantity: number
  QuantityType: number
  ActiveUsersLimit: number
  ActiveAppsLimit: number
  IsQuantityChanging: boolean
  UpcomingQuantity: number
  WasReassigned: boolean
}

export interface LastTransaction {
  PaddleId: string
  Date: string
  Failed: boolean
  Subtotal: number
}

export interface Features {
  MaxUserCount: number
  CardPasses: number
  CustomConnectors: boolean
  ManageRoles: boolean
  LegacyFeature: boolean
  PreviewFeature: boolean
  EnterprisePreviewFeature: boolean
}

export interface PersistedPlan {
  Id: number
  DisplayName: string
  IsFree: boolean
}
