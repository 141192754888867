import { Anchor } from 'grommet/components';
import { Link } from 'react-router';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  display: flex;
`;

export default function GrommetGatsbyLink(props) {
  return <Anchor as={StyledLink} {...props} />;
}
