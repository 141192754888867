import {
  Anchor,
  Box,
  Spinner,
  Text,
} from 'grommet/components';
import { memo } from 'react';

import { usePlatformQuery } from '../hooks';

function SuggestionList({
  query, onSearch, showNumbers = true, submitNumbers = false,
}) {
  const { data } = usePlatformQuery(`/intents/suggest?query=${query}`);
  const entries = [];
  const useNumbers = showNumbers || submitNumbers;

  if (Array.isArray(data?.Data?.items)) {
    for (let i = 0; i < data.Data.items.length; i++) {
      const item = data.Data.items[i];
      const indexOfQuery = item.phrase?.toLowerCase().indexOf(query?.toLowerCase()) || item.phrase?.length;
      const preQuery = item.phrase.substring(0, indexOfQuery);
      const postQuery = item.phrase.substring(indexOfQuery + query.length, item.phrase.length);

      let content = (showNumbers ? `${(i + 1)}. ` : '') + item.phrase;

      // only replace if the reconstructed phrase with query in place is the same as the original
      if (`${preQuery}${query}${postQuery}`?.toLowerCase() === content?.toLowerCase()) {
        content = (
          <>
            {showNumbers && <Text>{`${i + 1}. `}</Text>}
            {preQuery && <Text weight="bold" dangerouslySetInnerHTML={{ __html: preQuery }} />}
            <Text dangerouslySetInnerHTML={{ __html: query }} />
            {postQuery && <Text weight="bold" dangerouslySetInnerHTML={{ __html: postQuery }} />}
          </>
        );
      } else {
        entries.push(<Anchor key={item.intentId} onClick={() => onSearch(useNumbers ? (`${i + 1}`) : item.phrase)} margin="xsmall" dangerouslySetInnerHTML={{ __html: content }} />);
        continue;
      }

      entries.push(
        <Anchor key={item.intentId} onClick={() => onSearch(useNumbers ? (`${i + 1}`) : item.phrase)} margin="xsmall">
          {content}
        </Anchor>,
      );
    }
  }

  if (data && !entries?.length) {
    return null;
  }

  return (
    <Box justify="center" align={data ? 'start' : 'center'} background="background-front" pad="small" gap="xsmall" elevation="medium">
      {data ? entries : <Spinner color="brand" />}
    </Box>
  );
}

export default memo(SuggestionList, (prevProps, nextProps) => prevProps.query === nextProps.query);
