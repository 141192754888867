import { Buffer } from 'buffer';

import {
  Anchor,
  Box,
  Card,
  CardBody,
  CardHeader,
  Grommet,
  Heading,
  Text,
} from 'grommet/components';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';

import GrommetGatsbyLink from '../components/GrommetGatsbyLink';
import LandingFooter from '../components/landing/LandingFooter';
import LandingHero from '../components/landing/LandingHero';
import { LandingButton, Logo } from '../components/landing/styles';
import config, { hostUrl } from '../config';
import adeninLogo from '../images/Logo_adenin.svg';
import theme from '../theme';
import { ErrorReporter, isSSR } from '../utils';

function normalizeTitle(model) {
  if (model.requestedConnectorTitle) {
    return model.requestedConnectorTitle;
  }

  if (model.requestedConnectorName === 'office-365') {
    return 'Microsoft';
  }

  if (model.requestedConnectorName.startsWith('google-')) {
    return 'Google';
  }

  if (model.requestedConnectorName === 'github') {
    return 'GitHub';
  }
}

function title(model) {
  switch (model.statusCode) {
    case 300:
    case 309:
      return 'We already have an account for you';
    default: break;
  }

  if (model.isOidc) {
    return `Login with ${normalizeTitle(model)} failed`;
  }

  return `Authorizing with ${normalizeTitle(model)} failed`;
}

function message(model) {
  switch (model.statusCode) {
    case 300:
      return (
        <Text weight={600}>
          When you signed in with
          {' '}
          <Text weight="bold">{normalizeTitle(model)}</Text>
          {' '}
          we noticed that there is already an account that&apos;s linked to
          {' '}
          <Text weight="bold">{model.userAuthProviderTitle}</Text>
          {' '}
          instead
        </Text>
      );
    case 309:
      return (
        <Text weight={600}>
          When you signed in with
          {' '}
          <Text weight="bold">{normalizeTitle(model)}</Text>
          {' '}
          we noticed that an account for the same email address already exists with adenin. Do you want to link them?
        </Text>
      );
    default: break;
  }

  if (model.isOidc) {
    return (
      <Box gap="medium">
        <Text weight={600}>Either you have declined our request to link your user credentials, or your organization requires an administrator to approve adenin first.</Text>
        <Text weight={600}>You can either retry or just sign up using your email address.</Text>
        <Text color="status-error">{model.message}</Text>
      </Box>
    );
  }

  return (
    <Box gap="medium">
      <Text weight={600}>While accessing your account, we encountered an unexpected error. Please retry or contact our Support team quoting the below error.</Text>
      <Text><em>{model.message}</em></Text>
    </Box>
  );
}

function primaryButton(model, navigate) {
  switch (model.statusCode) {
    case 300:
      return (
        <LandingButton
          primary
          label={`Continue with ${model.userAuthProviderTitle}`}
          href={`${hostUrl}/OAuth2Connector/SignIn/${model.userAuthProviderName}?login_hint=${model.userEmail}`}
        />
      );
    case 309:
      return (
        <LandingButton
          primary
          label={`Link my account to ${normalizeTitle(model)}`}
          href={`${hostUrl}/OAuth2Connector/AssignAuthAndRedirect?authProvider=${model.requestedConnectorName}&returnUrl=${encodeURIComponent(config.defaultPath)}`}
        />
      );
    default: break;
  }

  if (model.isOidc) {
    return (
      <LandingButton
        primary
        label={`Retry with ${normalizeTitle(model)} or another service`}
        onClick={() => navigate('/login')}
      />
    );
  }

  if (model.returnUrl) {
    return (
      <LandingButton
        primary
        label={window.opener ? 'Dismiss' : 'Continue'}
        onClick={() => {
          if (window.opener) {
            window.opener.postMessage('closeAuthPopup', window.location.origin);
            window.close();
            return;
          }

          navigate(model.returnUrl);
        }}
      />
    );
  }

  return null;
}

function secondaryButton(model) {
  switch (model.statusCode) {
    case 300:
      return (
        <Anchor
          href="https://www.adenin.com/contact/"
          label={`I cannot sign in with ${model.userAuthProviderTitle} and need support`}
          target="_blank"
          rel="noopener"
          alignSelf="center"
        />
      );
    case 309:
      return (
        <GrommetGatsbyLink
          to="/login"
          label="Keep my account separate and sign in with my password"
          alignSelf="center"
        />
      );
    default: break;
  }

  if (model.isOidc) {
    return (
      <Anchor
        label="Contact adenin Support"
        onClick={() => window.Intercom('showNewMessage')}
        alignSelf="center"
      />
    );
  }

  return null;
}

function getComponents(model, navigate) {
  return {
    title: title(model),
    message: message(model),
    primaryButton: primaryButton(model, navigate),
    secondaryButton: secondaryButton(model),
  };
}

function AuthError() {
  const location = useLocation();
  const navigate = useNavigate();

  const components = useMemo(() => {
    const params = new URLSearchParams(location.search);

    if (!params.has('model')) {
      return undefined;
    }

    const base64 = params.get('model');
    const json = Buffer.from(base64, 'base64').toString('utf-8');

    try {
      const model = JSON.parse(json);
      ErrorReporter.error(`Authentication error message shown to user: ${model.message}`);
      return getComponents(model, navigate);
    } catch {
      return undefined;
    }
  }, [location.search, navigate]);

  useEffect(() => {
    if (!isSSR && components === undefined) {
      navigate(config.defaultPath);
    }
  }, [components, navigate]);

  useEffect(() => {
    if (window.Intercom && config.intercomAppId) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: config.intercomAppId,
      });
    }
  }, []);

  if (components === undefined) {
    return null;
  }

  return (
    <Grommet full theme={theme} themeMode="light" background="page-background" id="pageContainer">
      <Box fill justify="between">
        <LandingHero>
          <Card margin={{ vertical: 'xlarge' }} round={false} style={{ maxWidth: '625px' }}>
            <CardHeader
              flex={{ grow: 0, shrink: 0, basis: 'auto' }}
              direction="column"
              align="flex-start"
              justify="start"
              gap="0"
              pad={{ top: 'large', horizontal: 'medium', bottom: 'medium' }}
            >
              <Anchor>
                <Logo src={adeninLogo} />
              </Anchor>
              <Heading level="2" size="small" margin={{ top: 'xsmall', bottom: 'small' }} style={{ fontWeight: '600' }}>
                {components.title}
              </Heading>
            </CardHeader>
            <CardBody pad={{ top: 'none', horizontal: 'medium', bottom: 'large' }}>
              <Box gap="medium">
                {components.message}
                {components.primaryButton}
                {components.secondaryButton}
              </Box>
            </CardBody>
          </Card>
        </LandingHero>
        <LandingFooter />
      </Box>
    </Grommet>
  );
}

export default AuthError;
