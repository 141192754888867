import { PostHogProvider } from 'posthog-js/react';
import { memo } from 'react';
import { StyleSheetManager } from 'styled-components';
import type { StyledTarget } from 'styled-components/dist/types';

import { persistOptions, queryClient } from '@/lib/query';
import isPropValid from '@emotion/is-prop-valid';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

const posthogOptions = {
  api_host: 'https://eu.posthog.com',
  opt_in_site_apps: true,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true,
    },
  },
};

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: StyledTarget<'web'>) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

function Providers({ children }: { children: React.ReactNode }) {
  return (
    <PostHogProvider
      apiKey="phc_1mEwwsp16wREnGrf1UU67YmhTOmLioNpEyaYKQzRnUi"
      options={posthogOptions}
    >
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <PersistQueryClientProvider client={queryClient} persistOptions={persistOptions}>
          {children}
          <ReactQueryDevtools initialIsOpen={false} />
        </PersistQueryClientProvider>
      </StyleSheetManager>
    </PostHogProvider>
  );
}

export default memo(Providers);
