import { memo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';

import { useQueryClient } from '@tanstack/react-query';

import config, { apiUrl, defaultFetchOpts } from '../../config';
import { useToken } from '../../hooks';

function Exchange() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = useToken();
  const queryClient = useQueryClient();

  const { search } = location;

  let code = '';
  let state = '';

  if (search) {
    const params = new URLSearchParams(search);

    if (params.get('code')) {
      code = params.get('code');
    }

    if (params.get('state')) {
      state = params.get('state');
    }
  }

  const sendCode = useCallback(async () => {
    if (!token || !state) {
      return;
    }

    // eslint-disable-next-line react-compiler/react-compiler
    defaultFetchOpts.headers.Authorization = `Bearer ${token.access_token}`;

    const result = await fetch(`${apiUrl}/oauth2/token?code=${code}&state=${state}&redirect_uri=${window.location.origin}/app/auth/exchange`, defaultFetchOpts);

    if (!result.ok) {
      navigate(config.defaultPath);
    }

    const json = await result.json();

    if (json.ErrorCode !== 0) {
      await queryClient.invalidateQueries();
      navigate(config.defaultPath);
    }

    navigate(json.Data.returnUrl);
  }, [code, navigate, queryClient, state, token]);

  useEffect(() => {
    sendCode();
  }, [sendCode, token.access_token]);

  return null;
}

export default memo(Exchange);
