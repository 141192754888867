import { Notification } from 'grommet';
import { useEffect, useState } from 'react';

import { usePlatformMutation, useSessionStore } from '../hooks';

function ConfirmEmailBanner() {
  const session = useSessionStore((state) => state.session);

  const [showEmailResend, setShowEmailResend] = useState(false);
  const [emailResent, setEmailResent] = useState(false);

  useEffect(() => {
    if (session?.authenticated) {
      setShowEmailResend(!session.isEmailConfirmed && !session.isOIDC);
    }
  }, [session]);

  const { mutateAsync: resendActivationLink } = usePlatformMutation('/account/SendEmailActivationLink');

  const onResendActivationLink = async () => {
    await resendActivationLink();
    setEmailResent(true);
  };

  return showEmailResend
    ? (
        <div style={{ top: '0px' }}>
          <Notification
            status={emailResent ? 'normal' : 'warning'}
            message={emailResent ? 'Email has been resent' : 'Please confirm your email address email to activate your account.'}
            onClose={() => setShowEmailResend(false)}
            actions={[{ onClick: onResendActivationLink, label: 'Resend email', hidden: emailResent }]}
            global
          />
        </div>
      )
    : null;
}

export default ConfirmEmailBanner;
