import { Alert } from 'grommet-icons';
import {
  Anchor,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  DropButton,
  Footer,
  Form,
  FormField,
  Grommet,
  Heading,
  Image,
  Main,
  Spinner,
  Text,
  TextInput,
} from 'grommet/components';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import config, { apiUrl, defaultFetchOpts, hostUrl } from '../config';
import heroBg from '../images/Bridges_login_bg.jpg';
import adeninLogo from '../images/Logo_adenin.svg';
import chromeWebStore from '../images/chrome-web-store-btn.png';
import appSource from '../images/get-it-from-app-source.png';
import msTeams from '../images/ms-teams-btn.svg';
import theme from '../theme';
import { getCookie, isSSR, setAppBadge } from '../utils';

const LoginHero = styled(Main)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: auto;
`;

const LoginFooter = styled(Footer)`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Logo = styled(Image)`
  object-fit: contain;
  height: 33px;
  width: 60px;
`;

const Separator = styled(Box)`
  display: block;
  width: 100%;
  position: relative;
  text-align: center;
  margin: ${(props) => props.theme.global.edgeSize.medium} 0;
  background: ${(props) => props.theme.global.colors.background.light};

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 0.5px);
    width: calc(50% - 3ch);
    height: 1px;
    background: ${(props) => props.theme.global.colors.border.light};
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
`;

const LoginButton = styled(Button)`
  display: block;
  width: 100%;
  background: ${(props) => (props.primary ? props.theme.global.colors['orange!'] : props.theme.global.colors.background.light)};
  border: 1px solid ${(props) => (props.primary ? props.theme.global.colors['orange!'] : props.theme.global.colors.border.light)};
  color: ${(props) => (props.primary ? props.theme.global.colors.background.light : props.theme.global.colors.text.light)};
  text-align: center;
  font-weight: 600;
  height: 55px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: ${(props) => props.theme.global.edgeSize.small};
  &:hover {
    background: ${(props) => (props.primary ? props.theme.global.colors['red!'] : props.theme.global.colors['background-back'].light)};
    color: ${(props) => (props.primary ? props.theme.global.colors.background.light : props.theme.global.colors.text.light)};
  }
`;

const HelpDropButton = styled(DropButton)`
  text-align: end;
  &:hover {
    color: ${(props) => props.theme.global.colors['blue-900']};
  }
`;

function Login() {
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [busy, setBusy] = useState(false);

  let returnUrl = null;

  if (!isSSR) {
    const searchParams = new URLSearchParams(location.search);
    returnUrl = searchParams.get('returnUrl');

    try {
      setAppBadge(undefined);
      const status = { ts: (new Date()).toISOString(), status: 'login' };
      localStorage.setItem('AppBadge', JSON.stringify(status));
    } catch {
      // ignore if badge cannot be set to undefined
    }
  }

  useEffect(() => {
    const authProvider = getCookie('oidc');
    const tenantId = getCookie('tenant');
    if (authProvider) {
      document.cookie = `oidc_start=${authProvider};path=/`;
      document.cookie = `tenant_start=${tenantId};path=/`;
      document.cookie = 'oidc=;path=/';
      document.cookie = 'tenant=;path=/';
      window.location.href = `${hostUrl}/oauth2connector/signin/${authProvider}?tid=${tenantId}${returnUrl ? `&returnUrl=${encodeURIComponent(returnUrl)}` : ''}`;
    }
  }, [returnUrl]);

  const handleSubmit = useCallback(({ value }) => {
    setBusy(true);

    if (!showPasswordField) {
      fetch(`${apiUrl}/account/provider?usernameOrEmailAddress=${encodeURIComponent(value.email)}`, defaultFetchOpts)
        .then((res) => res.json())
        .then((providerData) => {
          if (providerData.ErrorCode === 0) {
            if (providerData.Data.authProvider) {
              document.cookie = `oidc_start=${providerData.Data.authProvider};path=/`;
              document.cookie = `tenant_start=${providerData.Data.tenantId};path=/`;
              window.location.href = `${hostUrl}/oauth2connector/signin/${providerData.Data.authProvider}?tid=${providerData.Data.tenantId}&login_hint=${value.email}${returnUrl ? `&returnUrl=${encodeURIComponent(returnUrl)}` : ''}`;
            } else {
              setBusy(false);
              setShowPasswordField(true);
            }

            setErrorText(null);
          } else {
            setErrorText(providerData.Data.ErrorText);
          }
        });

      return;
    }

    fetch(`${apiUrl}/account/login`, {
      method: 'POST',
      headers: {
        'X-Requested-By': 'Digital Assistant Client',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailAddress: value.email,
        password: value.password,
        returnUrl,
      }),
    })
      .then((response) => response.json())
      .then((loginData) => {
        console.log('login response', loginData);

        if (loginData.ErrorCode === 0) {
          const assignUrl = loginData.Data.requiresRedirect ? loginData.Data.redirectUrl : (returnUrl || config.defaultPath);

          console.log('login response was OK, assigning href to window', assignUrl);

          window.location.href = assignUrl;
          setErrorText(null);
          return;
        }

        setBusy(false);

        if (loginData.Data.ErrorText.indexOf('Your email address is not confirmed') > -1) {
          setErrorText(
            <>
              {loginData.Data.ErrorText ? `${loginData.Data.ErrorText}. ` : ''}
              <Anchor href={`${hostUrl}/Account/EmailActivation`} label="Resend activation email" target="_blank" rel="noopener" size="small" />
            </>,
          );
        } else if (loginData.ErrorCode === 401) {
          setErrorText(
            <>
              {loginData.Data.ErrorText ? `${loginData.Data.ErrorText}. ` : ''}
              <Anchor href={`${hostUrl}/Account/ForgotPassword`} label="Reset the account password" target="_blank" rel="noopener" size="small" />
            </>,
          );
        } else {
          setErrorText(loginData.Data.ErrorText);
        }
      });
  }, [returnUrl, showPasswordField]);

  return (
    <Grommet full theme={theme} themeMode="light" background="page-background" id="pageContainer">
      <Box direction="column" fill="vertical">
        <LoginHero style={{ backgroundImage: `url(${heroBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <Card margin={{ vertical: 'xlarge' }} round={false} style={{ width: '100%', maxWidth: '625px' }}>
            <CardHeader
              flex={{ grow: 0, shrink: 0, basis: 'auto' }}
              direction="column"
              align="flex-start"
              justify="start"
              gap="0"
              pad={{ top: 'large', horizontal: 'medium', bottom: 'medium' }}
            >
              <Anchor>
                <Logo src={adeninLogo} />
              </Anchor>
              <Heading level="1" size="small" margin={{ top: 'xsmall', bottom: 'small' }} style={{ fontWeight: '600' }}>
                Welcome back to adenin
              </Heading>
              <Text>
                Don&apos;t have an account?
                {' '}
                <Anchor href="https://www.adenin.com/register/" target="_blank" rel="noopener">
                  Create one
                </Anchor>
              </Text>
            </CardHeader>
            <CardBody pad={{ top: 'none', horizontal: 'medium', bottom: 'large' }}>
              <Form id="login-form" onSubmit={handleSubmit}>
                <FormField name="email" htmlFor="email" label="E-mail address" onFocus={() => setShowPasswordField(false)}>
                  <TextInput id="email" name="email" required disabled={busy} />
                </FormField>
                {showPasswordField && (
                  <FormField name="password" htmlFor="password" label="Password">
                    <TextInput id="password" name="password" type="password" required autoFocus disabled={busy} />
                  </FormField>
                )}
                <LoginButton primary margin={{ top: 'xsmall' }} type="submit" disabled={busy} label={busy ? <Spinner color="white" /> : 'Continue'} />
              </Form>
              {!!errorText && (
                <Box pad={{ top: 'medium' }} direction="row" align="center" justify="center" gap="small">
                  <Alert color="status-error" />
                  <Text size="small">{errorText}</Text>
                </Box>
              )}
              <Separator>or</Separator>
              <Box flex direction="column" gap="small">
                <LoginButton
                  href={`${hostUrl}/oauth2connector/signin/office-365?returnUrl=${encodeURIComponent(returnUrl || '/')}`}
                  icon={<Image width={24} src="https://www.adenin.com/assets/images/Logos/m365.svg" />}
                  label="Sign in with Microsoft"
                />
                <LoginButton
                  href={`${hostUrl}/oauth2connector/signin/google-signin?returnUrl=${encodeURIComponent(returnUrl || '/')}`}
                  icon={<Image width={24} src="https://www.adenin.com/assets/images/Logos/Google-Icon.svg" />}
                  label="Sign in with Google"
                />
                <LoginButton
                  href={`${hostUrl}/oauth2connector/signin/github?returnUrl=${encodeURIComponent(returnUrl || '/')}`}
                  icon={<Image width={24} src="https://www.adenin.com/assets/images/wp-images/logo/github.svg" />}
                  label="Sign in with Github"
                />
              </Box>
              <Box width="small" margin={{ top: 'small' }} alignSelf="end">
                <HelpDropButton
                  label="Trouble signing in?"
                  color="blue-900"
                  plain
                  dropAlign={{ top: 'bottom', right: 'right' }}
                  dropContent={(
                    <Box margin="small">
                      <Text size="small" color="text-weak">
                        &bull; For new users,
                        {' '}
                        <Anchor
                          href={`${hostUrl}/Account/EmailActivation`}
                          label="resend your activation email"
                          target="_blank"
                          rel="noopener"
                          size="small"
                          color="text-weak"
                        />
                      </Text>
                      <Text size="small" color="text-weak" margin={{ vertical: 'small' }}>
                        &bull; Existing users may
                        {' '}
                        <Anchor
                          href={`${hostUrl}/Account/ForgotPassword`}
                          label="reset the account password"
                          target="_blank"
                          rel="noopener"
                          size="small"
                          color="text-weak"
                        />
                      </Text>
                      <Text size="small" color="text-weak">
                        &bull; For issues signing in with another app,
                        {' '}
                        <Anchor href="https://www.adenin.com/support/" label="contact support" target="_blank" rel="noopener" size="small" color="text-weak" />
                      </Text>
                    </Box>
                  )}
                />
              </Box>
            </CardBody>
          </Card>
        </LoginHero>
        <LoginFooter pad={{ top: 'medium', bottom: 'large', horizontal: 'small' }}>
          <Text weight="bold" margin={{ vertical: 'small' }}>
            Stay connected from your favourite workplace apps
          </Text>
          <Box direction="row" gap="medium">
            <Anchor href="https://chrome.google.com/webstore/detail/adenin-digital-assistant/pfjagmnagmkgfplgejbkoeliagbljfnj?hl=en" target="_blank" rel="noopener nofollow">
              <Box border>
                <Image src={chromeWebStore} width="165px" height="50px" />
              </Box>
            </Anchor>
            {/* <Anchor
              href="https://slack.com/oauth/v2/authorize?client_id=2913770988.519260800198&amp;scope=chat:write,im:history,users:read,users:read.email"
              target="_blank"
              rel="noopener nofollow"
            >
              <Image src="https://platform.slack-edge.com/img/add_to_slack@2x.png" width="174px" height="50px" />
            </Anchor> */}
            <Anchor href="https://teams.microsoft.com/l/app/75950394-5cb3-4f08-80c4-16574d56a668?source=app-details-dialog" target="_blank" rel="noopener">
              <Image src={msTeams} width="172px" height="50px" />
            </Anchor>
            <Anchor href="https://appsource.microsoft.com/en-us/product/office/WA200005180?tab=Overview" target="_blank" rel="noopener">
              <Image src={appSource} height="50px" width="113px" />
            </Anchor>
          </Box>
          <Box direction="row" gap="small" margin={{ top: 'medium', bottom: 'small' }}>
            <Anchor href="https://www.adenin.com/support/" target="_blank" rel="noopener" size="small" color="text-weak">
              Help
            </Anchor>
            <Text size="small" color="text-weak">
              &bull;
            </Text>
            <Anchor href="https://www.adenin.com/contact/" target="_blank" rel="noopener" size="small" color="text-weak">
              Contact adenin
            </Anchor>
            <Text size="small" color="text-weak">
              &bull;
            </Text>
            <Anchor href="https://www.adenin.com/legal/privacy-policy/" target="_blank" rel="noopener" size="small" color="text-weak">
              Privacy notice
            </Anchor>
            <Text size="small" color="text-weak">
              &bull;
            </Text>
            <Text size="small" color="text-weak">
              &copy;
              {' '}
              {new Date().getFullYear()}
              . All rights reserved
            </Text>
          </Box>
        </LoginFooter>
      </Box>
    </Grommet>
  );
}

export default Login;
