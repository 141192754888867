import './styles/global.css';
import './styles/react-rtg.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import AppRoot from '@/root';

if (typeof document !== 'undefined' && typeof navigator !== 'undefined' && /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
  document.onreadystatechange = () => {
    if (document.readyState !== 'complete') return;
    document.documentElement.classList.add('platform-apple');
  };
}

if (!sessionStorage.length) {
  console.log('Ask other tabs for session storage');
  localStorage.setItem('getSessionStorage', Date.now().toString());
}

window.addEventListener('storage', (event) => {
  if (event.key === 'getSessionStorage') {
    console.log('Some tab asked for the sessionStorage -> send it');

    localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
    localStorage.removeItem('sessionStorage');
  }

  if (event.key === 'sessionStorage' && !sessionStorage.length) {
    console.log('sessionStorage is empty -> fill it');

    if (!event.newValue) {
      return;
    }

    const data = JSON.parse(event.newValue);

    Object.keys(data).forEach((key) => {
      sessionStorage.setItem(key, data[key]);
    });
  }
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AppRoot />
  </React.StrictMode>,
);
