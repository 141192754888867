import {
  Anchor,
  Box,
  Image,
  Text,
} from 'grommet/components';

import chromeWebStore from '../../images/chrome-web-store-btn.png';
import appSource from '../../images/get-it-from-app-source.png';
import msTeams from '../../images/ms-teams-btn.svg';
import { LandingFooterContainer } from './styles';

function LandingFooter() {
  return (
    <LandingFooterContainer pad={{ top: 'medium', bottom: 'large', horizontal: 'small' }}>
      <Text weight="bold" margin={{ vertical: 'small' }}>
        Stay connected from your favorite workplace apps
      </Text>
      <Box direction="row" gap="medium">
        <Anchor href="https://chrome.google.com/webstore/detail/adenin-digital-assistant/pfjagmnagmkgfplgejbkoeliagbljfnj?hl=en" target="_blank" rel="noopener nofollow">
          <Box border>
            <Image src={chromeWebStore} width="165px" height="50px" />
          </Box>
        </Anchor>
        <Anchor href="https://teams.microsoft.com/l/app/a2be9b85-098e-4e62-b51f-756ae2f551a8?source=app-details-dialog" target="_blank" rel="noopener">
          <Image src={msTeams} width="172px" height="50px" />
        </Anchor>
        <Anchor href="https://appsource.microsoft.com/en-us/product/office/WA200005180?tab=Overview" target="_blank" rel="noopener">
          <Image src={appSource} height="50px" width="113px" />
        </Anchor>
      </Box>
      <Box direction="row" gap="small" margin={{ top: 'medium', bottom: 'small' }}>
        <Anchor href="https://www.adenin.com/support/" target="_blank" rel="noopener" size="small" color="text-weak">
          Help
        </Anchor>
        <Text size="small" color="text-weak">
          &bull;
        </Text>
        <Anchor href="https://www.adenin.com/contact/" target="_blank" rel="noopener" size="small" color="text-weak">
          Contact adenin
        </Anchor>
        <Text size="small" color="text-weak">
          &bull;
        </Text>
        <Anchor href="https://www.adenin.com/legal/privacy-policy/" target="_blank" rel="noopener" size="small" color="text-weak">
          Privacy notice
        </Anchor>
        <Text size="small" color="text-weak">
          &bull;
        </Text>
        <Text size="small" color="text-weak">
          &copy;
          {' '}
          {new Date().getFullYear()}
          . All rights reserved
        </Text>
      </Box>
    </LandingFooterContainer>
  );
}

export default LandingFooter;
