import { useEffect, useState } from 'react';

import { analyzeJson } from '../utils/generator/analyzePayload';

export function extractDateProperties(elements: any[]) {
  const options = [];
  let defaultField = '';
  let defPriority = 4;

  for (let i = 0; i < elements.length; i++) {
    const e = elements[i];

    if (!e.type.startsWith('date') && !e.type.startsWith('epoch')) {
      continue;
    }

    options.push(e.name);

    if ((e.name.includes('upda') || e.name.includes('chang')) && (defPriority > 1 || !defaultField)) {
      defPriority = 1;
      defaultField = e.name;
    }

    if (e.name.includes('crea') && (defPriority > 2 || !defaultField)) {
      defPriority = 2;
      defaultField = e.name;
    }

    if (!defaultField) {
      defPriority = 3;
      defaultField = e.name;
    }
  }

  return {
    defaultField,
    options,
  };
}

export const useNotificationAnalysis = (previewData: any, notificationMode: string = 'listDate') => {
  const [analysisCompleted, setAnalysisCompleted] = useState(false);
  const [canAnalyseField, setCanAnalyseField] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [rootObject, setRootObject] = useState<string>();
  const [defaultField, setDefaultField] = useState<string>();

  useEffect(() => {
    const analyzed = analyzeJson(previewData);
    let def = '';
    let opts: string[] = [];

    if (notificationMode === 'listDate') {
      if (!analyzed.isList) {
        setAnalysisCompleted(true);
        return;
      }

      ({ defaultField: def, options: opts } = extractDateProperties(analyzed.elements));
    }

    setAnalysisCompleted(true);

    if (!opts.length) {
      return;
    }

    setRootObject(analyzed.rootObject);
    setOptions(opts);
    setDefaultField(def);
    setCanAnalyseField(true);
  }, [previewData, notificationMode]);

  return {
    analysisCompleted,
    canAnalyseField,
    defaultField,
    options,
    rootObject,
  };
};
