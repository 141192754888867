import get from 'lodash/get';

function getDeepKeys(obj) {
  let keys = [];
  if (!obj) return keys;
  Object.keys(obj).forEach((key) => {
    // if (obj[key] === '') return;
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      const subkeys = getDeepKeys(obj[key]);
      keys = keys.concat(subkeys.map((subkey) => `${key}.${subkey}`));
    } else if (Array.isArray(obj[key])) {
      for (let i = 0; i < obj[key].length; i++) {
        if (typeof obj[key][i] === 'object' && !Array.isArray(obj[key][i])) {
          const subkeys = getDeepKeys(obj[key][i]);
          keys = keys.concat(subkeys.map((subkey) => `${key}[${i}].${subkey}`));
        }
      }
    } else {
      keys.push(key);
    }
  });
  return keys;
}

function checkTypeNumber(num) {
  let convertedNumber = 'number';
  if (num % 1 === 0) {
    convertedNumber = 'integer';
    if (num < 2147483647 && num > -2147483647) {
      if (num >= 1000000 && num >= -1000000) {
        convertedNumber = 'int32_large';
      } else {
        convertedNumber = 'int32';
      }
    } else if (Number.isSafeInteger(num)) {
      convertedNumber = 'int64';
    }
  }
  return convertedNumber;
}

function checkTypeString(str) {
  const regxDate = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  // const regxDateTime = /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]).([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9](\.[0-9]{1,2})?(Z|(\+|-)([0-1][0-9]|2[0-3]):[0-5][0-9])$/;
  // https://regexr.com/3iok2
  const regxDateTime = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?Z?/;
  const regxLinks = /^(http|https):\/\//;
  const regxImage = /(\.jpg|\.jpeg|\.png|\.gif|\.webp|\.svg)$/i;
  const regxGUID = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  // https://emailregex.com/
  const regxEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // Count spaces in the string
  const spaceCount = (str.split(' ').length - 1);

  let convertedString = 'string';

  // Match Email
  if (regxEmail.test(str)) {
    convertedString = 'email';
  }
  // Match GUID
  if (regxGUID.test(str)) {
    convertedString = 'guid';
  }
  // Match DateTime
  if (regxDateTime.test(str)) {
    convertedString = 'date-time';
  // Match Date
  } else if (regxDate.test(str)) {
    convertedString = 'date';
  // Match URL
  } else if (regxLinks.test(str)) {
    // Image URL
    if (regxImage.test(str)) {
      convertedString = 'image';
    // URL
    } else {
      convertedString = 'link';
    }
  // Check if this string is a longword and not an email address
  } else if (str.length >= 30 && spaceCount === 0 && !regxEmail.test(str)) {
    convertedString = 'longword';
  // Check if this string is a longtext
  } else if (str.length > 50 && spaceCount > 0) {
    convertedString = 'longtext';
  // Check if this string is a shorttext
  } else if (str.length > 0 && str.length <= 5) {
    convertedString = 'shorttext';
  }

  // Return string type
  return convertedString;
}

function checkType(key, obj) {
  const converted = { name: `${key}`, type: 'string' };

  // Check if key has date or time and the value is a valid number
  if ((key.match(/(date|time)/) || key.toLowerCase().endsWith('at')) && !isNaN(obj) && !isNaN(parseFloat(obj)) && Number(obj) > 1000000000) {
    if (obj > 1000000000000) {
      converted.type = 'epoch_long';
    } else {
      converted.type = 'epoch';
    }
  } else if (typeof obj === 'number') {
    const type = checkTypeNumber(obj);
    converted.type = `${type}`;
  } else if (typeof obj === 'string') {
    const type = checkTypeString(obj);
    converted.type = `${type}`;
    // Check if string is title type
    const titleTypeConditions = ['title', 'subject', 'caption', 'description', 'name'];
    const pathKeys = key.toLowerCase().split('.');
    const pathKey = pathKeys[pathKeys.length - 1];
    const titleType = titleTypeConditions.some((el) => pathKey.includes(el));
    if (titleType) {
      converted.type = 'title';
    }
  } else if (typeof obj === 'boolean') {
    converted.type = 'boolean';
  }
  return converted;
}

function getElementsList(payload, rootObject) {
  const paths = [];
  let newPayload = payload;
  let keys = [];

  // rootObject is an array
  if (rootObject !== '' && Array.isArray(payload[rootObject])) {
    // Use the rootObject location
    newPayload = payload[rootObject];
    // Loop over and push keys to path variable for recursion
    Object.keys(newPayload).forEach((key) => {
      if (!Array.isArray(newPayload[key])) {
        paths.push(newPayload[key]);
      }
    });
    // Save all useful deep/nested keys
    keys = getDeepKeys(paths[0]);
  // rootObject is an object not an array
  } else if (rootObject !== '' && !Array.isArray(payload[rootObject]) && typeof payload[rootObject] === 'object') {
    // Update newPayload to use the rootObject path
    newPayload = payload[rootObject];
    // Save all useful deep/nested keys
    keys = getDeepKeys(newPayload);
  } else {
    // Loop over and push root variables to paths
    Object.keys(newPayload).forEach((key) => {
      // Filter unwanted values
      if (key[0] !== '@' && key[0] !== '$' && key[0] !== '_') {
        if (!Array.isArray(newPayload[key]) && typeof newPayload[key] !== 'object') {
          // Save all useful root keys
          keys.push(key);
        }
      }
    });
  }

  const typeCheckedElements = [];
  // posType counts
  const Counters = {};
  const checkValues = {};
  // pos element count
  let elementCount = 0;

  // Loop over keys for type detection
  Object.keys(keys).forEach((key) => {
    let path;

    // Define path
    if (rootObject !== '' && Array.isArray(payload[rootObject])) {
      path = `${rootObject}[0].${keys[key]}`;
    } else if (rootObject !== '' && !Array.isArray(payload[rootObject]) && typeof payload[rootObject] === 'object') {
      path = `${rootObject}.${keys[key]}`;
    } else {
      path = `${keys[key]}`;
    }

    // Use lodash to get value from the given path for type detection
    const value = get(payload, path);
    // Detected type
    const typeCheckedElement = checkType(keys[key], value);
    // Check for duplicate values
    const valueString = String(value);
    if (checkValues[valueString] === true) {
      typeCheckedElement.duplicateValue = true;
    } else {
      checkValues[valueString] = true;
    }
    // Add value
    typeCheckedElement.value = value;
    // Position
    typeCheckedElement.pos = elementCount;
    elementCount++;
    // Type value
    const counterName = typeCheckedElement.type;
    // Start counter
    Counters[counterName] = Counters[counterName] || 0;
    // Assign posType to element
    typeCheckedElement.posType = Counters[counterName];
    // Increment counter
    Counters[counterName]++;

    // Push result
    typeCheckedElements.push(typeCheckedElement);
  });
  return typeCheckedElements;
}

function findArrayName(payload) {
  const arrayNames = [];
  const objectNames = [];

  // Loop over payload to find arrays containing objects
  Object.keys(payload).forEach((key) => {
    // Filter unwanted values
    if (key[0] !== '@' && key[0] !== '$' && key[0] !== '_') {
      if (Array.isArray(payload[key]) && payload[key].length > 0 && typeof payload[key][0] === 'object') {
        arrayNames.push(key);
      }

      if (!Array.isArray(payload[key]) && typeof payload[key] === 'object' && Object.keys(payload[key] || {}).length > 0) {
        objectNames.push(key);
      }
    }
  });

  if (arrayNames.length > 0) {
    // Return array rootObject
    return arrayNames[0];
  }

  if (objectNames.length > 0) {
    // Return object rootObject
    return objectNames[0];
  }
  // Return no root object if no arrays or objects with data are found
  return '';
}

function nameToLabel(name) {
  // Split field path by period
  const string = name.split('.');
  // Use last part of field path for label name
  const splitString = string[string.length - 1];
  // Process string for label
  const strings = splitString
    // Remove array notation
    .replace(/(\[\d+])/g, '')
    // Replace dot notation with underscore
    .replace(/\./g, '_')
    // Remove start bracket notation
    .replace(/\['/g, '')
    // Remove end bracket notation
    .replace(/']/g, '')
    // Split at capitals
    .split(/(?=[A-Z])/g)
    .join('_')
    // Split at digits
    .split(/(\d+)/g)
    .join('_')
    // Split at underscores
    .split('_');

  const result = [];
  for (let word of strings) {
    if (word.match(/url$/i) || word.match(/link$/i)) {
      word = word
        .replace('url', '')
        .replace('link', '');
    }

    if (word !== '' && !word.match(/url$/i) && !word.match(/link$/i)) {
      result.push(word.charAt(0).toUpperCase() + word.slice(1));
    }
  }
  const label = result.join(' ').replaceAll('-', '');

  // Return original name if new label is empty
  if (label.length === 0) {
    return name;
  }

  return label;
}

export function analyzeJson(rawData) {
  // Copy data for mutation
  let payload = rawData;
  // Set success and errorMessage default values
  let success = false;
  let errorMessage;

  // Try to parse rawData if string
  if (typeof payload === 'string') {
    // Empty payload, show no data error card
    if (payload === '') {
      success = false;
      errorMessage = 'no data';
    }
    // Attempt to parse payload
    try {
      payload = JSON.parse(payload);
    } catch (e) {
      // Error message was passed as payload, use payload message for error card
      if (payload.match('Error:')) {
        console.error(payload);
        success = false;
        errorMessage = payload;
      }
      // Error was only caught here, use error message for error card
      console.error(e);
      success = false;
      errorMessage = `${e}`;
    }
  }

  // Error message for a number because JSON.parse does not catch this
  if (typeof payload === 'number') {
    errorMessage = `SyntaxError: Unexpected token '${payload}', "${payload}" is not valid JSON`;
  }

  // Wrap in object if needed
  if (Array.isArray(payload)) {
    payload = { response: payload };
  }

  // Process if object
  if (typeof payload === 'object') {
    // Find rootObject names
    // console.log('Payload sent to findArrayName:', payload);
    let rootObject = findArrayName(payload);
    // console.log('rootObject found findArrayName:', rootObject);

    // Create elements to be added to items
    const listElements = getElementsList(payload, rootObject);
    // console.log('List elements from getElementsList', listElements);
    const rootElements = getElementsList(payload, '');
    // console.log('Root elements from getElementsList', rootElements);
    const elements = rootElements.length > listElements.length ? rootElements : listElements;

    // Add a label to each element
    elements.forEach((element) => {
      element.label = nameToLabel(element.name);
    });

    // console.log('Elements selected:', elements);
    rootObject = rootElements.length > listElements.length ? '' : rootObject;
    // console.log('rootObject selected:', rootObject);

    let isList = false;

    // Set isList based on the rootObject variable
    if (rootObject !== '' && Array.isArray(payload[rootObject])) {
      isList = true;
    // No rootObject or single response object used as rootObject
    } else {
      isList = false;
    }

    // Return data from analyze
    return {
      isList,
      rootObject,
      success: true,
      errorMessage: '',
      elements: [...elements],
    };
  }

  // Payload for errors/strings
  return {
    success,
    errorMessage,
    elements: [],
  };
}
