import {
  Anchor,
  Box,
  Button,
  Carousel,
  Collapsible,
  Image,
  Layer,
  Text,
  TextInput,
} from 'grommet';
import {
  AddCircle,
  BlockQuote,
  Close,
  Inspect,
} from 'grommet-icons';
import { useEffect, useState } from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
import useBus from 'use-bus';

import { hostUrl } from '../../config';
import { usePlatformMutation, useSessionStore } from '../../hooks';
import alexaLogo from '../../images/alexa.svg';
import chromeLogo from '../../images/chrome-extension.svg';
import ciscoLogo from '../../images/cisco-webex.svg';
import teamsLogo from '../../images/ms-teams-logo.svg';
import sharepointLogo from '../../images/sharepoint-logo.svg';
import sharepointSearchlogo from '../../images/sharepoint-search.svg';
import slackLogo from '../../images/slack-app.svg';
import vivaLogo from '../../images/viva-logo.png';
import wordpressLogo from '../../images/wordpress.svg';
import workplaceLogo from '../../images/workplace-by-facebook-bot.svg';
import { isMobilePlatform } from '../../utils';
import GrommetGatsbyLink from '../GrommetGatsbyLink';

const TeamsButton = styled(Button)`
  & [target='ms-teams-share-popup'] {
    display: none;
  }
`;

function ShareModal() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [payload, setPayload] = useState(null);
  const [copied, setCopied] = useState(false);
  const [collapsibleOpen, setCollapsibleOpen] = useState(false);

  const session = useSessionStore((state) => state.session);

  const { mutateAsync: fetchPayload } = usePlatformMutation(`/card/adaptive/${data?.Id}`);

  useBus(
    'modal.share.toggle',
    (event) => {
      setData(event.metadata);
      setOpen(!open);
    },
  );

  useEffect(() => {
    if (data?.Id) {
      fetchPayload().then((res) => {
        if (res.ErrorCode === 0) {
          setPayload(res.Data);
        }
      });
    }
  }, [data?.Id, fetchPayload]);

  if (!open || !data) return null;

  let utterance;

  if (payload?.properties?.utterances?.items?.length) {
    utterance = payload?.properties?.utterances?.items[0]?.value;
  } else {
    utterance = data.Title;
  }

  const link = `${hostUrl || window.location.origin}/app/assistant/card/${data.Id}`;
  const isAdmin = session?.Roles?.includes('Administrator');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    });
  };

  return (
    <Layer modal onClickOutside={() => setOpen(false)} style={{ overflow: 'auto', maxHeight: '97vh' }}>
      <Box pad="medium" direction="row" align="center" justify="between" border={{ side: 'bottom' }}>
        <Text size="large" className="shareModalTitle">
          Share
          {' '}
          {data.Title}
        </Text>
        <Button icon={<Close size="18px" />} onClick={() => setOpen(false)} />
      </Box>
      <Box width="large" gap="medium" pad="medium" flex="grow">
        {data.AssignedRole && (
          <Box direction="row" align="center" gap="small" background="background-back" pad="small" wrap height="max-content">
            <Inspect />
            {isMobilePlatform ? (<Text size="xsmall">Can be seen by anyone within this audience</Text>) : (<Text size="small">Can be seen by anyone within this audience</Text>)}
            <Box flex="grow">
              <TextInput disabled value={data.AssignedRole} />
            </Box>
            {isAdmin && <Anchor as={Link} to={`/app/designer/card/${data.Id}?editRole=true`} label="Edit" onClick={() => setOpen(false)} />}
          </Box>
        )}
        {isMobilePlatform ? (<Text size="xsmall">Copy the link below and share it with a colleague</Text>) : (<Text>Copy the link below and share it with a colleague</Text>)}
        <Box direction="row" gap="small" align="center">
          <Box fill="horizontal">
            <TextInput value={link} />
          </Box>
          <Box flex="grow">
            <Button label={copied ? 'Copied!' : 'Copy'} primary onClick={copyToClipboard} disabled={copied} />
          </Box>
        </Box>
        {isMobilePlatform ? (<Text size="xsmall">Embed this Card into your internal apps</Text>) : (<Text>Embed this Card into your internal apps</Text>)}
        <Box direction="row" gap="small" align="center">
          {/* Carousel for share buttons */}
          {isMobilePlatform ? (
            <Carousel fill controls="arrows">
              {/* Page 1 */}
              <Box gap="small" direction="row" justify="center" align="center">
                <Box width="49%">
                  <Button gap="small" onClick={() => setCollapsibleOpen(!collapsibleOpen)}>
                    <Box height="xsmall" background={collapsibleOpen ? 'brand' : 'background-contrast'} align="center" justify="center">
                      <Image src={teamsLogo} style={{ height: '40px' }} margin={{ bottom: 'small' }} />
                      <Text size="small">Microsoft Teams</Text>
                    </Box>
                  </Button>
                </Box>
                <Box width="49%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Sharepoint?type=sharepoint&cardId=${data.Id}`}>
                    <Box height="xsmall" background="background-contrast" align="center" justify="center">
                      <Image src={sharepointLogo} style={{ height: '40px' }} margin={{ bottom: 'small' }} />
                      <Text size="small">SharePoint</Text>
                    </Box>
                  </Button>
                </Box>
              </Box>
              {/* Page 2 */}
              <Box gap="small" direction="row" justify="center" align="center">
                <Box width="49%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Viva?type=viva&cardId=${data.Id}`}>
                    <Box height="xsmall" background="background-contrast" align="center" justify="center">
                      <Image src={vivaLogo} style={{ height: '40px' }} margin={{ bottom: 'small' }} />
                      <Text size="small">Viva Connections</Text>
                    </Box>
                  </Button>
                </Box>
                <Box width="49%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Wordpress?cardId=${data.Id}`}>
                    <Box height="6em" background="background-contrast" align="center" justify="center">
                      <Image src={wordpressLogo} style={{ height: '40px' }} margin={{ bottom: 'small' }} />
                      <Text size="small">Wordpress</Text>
                    </Box>
                  </Button>
                </Box>
              </Box>
              {/* Page 3 */}
              <Box gap="small" direction="row" justify="center" align="center">
                <Box width="49%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Cisco-webex?cardId=${data.Id}`}>
                    <Box height="6em" background="background-contrast" align="center" justify="center">
                      <Image src={ciscoLogo} style={{ height: '40px' }} margin={{ bottom: 'small' }} />
                      <Text size="small">Cisco Webex</Text>
                    </Box>
                  </Button>
                </Box>
                <Box width="49%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Sharepoint-search?cardId=${data.Id}`}>
                    <Box height="6em" background="background-contrast" align="center" justify="center">
                      <Image src={sharepointSearchlogo} style={{ height: '40px' }} margin={{ bottom: 'small' }} />
                      <Text size="small">SharePoint Search</Text>
                    </Box>
                  </Button>
                </Box>
              </Box>
              {/* Page 4 */}
              <Box gap="small" direction="row" justify="center" align="center">
                <Box width="49%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Slack?cardId=${data.Id}`}>
                    <Box height="6em" background="background-contrast" align="center" justify="center">
                      <Image src={slackLogo} style={{ height: '40px' }} margin={{ bottom: 'small' }} />
                      <Text size="small">Slack</Text>
                    </Box>
                  </Button>
                </Box>
                <Box width="49%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Alexa?cardId=${data.Id}`}>
                    <Box height="xsmall" background="background-contrast" align="center" justify="center">
                      <Image src={alexaLogo} style={{ height: '40px' }} margin={{ bottom: 'small' }} />
                      <Text size="small">Amazon Alexa</Text>
                    </Box>
                  </Button>
                </Box>
              </Box>
              {/* Page 5 */}
              <Box gap="small" direction="row" justify="start" align="center">
                <Box width="49%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Chrome-extension?cardId=${data.Id}`}>
                    <Box height="xsmall" background="background-contrast" align="center" justify="center">
                      <Image src={chromeLogo} style={{ height: '40px' }} margin={{ bottom: 'small' }} />
                      <Text size="small">Chrome extension</Text>
                    </Box>
                  </Button>
                </Box>
                <Box width="49%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Workplace?cardId=${data.Id}`}>
                    <Box height="xsmall" background="background-contrast" align="center" justify="center">
                      <Image src={workplaceLogo} style={{ height: '40px' }} margin={{ bottom: 'small' }} />
                      <Text size="small">Workplace from Meta</Text>
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Carousel>
          ) : (
            <Carousel fill controls="arrows">
              <Box gap="small" direction="row" justify="center" align="center">
                {/* Page 1 */}
                <Box width="25%">
                  <Button gap="small" onClick={() => setCollapsibleOpen(!collapsibleOpen)}>
                    <Box pad="xsmall" height="10em" background={collapsibleOpen ? 'brand' : 'background-contrast'} align="center" justify="center">
                      <Image src={teamsLogo} style={{ height: '70px' }} margin={{ bottom: 'small' }} />
                      <Text>Microsoft Teams</Text>
                    </Box>
                  </Button>
                </Box>
                <Box width="25%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Sharepoint?type=sharepoint&cardId=${data.Id}`}>
                    <Box pad="xsmall" height="10em" background="background-contrast" align="center" justify="center">
                      <Image src={sharepointLogo} style={{ height: '70px' }} margin={{ bottom: 'small' }} />
                      <Text>SharePoint</Text>
                    </Box>
                  </Button>
                </Box>
                <Box width="25%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Viva?type=viva&cardId=${data.Id}`}>
                    <Box pad="xsmall" height="10em" background="background-contrast" align="center" justify="center">
                      <Image src={vivaLogo} style={{ height: '70px' }} margin={{ bottom: 'small' }} />
                      <Text>Viva Connections</Text>
                    </Box>
                  </Button>
                </Box>
                <Box width="25%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Wordpress?cardId=${data.Id}`}>
                    <Box pad="xsmall" height="10em" background="background-contrast" align="center" justify="center">
                      <Image src={wordpressLogo} style={{ height: '70px' }} margin={{ bottom: 'small' }} />
                      <Text>Wordpress</Text>
                    </Box>
                  </Button>
                </Box>
              </Box>
              {/* Page 2 */}
              <Box gap="small" direction="row" justify="center" align="center">
                <Box width="25%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Cisco-webex?cardId=${data.Id}`}>
                    <Box pad="xsmall" height="10em" background="background-contrast" align="center" justify="center">
                      <Image src={ciscoLogo} style={{ height: '70px' }} margin={{ bottom: 'small' }} />
                      <Text>Cisco Webex</Text>
                    </Box>
                  </Button>
                </Box>
                <Box width="25%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Sharepoint-search?cardId=${data.Id}`}>
                    <Box pad="xsmall" height="10em" background="background-contrast" align="center" justify="center">
                      <Image src={sharepointSearchlogo} style={{ height: '70px' }} margin={{ bottom: 'small' }} />
                      <Text>SharePoint Search</Text>
                    </Box>
                  </Button>
                </Box>
                <Box width="25%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Slack?cardId=${data.Id}`}>
                    <Box pad="xsmall" height="10em" background="background-contrast" align="center" justify="center">
                      <Image src={slackLogo} style={{ height: '70px' }} margin={{ bottom: 'small' }} />
                      <Text>Slack</Text>
                    </Box>
                  </Button>
                </Box>
                <Box width="25%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Alexa?cardId=${data.Id}`}>
                    <Box pad="xsmall" height="10em" background="background-contrast" align="center" justify="center">
                      <Image src={alexaLogo} style={{ height: '70px' }} margin={{ bottom: 'small' }} />
                      <Text>Amazon Alexa</Text>
                    </Box>
                  </Button>
                </Box>
              </Box>
              {/* Page 3 */}
              <Box gap="small" direction="row" justify="start" align="center">
                <Box width="25%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Chrome-extension?cardId=${data.Id}`}>
                    <Box pad="xsmall" height="10em" background="background-contrast" align="center" justify="center">
                      <Image src={chromeLogo} style={{ height: '70px' }} margin={{ bottom: 'small' }} />
                      <Text>Chrome extension</Text>
                    </Box>
                  </Button>
                </Box>
                <Box width="25%">
                  <Button gap="small" href={`${hostUrl}/app/modal/App/Channels/Workplace-by-facebook-bot?cardId=${data.Id}`}>
                    <Box pad="xsmall" height="10em" background="background-contrast" align="center" justify="center">
                      <Image src={workplaceLogo} style={{ height: '70px' }} margin={{ bottom: 'small' }} />
                      <Text textAlign="center">Workplace from Meta</Text>
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Carousel>
          )}
        </Box>
        {/* Collapsible tab for teams button */}
        {isMobilePlatform
          ? (
              <Collapsible open={collapsibleOpen}>
                <Box gap="small" border="top" overflow="scroll">
                  <Box gap="medium" margin={{ top: 'medium' }}>
                    <Text size="xsmall">First, add adenin</Text>
                    <Box width="max-content">
                      <TeamsButton
                        size="xsmall"
                        className="teams-share-button"
                        data-href={link}
                        data-button-type="smallTrans"
                        data-preview="false"
                        fill
                        primary
                        color="status-ok"
                        label="Add to Microsoft Teams"
                        icon={<AddCircle />}
                      />
                    </Box>
                    <Text size="xsmall">This only needs to be done once to cover your entire organization</Text>
                  </Box>
                  <Box gap="medium" margin={{ top: 'medium' }}>
                    <Text size="xsmall">Then ask for this Card, for example:</Text>
                    <Box background="background-contrast">
                      <Text size="xsmall" margin="small">
                        <BlockQuote />
                    &nbsp;I&apos;m the first utterance from the &apos;Add training phrases&apos; window
                      </Text>
                    </Box>
                    <Anchor size="xsmall">Add more training phrases</Anchor>
                  </Box>
                </Box>
              </Collapsible>
            )
          : (
              <Collapsible width="large" open={collapsibleOpen}>
                <Box gap="small" direction="row" border="top" responsive>
                  <Box gap="small" width="49%" margin={{ top: 'medium' }}>
                    <Text>First, add adenin</Text>
                    <Box height="xxsmall" width="max-content">
                      <script src="https://teams.microsoft.com/share/launcher.js" />
                      <TeamsButton
                        className="teams-share-button"
                        data-href={link}
                        data-button-type="smallTrans"
                        data-preview="false"
                        fill
                        primary
                        color="status-ok"
                        label="Add to Microsoft Teams"
                        icon={<AddCircle />}
                      />
                    </Box>
                    <Text>This only needs to be done once to cover your entire organization</Text>
                  </Box>
                  <Box gap="small" width="49%" margin={{ top: 'medium' }}>
                    <Text>Then ask for this Card, for example:</Text>
                    <Box background="background-contrast">
                      <Text margin="small">
                        <BlockQuote />
                        {utterance}
                      </Text>
                    </Box>
                    <GrommetGatsbyLink to={`/app/designer/card/${data.Id}?edit=utterance`} onClick={() => setOpen(false)}>Add more training phrases</GrommetGatsbyLink>
                  </Box>
                </Box>
              </Collapsible>
            )}
      </Box>
    </Layer>
  );
}

export default ShareModal;
