/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import './assistant-card.css';

import { Box, ThemeContext } from 'grommet';
import {
  Anchor,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Skeleton,
} from 'grommet/components';
import { deepMerge } from 'grommet/utils';
import { isEqual } from 'lodash';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import {
  Suspense,
  lazy,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

import {
  useBoardScale,
  usePlatformMutation,
  usePlatformQueryClient,
  useSessionStore,
} from '../../../hooks';
import baseTheme from '../../../theme';
import {
  findFieldValue,
  hasNotificationDot,
  isFirefox,
  isMobilePlatform,
  track,
} from '../../../utils';
import CardTop from '../CardTop';

// import PlaceholderLoading from '../../PlaceholderLoading';

const ApiErrorCard = lazy(() => import('../ApiErrorCard'));

const NotificationSpan = styled.span`
  display: inline-block;
  position: fixed;
  right: -9px;
  top: -9px;
  width: 20px;
  height: 20px;
  background-color: rgb(1, 120, 213);
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTYgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYxLjIgKDg5NjUzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5QYXRoPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IltNYXJdLU5vLWFwcC1yZXN1bHRzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iQXJ0Ym9hcmQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNTkuMDAwMDAwLCAtMzUzLjAwMDAwMCkiIGZpbGw9IiNGQ0ZGRkYiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0yNzMuNDk4MDQ3LDM1MyBDMjczLjg4MTc5NywzNTMgMjc0LjI2NTU5NCwzNTMuMTQ2NDUzIDI3NC41NTg1OTQsMzUzLjQzOTQ1MyBDMjc1LjE0NDU5NCwzNTQuMDI1NDUzIDI3NS4xNDU1NDcsMzU0Ljk3NDU0NyAyNzQuNTYwNTQ3LDM1NS41NjA1NDcgTDI2NS41NjA1NDcsMzY0LjU2MDU0NyBDMjY1LjI2NzU0NywzNjQuODUzNTQ3IDI2NC44ODQsMzY1IDI2NC41LDM2NSBDMjY0LjExNiwzNjUgMjYzLjczMjQ1MywzNjQuODUzNTQ3IDI2My40Mzk0NTMsMzY0LjU2MDU0NyBMMjU5LjQzOTQ1MywzNjAuNTYwNTQ3IEMyNTguODUzNDUzLDM1OS45NzQ1NDcgMjU4Ljg1MzQ1MywzNTkuMDI1NDUzIDI1OS40Mzk0NTMsMzU4LjQzOTQ1MyBDMjYwLjAyNTQ1MywzNTcuODUzNDUzIDI2MC45NzQ1NDcsMzU3Ljg1MzQ1MyAyNjEuNTYwNTQ3LDM1OC40Mzk0NTMgTDI2NC40OTgwNDcsMzYxLjM3ODkwNiBMMjcyLjQzNzUsMzUzLjQzOTQ1MyBDMjcyLjczMDUsMzUzLjE0NjQ1MyAyNzMuMTE0Mjk3LDM1MyAyNzMuNDk4MDQ3LDM1MyBaIiBpZD0iUGF0aCI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  &:active {
    zoom: 90%;
  }
`;

function CustomSkeleton(data) {
  const { cardHeight } = data;
  // Set skeleton shimmer class
  const isDarkMode = useContext(ThemeContext).dark;
  const shimmer = isDarkMode ? 'shimmerDark' : 'shimmerLight';
  // Set skeleton background color from theme one.jsx
  const color = { dark: ['skeleton'], light: ['skeleton'] };

  return (
    <Box className="isLoading">
      {cardHeight < 2
        ? (
            <Box pad="medium">
              <Box direction="row">
                <Skeleton className={shimmer} width="100%" height="33px" colors={color} />
              </Box>
              <Box margin={{ top: 'small' }}>
                <Skeleton className={shimmer} width="100%" height="10px" round="large" colors={color} />
              </Box>
              <Box margin={{ top: 'small' }}>
                <Skeleton className={shimmer} width="90%" height="10px" round="large" colors={color} />
              </Box>
              <Box margin={{ top: 'small' }}>
                <Skeleton className={shimmer} width="40%" height="10px" round="large" colors={color} />
              </Box>
            </Box>
          )
        : (
            <>
              <Box pad="medium">
                <Box direction="row">
                  <Skeleton className={shimmer} width="100%" height="33px" colors={color} />
                </Box>
                <Box margin={{ top: 'small' }}>
                  <Skeleton className={shimmer} width="100%" height="10px" round="large" colors={color} />
                </Box>
                <Box margin={{ top: 'small' }}>
                  <Skeleton className={shimmer} width="90%" height="10px" round="large" colors={color} />
                </Box>
                <Box margin={{ top: 'small' }}>
                  <Skeleton className={shimmer} width="40%" height="10px" round="large" colors={color} />
                </Box>
              </Box>
              <Box pad="medium">
                <Box direction="row">
                  <Skeleton className={shimmer} width="100%" height="33px" colors={color} />
                </Box>
                <Box margin={{ top: 'small' }}>
                  <Skeleton className={shimmer} width="100%" height="10px" round="large" colors={color} />
                </Box>
                <Box margin={{ top: 'small' }}>
                  <Skeleton className={shimmer} width="90%" height="10px" round="large" colors={color} />
                </Box>
                <Box margin={{ top: 'small' }}>
                  <Skeleton className={shimmer} width="40%" height="10px" round="large" colors={color} />
                </Box>
              </Box>
              <Box pad="medium">
                <Box direction="row">
                  <Skeleton className={shimmer} width="100%" height="33px" colors={color} />
                </Box>
                <Box margin={{ top: 'small' }}>
                  <Skeleton className={shimmer} width="100%" height="10px" round="large" colors={color} />
                </Box>
                <Box margin={{ top: 'small' }}>
                  <Skeleton className={shimmer} width="90%" height="10px" round="large" colors={color} />
                </Box>
                <Box margin={{ top: 'small' }}>
                  <Skeleton className={shimmer} width="40%" height="10px" round="large" colors={color} />
                </Box>
              </Box>
            </>
          )}
    </Box>
  );
}

function RenderedCard({
  metadata, data, content: CardContent, draggable = null, drag = null, notification = false, iframe = false, shadow = false, sidebar = false, closeToNotifications = false, dismissable = false, hideFooter = false,
}) {
  const [theme, setTheme] = useState(baseTheme);
  const session = useSessionStore((state) => state.session);
  const scale = useBoardScale((s) => s.scale);

  // Check card height
  const cardHeight = metadata?.currentLayout?.h;

  let footer = null;

  if (notification && !hideFooter) {
    footer = <CardFooter pad="small" />;
  } else if (!hideFooter) {
    let innerFooter = null;

    if (metadata.footer?.hasMultiple) {
      innerFooter = metadata.footerActions.map((action) => {
        if (!action || !action.url || !action.title) return null;
        return <Anchor key={action.url} href={action.url} label={action.title} target="_blank" size="medium" />;
      });
    } else if (metadata.footer?.label && metadata.footer?.url) {
      innerFooter = <Anchor href={metadata.footer.url} label={metadata.footer.label} target="_blank" size="medium" />;
    }

    if (innerFooter) {
      footer = (
        <CardFooter direction="row" align="start" gap="medium" justify="start" pad="small" border={{ side: 'top', color: 'border' }}>
          {innerFooter}
        </CardFooter>
      );
    }
  }

  const hasNotification = hasNotificationDot(data, metadata);

  const { mutateAsync: readNotification } = usePlatformMutation('/briefing/updatecardsstatus', {
    method: 'POST',
  });

  const { invalidateQueries } = usePlatformQueryClient();

  const cardRef = useRef();

  const onReadNotification = useCallback(async (e) => {
    if (!hasNotification) {
      return;
    }

    const dot = Array.isArray(e.target.className) && e.target.className.includes('blue-dot');
    let readVersion = data?.Data?._hash;

    if (metadata.configuration?.notificationMode && metadata.configuration.notificationMode !== 'hash') {
      const fieldValue = findFieldValue(data.Data, metadata.configuration.rootObject, metadata.configuration.fieldName);
      if (fieldValue) {
        readVersion = `${metadata.configuration.notificationMode}-${fieldValue}`;
      }
    }

    await readNotification([{
      Id: metadata.Id,
      ReadVersion: readVersion,
      ReadDate: new Date().toISOString(),
    }]);

    await invalidateQueries('/userworkplace/usercards');
    track('Notification read confirmation', dot ? 'blue dot' : undefined);
  }, [
    data?.Data,
    hasNotification,
    invalidateQueries,
    metadata.Id,
    metadata.configuration?.notificationMode,
    metadata.configuration?.fieldName,
    metadata.configuration?.rootObject,
    readNotification,
  ]);

  useEffect(() => {
    if (cardRef?.current && data?.Data?._hash && !notification) {
      const card = cardRef.current;
      card.addEventListener('click', onReadNotification, true);
      return () => card.removeEventListener('click', onReadNotification, true);
    }
  }, [data?.Data?._hash, hasNotification, metadata.Id, notification, onReadNotification]);

  const style = {};

  // For use with card opacity blur on hover
  style.transition = 'backdrop-filter .2s';

  if (!shadow) {
    style.boxShadow = 'none';
  }

  if (!isMobilePlatform) {
    if (isFirefox) {
      style.transform = `scale(${scale})`;
      style.transformOrigin = '0 0';
      style.width = `${(1 / scale) * 100}%`;
    } else {
      style.zoom = scale;
    }
  }

  if (metadata?.__generatorPreview) {
    style.width = '300px';
    style.zoom = 1;
    style.overflow = 'unset';
    style.backgroundColor = 'unset';
  }

  if (metadata?.__notificationPreview) {
    style.minHeight = '100px';
    style.height = 'unset !important';
  }

  useEffect(() => {
    if (typeof session.customTheme === 'object') {
      setTheme(deepMerge(baseTheme, session?.customTheme));
    }
  }, [session?.customTheme]);

  const cardRounding = theme.card.container.round;

  return (
    <Card
      round={cardRounding}
      fill={(metadata.__generatorPreview || metadata.__notificationPreview) ? undefined : 'vertical'}
      className={`card-${metadata.Id} ${metadata.__generatorPreview ? 'fadeInUp' : ''}`}
      ref={cardRef}
      style={style}
    >
      {!notification && (
        <>
          {hasNotification && !iframe && !sidebar && <NotificationSpan className="blue-dot" />}
          <CardHeader
            pad={{
              top: '6px', right: '4px', bottom: '2px', left: '12px',
            }}
            ref={drag}
            {...draggable}
          >
            <CardTop
              id={metadata.Id}
              title={data?.Data?._title || metadata.Title}
              settings={metadata.settings}
              imageUrl={metadata.imageUrl}
              metadata={metadata}
              notification={notification}
              sidebar={sidebar}
              closeToNotifications={closeToNotifications}
              dismissable={dismissable}
              hideMenuButton={hideFooter}
              iframe={iframe}
            />
          </CardHeader>
        </>
      )}
      <CardBody
        className="card card-drag-cancel"
        pad={sidebar ? { bottom: 'small' } : 'none'}
        overflow={metadata?.__generatorPreview ? undefined : { vertical: 'auto' }}
        justify={metadata.__notificationPreview ? 'center' : undefined}
      >
        {data
          ? (
              <Suspense fallback={(<CustomSkeleton cardHeight={cardHeight} />)}>
                <ErrorBoundary FallbackComponent={ApiErrorCard}>
                  <CardContent metadata={metadata} data={notification ? data : data.Data} iframe={iframe} sidebar={sidebar} />
                </ErrorBoundary>
              </Suspense>
            )
          : (
              <CustomSkeleton cardHeight={cardHeight} />
            )}
      </CardBody>
      {footer}
    </Card>
  );
}

export default memo(
  RenderedCard,
  (prevProps, nextProps) => isEqual(prevProps.metadata, nextProps.metadata) && isEqual(prevProps.data, nextProps.data) && prevProps.name === nextProps.name,
);
