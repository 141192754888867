// @refresh reset

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import './board-matrix.css';

import {
  Box,
  Button,
  Header,
  Heading,
  Image,
  Layer,
  ResponsiveContext,
  Text,
  TextInput,
} from 'grommet';
import {
  Add,
  AddCircle,
  Close,
  Search,
} from 'grommet-icons';
import { isEqual } from 'lodash';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import {
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import CardCreator from '../../../components/CardCreator';
import EmptyState from '../../../components/EmptyState';
import AssistantCard from '../../../components/board-cards/AssistantCard';
import config from '../../../config';
import {
  useBoardScale,
  useColorScheme,
  useCurrentColumns,
  useIsBannerShowing,
  usePlatformMutation,
  usePlatformQuery,
  usePlatformQueryClient,
  useSessionStore,
} from '../../../hooks';
import robboAddCards from '../../../images/robbo_add_cards.png';
import { isSSR, track } from '../../../utils';
import {
  MULTI,
  SINGLE,
  getLayouts,
  useGridLayout,
} from './utils';

const ResponsiveGridLayout = WidthProvider(Responsive);

const AppPickerButton = styled(Button)`
  background-color: ${(props) => props.theme.global.colors['brand-primary'][useColorScheme() ? 'light' : 'dark']};
  border-radius: 50%;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const PinTip = styled.div`
  position: absolute;
  width: 320px;
  top: ${(props) => props.top};
  right: 48px;
  z-index: 2;
  background-color: ${(props) => props.theme.global.colors['brand-primary'][useColorScheme() ? 'light' : 'dark']};
  margin-top: 16px;
  box-shadow: 0px 6px 12px #0000005c;
  &::after {
    content: '';
    position: absolute;
    margin-left: 292px;
    border-width: 7px;
    border-style: solid;
    transform: rotate(45deg);
    color: ${(props) => props.theme.global.colors['brand-primary'][useColorScheme() ? 'light' : 'dark']};
    margin-top: ${(props) => (props.size === 'small' ? '-130px' : '-162px')};
  }
`;

const CardContainer = styled.div`
  align-items: center;
`;

if (!isSSR) {
  window.__cardsRefreshed = false;
}

function Board() {
  const location = useLocation();
  const navigate = useNavigate();

  const [cards, setCards] = useState(null);
  const [appPickerOpen, setAppPickerOpen] = useState(false);

  const size = useContext(ResponsiveContext);

  const session = useSessionStore((state) => state.session);
  const { invalidateQueries } = usePlatformQueryClient();
  const { data, isFetching, isFetched } = usePlatformQuery('/userworkplace/usercards');
  const mutation = usePlatformMutation('/userworkplace/updatepositions', {
    method: 'POST',
  });

  const setCurrentColumns = useCurrentColumns((state) => state.setCurrentColumns);
  const percScale = useBoardScale((s) => s.scale);
  const setPercScale = useBoardScale((s) => s.setScale);

  const onWidthChange = (w) => {
    if (w >= 877) {
      let perc = w / 1730;

      if (perc > 1) {
        perc = 1;
      }

      setPercScale(perc);
    } else {
      setPercScale(1);
    }
  };

  const {
    cardMargin, cardHeight, colCount, columns, widths, adaptiveBoard,
  } = useGridLayout(percScale, !location.pathname.includes('grid-'));

  useEffect(() => {
    if (!isSSR && (location.search.includes('newcard') || location.search.includes('clearcache'))) {
      invalidateQueries('/userworkplace/usercards');
    }

    setCurrentColumns(adaptiveBoard ? colCount : (colCount / 2));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adaptiveBoard/* , colCount, invalidateQueries, location.search, setCurrentColumns */]);

  useEffect(() => {
    track(location.pathname.includes('embedboard') ? 'Board embedded started' : 'Board started');
  }, [location.pathname]);

  useEffect(() => {
    if (!data || data.ErrorCode !== 0) return;

    const newCards = [];

    for (let i = 0; i < data.Data.length; i++) {
      const item = data.Data[i];
      item.id = item.id || item.Id;

      if (item.UserActions.pinned && item.BoardStatus === '1') {
        newCards.push(item);

        if (!window.__cardsRefreshed) {
          invalidateQueries(`/gateway/card/${item.id}`);
        }
      }
    }

    window.__cardsRefreshed = true;
    newCards.sort((a, b) => (a.Sort < b.Sort ? 1 : -1));

    setCards(newCards);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (appPickerOpen) {
      track('App_Picker_Open');
    }
  }, [appPickerOpen]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.has('picker')) {
      setAppPickerOpen(true);
      track('add app opened board link');
    }
  }, [location.search]);

  const mutateLayout = async (layout, newCols) => {
    mutation.mutate(
      {
        columnKey: `c${newCols}`,
        items: layout,
      },
      {
        onSuccess: async () => {
          console.log('matrix success');
          await invalidateQueries('/userworkplace/usercards');
        },
      },
    );
  };

  const onLayoutChange = async (activeLayout, allLayouts) => {
    if (adaptiveBoard) {
      if (isEqual(activeLayout, allLayouts[MULTI])) {
        await mutateLayout(allLayouts[MULTI], columns[MULTI]);
        setCurrentColumns(columns[MULTI]);
      } else if (isEqual(activeLayout, allLayouts[SINGLE])) {
        await mutateLayout(allLayouts[SINGLE], columns[SINGLE]);
        setCurrentColumns(columns[SINGLE]);
      }

      return;
    }

    if (isEqual(activeLayout, allLayouts.lg)) {
      await mutateLayout(allLayouts.lg, columns.lg);
      setCurrentColumns(columns.lg);
    } else if (isEqual(activeLayout, allLayouts.md)) {
      await mutateLayout(allLayouts.md, columns.md);
      setCurrentColumns(columns.md);
    } else if (isEqual(activeLayout, allLayouts.sm)) {
      await mutateLayout(allLayouts.sm, columns.sm);
      setCurrentColumns(columns.sm);
    } else if (isEqual(activeLayout, allLayouts.xs)) {
      await mutateLayout(allLayouts.xs, columns.xs);
      setCurrentColumns(columns.xs);
    }
  };

  const freeMovement = useFeatureFlagEnabled('free-board-movement');

  const roles = session?.Roles || [];
  const isAdmin = roles.includes('Administrator');

  let content;

  if (cards?.length > 0) {
    content = (
      <>
        {/* {isTourOpen && <BoardTour open={isTourOpen} setOpen={setIsTourOpen} setStep={tour.set} />} */}
        <div className={`board-container board-container-flex ${!adaptiveBoard ? 'board-responsive' : ''}`} style={adaptiveBoard ? { maxWidth: '1730px' } : undefined}>
          <ResponsiveGridLayout
            onLayoutChange={onLayoutChange}
            rowHeight={cardHeight}
            margin={[cardMargin, cardMargin]}
            breakpoints={widths}
            cols={columns}
            layouts={getLayouts(columns, cards, adaptiveBoard)}
            draggableCancel=".card-drag-cancel"
            draggableHandle=".card-drag-handle"
            compactType={freeMovement ? null : undefined}
            onWidthChange={onWidthChange}
          >
            {cards.map((item, index) => (
              <CardContainer key={item.Id} className={index === 0 ? 'first-card' : ''}>
                <AssistantCard metadata={item} />
              </CardContainer>
            ))}
          </ResponsiveGridLayout>
        </div>
      </>
    );
  } else if (isFetched && !isFetching && session.authenticated && cards && !isAdmin) {
    content = (
      <EmptyState
        illustration={(
          <Box width="small" round="full" margin={{ top: 'large' }}>
            <Image src={robboAddCards} />
          </Box>
        )}
        heading={(
          <Heading level="1" size="small">
            {isAdmin ? 'Let’s add some apps to your space' : 'Let\'s add some Cards to your space'}
          </Heading>
        )}
        caption={(
          <Text size="large" textAlign="center" margin={{ bottom: 'medium' }} style={{ maxWidth: '60ch' }}>
            {isAdmin
              ? 'Find all the apps you already use by browsing our App Directory. You can customize what apps you want to see on your Board anytime.'
              : 'Browse available Cards and pin the ones you want to see to your Board.You can customize your Board anytime.'}
          </Text>
        )}
        action={(
          <Button
            size="large"
            primary
            icon={<AddCircle />}
            label={isAdmin ? 'Add app from Directory' : 'Manage Cards'}
            onClick={() => navigate(isAdmin ? '/app/modal/app/directory' : '/app/assistant/mycards')}
          />
        )}
      />
    );
  } else {
    content = null;
  }

  const closeAppPicker = () => setAppPickerOpen(false);
  const [isTipHidden, setIsTipHidden] = useState(false);

  const onSaveAppPicker = async () => {
    await invalidateQueries('/userworkplace/usercards');
    setAppPickerOpen(false);
  };

  const isBannerShowing = useIsBannerShowing((state) => state.showing);

  let pinTop = (!session.isEmailConfirmed && !session.isOIDC) ? '150px' : '120px';

  if (!isSSR && location?.pathname.includes('embed')) {
    pinTop = '60px';
  }

  if (isBannerShowing) {
    pinTop = '150px';
  }

  const searchRef = useRef();
  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
    if (searchRef.current) {
      const searchField = searchRef.current;

      const enterListener = (e) => {
        if (e.key === 'Enter') {
          navigate(config.searchUrl.replace('%s', searchValue));
        }
      };

      searchField.removeEventListener('keydown', enterListener);
      searchField.addEventListener('keydown', enterListener);

      return () => {
        searchField.removeEventListener('keydown', enterListener);
      };
    }
  }, [searchValue]);

  return (
    <>
      <Header direction="row" fill="horizontal" justify="end">
        {isAdmin
          ? (
              <>
                <AppPickerButton
                  id="AppPickerButton"
                  icon={<Add color="white" size="16px" />}
                  open={false}
                  margin={{ vertical: 'small', right: 'large' }}
                  onClick={() => {
                    if (location?.pathname.includes('embed')) {
                      window.open('https://app.adenin.com/app/assistant/grid-board?picker=true', '_blank');
                    } else {
                      setAppPickerOpen(true);
                    }
                  }}
                />
                {!isTipHidden && !appPickerOpen && isFetched && cards?.filter((card) => !card.Name.startsWith('sys')).length === 0 && (
                  <PinTip size={size} top={pinTop}>
                    <Box pad="medium" gap="small">
                      <Box direction="row" justify="between">
                        <Text weight="bold" color="white">
                          Add your apps over here!
                        </Text>
                        <Button icon={<Close color="white" size="12px" />} onClick={() => setIsTipHidden(true)} />
                      </Box>
                      <Text color="white">Your Board is your personal space and you can pin Cards from your favorite apps to it.</Text>
                    </Box>
                  </PinTip>
                )}
              </>
            )
          : null}
      </Header>
      {config.searchUrl && (
        <Box direction="row" gap="small" alignSelf="center" margin={{ horizontal: 'medium' }}>
          <Box width="medium">
            <TextInput
              ref={searchRef}
              placeholder="What are you searching for today?"
              icon={<Search />}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Box>
          <Button
            primary
            label="Search"
            onClick={() => navigate(config.searchUrl.replace('%s', searchValue))}
          />
        </Box>
      )}
      {content}
      <script src="/iframeResizer.contentWindow.min.js" />
      {appPickerOpen && (
        <Layer id="AppPickerModal" background="background" modal onClickOutside={closeAppPicker} style={{ minHeight: '300px', maxHeight: '97vh' }}>
          <Box overflow="auto">
            <Box width="large" flex="grow">
              <Box pad={{ horizontal: 'medium', top: 'medium' }} direction="row" fill="horizontal" justify="between" gap="medium" flex="grow">
                <Heading level="4" margin="none">Add a new app</Heading>
                <Button icon={<Close />} onClick={closeAppPicker} />
              </Box>
              <CardCreator onSave={onSaveAppPicker} initiallyExpanded />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
}

export default memo(Board);
