import { Buffer } from 'buffer';

import { useEffect } from 'react';

import { isSSR } from '../utils';
import Splash from './SplashScreen';

function Loader() {
  useEffect(() => {
    if (isSSR) return;
    const params = new URLSearchParams(location.search);
    const enc = params.get('url');
    const url = Buffer.from(enc, 'base64').toString('utf-8');
    window.location.href = url;
  }, []);
  return <Splash />;
}

export default Loader;
