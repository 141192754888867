import './splash.css';

import { ThemeContext } from 'grommet';
import { useContext } from 'react';

function SplashScreen() {
  const { dark } = useContext(ThemeContext);
  return (
    <div id="splash">
      <div className="initial-load-animation">
        <svg className="ip" viewBox="0 0 400 100" width="400px" height="100px" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="grad" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#d50000" />
              <stop offset="33%" stopColor="#f2360a" />
              <stop offset="67%" stopColor="#FA6618" />
              <stop offset="100%" stopColor="#DB0B02" />
            </linearGradient>
          </defs>
          <g fill="none" strokeLinecap="round" strokeWidth="16">
            <g className={`ip__track${dark ? '_dark' : ''}`} stroke="#ddd">
              <path d="M26,49.2146921 C26,22.2834046 44.1839934,2.30803631 72.5641933,2.30803631 C93.1345483,2.92012273 114.349758,18.5556747 136.209822,49.2146921 C156.532122,77.718245 177.556239,96.8878392 200.34288,96.8878392 C220.817774,96.8878392 242.718725,79.1060167 264.013042,49.3100066 C289.923268,15.2984831 310.152778,0.662573619 329.532546,2.30803631 C357.477046,4.68069799 374,23.6476767 374,49.5955048 C372.334456,76.2913084 358.296616,93.3738596 329.532546,96.8878392 C310.514101,98.5264861 288.258916,82.2098982 262.766993,47.9380757 C239.299346,16.758019 218.491308,1.4503501 200.34288,2.01506897 C180.272311,1.4503501 158.406995,17.92845 134.746931,51.4493687 C91.1312742,117.595918 30.0443675,104.075079 26,49.2146921 Z" />
            </g>
            <g strokeDasharray="180 800">
              <path className="ip__worm" stroke="url(#grad)" strokeDashoffset="0" d="M26,49.2146921 C26,22.2834046 44.1839934,2.30803631 72.5641933,2.30803631 C93.1345483,2.92012273 114.349758,18.5556747 136.209822,49.2146921 C156.532122,77.718245 177.556239,96.8878392 200.34288,96.8878392 C220.817774,96.8878392 242.718725,79.1060167 264.013042,49.3100066 C289.923268,15.2984831 310.152778,0.662573619 329.532546,2.30803631 C357.477046,4.68069799 374,23.6476767 374,49.5955048 C372.334456,76.2913084 358.296616,93.3738596 329.532546,96.8878392 C310.514101,98.5264861 288.258916,82.2098982 262.766993,47.9380757 C239.299346,16.758019 218.491308,1.4503501 200.34288,2.01506897 C180.272311,1.4503501 158.406995,17.92845 134.746931,51.4493687 C91.1312742,117.595918 30.0443675,104.075079 26,49.2146921 Z" />
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default SplashScreen;
