import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import config from '../config';

function Index() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(config.defaultPath + location.search);
  });

  return null;
}

export default Index;
