import { ThemeContext } from 'grommet';
import {
  Action,
  Close,
  Configure,
  ContactInfo,
  Currency,
  Time,
  User,
  UserAdmin,
  Vulnerability,
} from 'grommet-icons';
import type {
  ButtonExtendedProps,
} from 'grommet/components';
import {
  Box,
  Button,
  Heading,
  Nav,
  Sidebar,
  Text,
} from 'grommet/components';
import type { HeightType, PadType } from 'grommet/utils';
import { memo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

import config from '../config';
import { useSessionStore } from '../hooks';
import UpgradeArrow from '../icons/upgrade_arrow.svg?react';
import { getBorderStyle } from '../utils';
import ImageLoader from './utils/ImageLoader';

export type PageKey =
  | 'profile'
  | 'billing'
  | 'connectors'
  | 'users'
  | 'roles'
  | 'settings'
  | 'cors'
  | 'usageinformationrecords';

type PageTitle =
  | 'My profile'
  | 'Billing & usage'
  | 'App connections'
  | 'Users'
  | 'Roles'
  | 'Configuration'
  | 'CORS'
  | 'API Logs';

type PagePath =
  | '/app/admin/profile'
  | '/app/admin/deleteaccount'
  | '/app/billing'
  | '/app/subscription'
  | '/app/admin/connectors'
  | '/app/admin/grid/connector'
  | '/app/admin/users'
  | '/app/admin/roles'
  | '/app/admin/settings'
  | '/app/admin/cors'
  | '/app/admin/usageinformationrecords';

type PageMetadata = {
  title: PageTitle
  path: PagePath
  icon: React.ReactElement
  subPaths?: PagePath[]
};

export const pageMetadata: Record<PageKey, PageMetadata> = {
  profile: {
    title: 'My profile',
    path: '/app/admin/profile',
    icon: <ContactInfo />,
    subPaths: ['/app/admin/deleteaccount'],
  },
  billing: {
    title: 'Billing & usage',
    path: '/app/billing',
    icon: <Currency />,
    subPaths: ['/app/subscription'],
  },
  connectors: {
    title: 'App connections',
    path: '/app/admin/connectors',
    icon: <Action />,
    subPaths: ['/app/admin/grid/connector'],
  },
  users: {
    title: 'Users',
    path: '/app/admin/users',
    icon: <User />,
  },
  roles: {
    title: 'Roles',
    path: '/app/admin/roles',
    icon: <UserAdmin />,
  },
  settings: {
    title: 'Configuration',
    path: '/app/admin/settings',
    icon: <Configure />,
  },
  cors: {
    title: 'CORS',
    path: '/app/admin/cors',
    icon: <Vulnerability />,
  },
  usageinformationrecords: {
    title: 'API Logs',
    path: '/app/admin/usageinformationrecords',
    icon: <Time />,
  },
};

const rightBorderStyle = getBorderStyle('right');
const bottomBorderStyle = getBorderStyle('bottom');
const leftBorderStyle = getBorderStyle('left');

function getLabel(key: PageKey, active: boolean, upgradeable: boolean) {
  if (active && upgradeable) {
    return (
      <Box direction="row" gap="xsmall" align="center">
        <Text>{pageMetadata[key].title}</Text>
        <UpgradeArrow />
      </Box>
    );
  }

  return pageMetadata[key].title;
}

function SidebarButton({
  label, icon, active, ...rest
}: ButtonExtendedProps) {
  return (
    <Button plain {...rest}>
      <Box
        direction="row"
        gap="small"
        background={active ? 'brand' : undefined}
        pad="medium"
      >
        {icon}
        <Text>{label}</Text>
      </Box>
    </Button>
  );
}

const MemoizedSidebarButton = memo(SidebarButton);

function AdminMenu({ upgradeable = false }: { upgradeable?: boolean }) {
  const location = useLocation();
  const navigate = useNavigate();

  const menuButtons = (Object.keys(pageMetadata) as PageKey[]).map((key) => {
    const active = location.pathname.includes(pageMetadata[key].path) || (pageMetadata[key].subPaths?.some((path) => location.pathname.startsWith(path)) ?? false);
    return (
      <MemoizedSidebarButton
        key={`admin-menu-${key}`}
        active={active}
        gap="small"
        hoverIndicator
        justify="start"
        icon={pageMetadata[key].icon}
        label={getLabel(key, active, upgradeable)}
        onClick={() => navigate(pageMetadata[key].path)}
      />
    );
  });

  return (
    <Sidebar responsive={false} gap="xsmall" width="medium" pad="small" border={rightBorderStyle}>
      <Nav responsive={false} gap="xsmall">
        {menuButtons}
      </Nav>
    </Sidebar>
  );
}

const MemoizedAdminMenu = memo(AdminMenu);

const containerHeight: HeightType = { min: '100vh' };
const closeButtonPad: PadType = { left: 'medium', right: 'small' };
const closeIcon = <Close />;

function AdminOverlay({
  title,
  children,
  upgradeable = false,
}: {
  title?: string
  children: React.ReactNode
  upgradeable?: boolean
}) {
  const session = useSessionStore((state) => state.session);
  const location = useLocation();
  const navigate = useNavigate();

  const onClose = useCallback(() => navigate(config.defaultPath), [navigate]);

  return (
    <ThemeContext.Extend value={{ dark: false }}>
      <Box fill height={containerHeight} background="background">
        <Box direction="row" pad="small" justify="between" border={bottomBorderStyle}>
          <ImageLoader src={session.logoUrl} height={40} width="auto" shape="rect" />
          <Box border={leftBorderStyle} pad={closeButtonPad} justify="center">
            <Button icon={closeIcon} plain onClick={onClose} />
          </Box>
        </Box>
        {location.pathname.includes('themedesigner')
          ? (
              <Box direction="row" fill>
                {children}
              </Box>
            )
          : (
              <Box direction="row" fill>
                <MemoizedAdminMenu upgradeable={upgradeable} />
                <Box pad="medium" gap="medium" fill>
                  {title && <Heading level="2" margin="0">{title}</Heading>}
                  {children}
                </Box>
              </Box>
            )}
      </Box>
    </ThemeContext.Extend>
  );
}

export default memo(AdminOverlay);
