import config from '../../../config';
import { isMobilePlatform } from '../../../utils';

export function processCardLayout(card, cardLayout) {
  const newLayout = cardLayout;

  if (config.boardWidthScale !== 1) {
    newLayout.resizeHandles = ['s'];
  }

  if (card.configuration?.minWidth) {
    const intMinW = parseFloat(card.configuration.minWidth);
    newLayout.minW = Math.floor(intMinW * config.boardWidthScale + 0.9);
    if (cardLayout.minW < 1) newLayout.minW = 1;
  }

  if (card.configuration?.maxWidth) {
    const intMaxW = parseFloat(card.configuration.maxWidth);
    newLayout.maxW = Math.floor(intMaxW * config.boardWidthScale + 0.9);
  }

  if (card.configuration?.minHeight) {
    const intMinH = parseFloat(card.configuration.minHeight);
    newLayout.minH = Math.floor(intMinH / config.boardHeightScale + 0.9);
    if (cardLayout.minH < 1) newLayout.minH = 1;
  }

  if (card.configuration?.maxHeight) {
    const intMaxH = parseFloat(card.configuration.maxHeight);
    newLayout.maxH = Math.floor(intMaxH / config.boardHeightScale + 0.9);

    if (cardLayout.minH === cardLayout.maxH) {
      newLayout.resizeHandles = [];
    }
  }

  return cardLayout;
}

export function getLayout(cols, cards) {
  const layouts = [];
  const newCards = [];

  let x = 0;
  let y = 0;

  let h = 0;
  let w = 0;

  let xnext = 0;
  let ynext = 0;
  let ymax = 0;

  for (let i = 0; i < cards.length; i++) {
    const card = cards[i];
    const layout = card.layout[`c${cols}`] || {};

    if (!layout.h || !layout.w) {
      newCards.push(card);
      continue;
    }

    h = layout.h;
    w = layout.w;

    if (w > cols) w = cols; // max width is number of cols

    x = layout.x || 0;
    y = layout.y || 0;

    if (isNaN(x)) x = 0;
    if (isNaN(y)) y = 0;

    if (y + h > ymax) ymax = y + h;

    xnext = x + w;
    ynext = y;

    const cardLayout = processCardLayout(card, {
      w, h, x, y, i: card.Id,
    });

    layouts.push(cardLayout);
  }

  for (let i = 0; i < newCards.length; i++) {
    if (xnext >= cols) {
      xnext = 0;
      ynext = ymax;
    }

    const card = newCards[i];

    h = Math.floor((parseFloat(card.configuration?.defaultHeight) || 2) / config.boardHeightScale + 0.9);
    w = Math.floor((parseFloat(card.configuration?.defaultWidth) || 2) * config.boardWidthScale + 0.9);

    x = xnext;
    y = ynext;

    if (isNaN(x)) x = 0;
    if (isNaN(y)) y = 0;

    // check if card fits into current line
    if (x + w > cols) {
      // create new line
      x = 0;
      y = ymax;
    }

    // save new layout height ymax
    if (y + h > ymax) ymax = y + h;

    xnext = x + w;
    ynext = y;

    const cardLayout = processCardLayout(card, {
      w, h, x, y, i: card.Id,
    });

    layouts.push(cardLayout);
  }

  // console.log('matrix layouts', layouts);

  return layouts;
}

export const SINGLE = 'single-column';
export const MULTI = 'multi-column';

export function getLayouts(cols, cards, adaptiveBoard) {
  if (adaptiveBoard) {
    return {
      [MULTI]: getLayout(cols[MULTI], cards),
      [SINGLE]: getLayout(cols[SINGLE], cards),
    };
  }

  return {
    lg: getLayout(cols.lg, cards),
    md: getLayout(cols.md, cards),
    sm: getLayout(cols.sm, cards),
    xs: getLayout(cols.xs, cards),
  };
}

const WIDTH_XL = 1440;
const WIDTH_LG = 1303;
const WIDTH_MD = 877;
const WIDTH_SM = 151;

const mobileCardMargin = () => {
  if (isMobilePlatform === true) {
    // 10 on mobiles
    return 10;
  }
  // Original value
  return 24;
};

const cardMargin = mobileCardMargin();

const DEFAULT_CARD_HEIGHT = 224;

const getCardHeight = (def) => (config.boardHeightScale !== 1 ? Math.floor(def * config.boardHeightScale + 0.9) - cardMargin : def);
const COL_COUNT = Math.floor((isMobilePlatform ? 1 : 8) * config.boardWidthScale + 0.9) || 1;

const cols = {
  lg: COL_COUNT,
  md: Math.floor(6 * config.boardWidthScale + 0.9),
  sm: Math.floor(4 * config.boardWidthScale + 0.9),
  xs: Math.floor(2 * config.boardWidthScale + 0.9),
};

export const useGridLayout = (perc, hasSidebar = false) => {
  let cardHeight = 0;
  let colCount = COL_COUNT;
  let columns = cols;
  let widths = {};

  if (!isMobilePlatform) {
    colCount = hasSidebar ? 7 : 8;
    columns = {
      [MULTI]: colCount,
      [SINGLE]: 1,
    };

    widths = {
      [MULTI]: WIDTH_MD,
      [SINGLE]: 0,
    };

    cardHeight = getCardHeight(perc * DEFAULT_CARD_HEIGHT);
  } else {
    widths = {
      lg: WIDTH_XL,
      md: WIDTH_LG,
      sm: WIDTH_MD,
      xs: WIDTH_SM,
    };

    cardHeight = getCardHeight(DEFAULT_CARD_HEIGHT);
  }

  return {
    cardMargin, cardHeight, colCount, columns, widths, adaptiveBoard: !isMobilePlatform,
  };
};

/* const CARD_WIDTH = config.boardWidthScale !== 1 ? Math.floor((CARD_HEIGHT - CARD_MARGIN) * config.boardWidthScale + 0.9) : CARD_HEIGHT;
const NUM_COLUMNS = config.boardWidthScale !== 1 ? DEFAULT_COL_COUNT * config.boardWidthScale : config.boardWidthScale * DEFAULT_COL_COUNT;
const GRID_WIDTH = 1512;

export function getGridBackground() {
  const innerGridCells = Array.from(Array(NUM_COLUMNS), (__, i) => `<rect fill='#E5E5E550' stroke='none' x='${(CARD_WIDTH + CARD_MARGIN) * i + CARD_MARGIN}' y='${CARD_MARGIN}' width='100px' height='${CARD_HEIGHT}' />`).join('');

  const svg = `<svg xmlns='http://www.w3.org/2000/svg' width='${GRID_WIDTH}' height='${CARD_HEIGHT + CARD_MARGIN}'>${innerGridCells}</svg>`;

  return `url("data:image/svg+xml;utf8,${encodeURIComponent(svg)}")`;
} */
