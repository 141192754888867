import { FormDown, FormUp } from 'grommet-icons';
import {
  Box,
  Button,
  Collapsible,
  Image,
  Text,
} from 'grommet/components';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { dispatch } from 'use-bus';

import config, { hostUrl } from '../config';
import { useToken, withPkce } from '../hooks';
import { ErrorReporter } from '../utils';

const marginLeftXSmall = { left: 'xsmall' };
const marginTopSmall = { top: 'small' };

function GlobalError({ error }) {
  const [open, setOpen] = useState(false);
  const token = useToken();
  const navigate = useNavigate();

  const logout = useCallback(() => {
    document.cookie = 'oidc=;path=/';
    document.cookie = 'tenant=;path=/';

    let logoutUrl = `${hostUrl}/account/logout?returnUrl=${encodeURIComponent(location.href)}`;

    if (config.useTokenAuth && token?.access_token) {
      logoutUrl += `&revoke_token=${token?.access_token}`;
    }

    if (config.useTokenAuth && token?.access_token) {
      dispatch({ type: 'pkce.clear', logoutUrl });
      return;
    }

    navigate(logoutUrl);
  }, [navigate, token?.access_token]);

  useEffect(() => {
    ErrorReporter.error(`[Boundary] ${error?.stack || error?.toString()}`);
    console.error(error);
  }, [error]);

  const reload = () => window.location.reload();
  const toggleOpen = useCallback(() => setOpen((prev) => !prev), []);

  if (error.name === 'ChunkLoadError') {
    return (
      <Box fill background="page-background" justify="center" align="center" gap="medium" pad="medium">
        <Image src="/robo_404_800px.png" width={350} fit="contain" alt="Error" />
        <Text weight="bold">The application has been updated, please refresh your browser</Text>
        <Box direction="row" gap="medium">
          <Button label="Reload" primary onClick={reload} />
        </Box>
      </Box>
    );
  }

  return (
    <Box fill background="page-background" justify="center" align="center" gap="medium" pad="medium">
      <Image src="/robo_404_800px.png" width={350} fit="contain" alt="Error" />
      <Text weight="bold">The application encountered an error</Text>
      <Box direction="row" align="center" justify="start" onClick={toggleOpen}>
        <Text color="dark-3">Details</Text>
        {open
          ? (
              <FormUp color="dark-3" margin={marginLeftXSmall} />
            )
          : (
              <FormDown color="dark-3" margin={marginLeftXSmall} />
            )}
      </Box>
      <Collapsible open={open}>
        <Box margin={marginTopSmall} align="center" justify="center" direction="column">
          <Text>{error?.stack || error?.toString()}</Text>
        </Box>
      </Collapsible>
      <Box direction="row" gap="medium">
        <Button label="Continue" primary href="/" />
        <Button label="Login" secondary onClick={logout} />
      </Box>
    </Box>
  );
}

const GlobalErrorWithPkce = withPkce(GlobalError);

export default GlobalErrorWithPkce;
