import { tenantId } from '../../../config';
import createCodeVerifier from './create-code-verifier';
import getEncodedVerifierKey from './getEncodedVerifierKey';
import hashed from './hashed';
import { base64URLEncode, sha256 } from './sha256-base64-url-encode';

export default function authorize({
  authorizeEndpoint, clientId, scopes, storage = sessionStorage,
}) {
  const redirect_uri = `${window.window.location.origin}/app/callback`;
  const returnUrl = window.location.href;
  const encodedVerifier = base64URLEncode(createCodeVerifier());

  storage.setItem(
    getEncodedVerifierKey(clientId),
    JSON.stringify({
      encodedVerifier,
      redirect_uri,
    }),
  );

  const query = {
    client_id: clientId,
    response_type: 'code',
    redirect_uri,
    code_challenge: base64URLEncode(sha256(encodedVerifier)),
    code_challenge_method: 'S256',
    returnUrl,
    tid: tenantId,
  };

  if (scopes && scopes.length > 0) {
    query.scope = scopes.join(' ');
  }

  const url = `${authorizeEndpoint}?${hashed(query)}`;
  window.location.replace(url);
}
