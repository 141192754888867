import { Box } from 'grommet';
import { useLocation } from 'react-router';

import { withSession } from '../components/EmbedSession';
import AssistantCard from '../components/board-cards/AssistantCard';
import { usePlatformQuery } from '../hooks';

function EmbedCard() {
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const shadow = params.get('shadow') !== 'disable';

  const { data } = usePlatformQuery(`/userworkplace/usercard/${id}`, {
    enabled: !!id,
  });

  return data?.Data
    ? (
        <Box margin="xsmall">
          <AssistantCard metadata={data.Data} iframe shadow={shadow} />
        </Box>
      )
    : null;
}

export default withSession(EmbedCard);
