import { Box, Button, Text } from 'grommet';
import { FormClose } from 'grommet-icons';
import { Suspense, lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useSidebarOpen } from '../../hooks';
import { sidebarCatalog } from '../../sidebars/catalog';
import { isMobilePlatform } from '../../utils';
import PlaceholderLoading from '../PlaceholderLoading';

const ApiErrorCard = lazy(() => import('../board-cards/ApiErrorCard'));

export default function AppSidebar({
  component, title, wipeDefault, ...rest
}) {
  const setSidebarOpen = useSidebarOpen((state) => state.sidebarOpen);

  function toggleSidebar() {
    setSidebarOpen(false);
  }

  let SidebarContent = null;

  // check if custom react component should be used to render specific card Id
  const sidebar = sidebarCatalog[component || title];
  if (sidebar) {
    // console.log('render react card ' + metadata?.Title);
    SidebarContent = sidebar;
  } else {
    SidebarContent = sidebarCatalog.empty;
  }

  // scroll page container to top
  // *todo* anylze why scroll position is restored
  const container = document.getElementById('pageContainer');

  if (container) {
    container.scrollTo(0, 0);
  }

  const innerSidebar = (
    <Suspense fallback={<PlaceholderLoading shape="rect" width={160} height={24} />}>
      <ErrorBoundary FallbackComponent={ApiErrorCard}>
        <SidebarContent {...rest} />
      </ErrorBoundary>
    </Suspense>
  );

  return (
    <Box
      flex
      fill="horizontal"
      background="background"
      pad={wipeDefault ? '' : 'small'}
      elevation="large"
      style={{ minWidth: isMobilePlatform ? '100%' : '400px', maxWidth: isMobilePlatform ? '100%' : '400px' }}
    >
      {wipeDefault
        ? (
            innerSidebar
          )
        : (
            <>
              <Box direction="row" align="center" as="header" justify="between">
                <Text size="xlarge">{title}</Text>
                <Button icon={<FormClose />} onClick={() => toggleSidebar()} />
              </Box>
              <Box flex overflow="auto" pad="xsmall">
                {innerSidebar}
              </Box>
              {title !== 'Hello' && title !== 'Assistant' && (
                <Box as="footer" border={{ side: 'top' }} pad="small" justify="end" direction="row" align="center">
                  <Button primary label="Save" onClick={() => toggleSidebar()} />
                </Box>
              )}
            </>
          )}
    </Box>
  );
}
