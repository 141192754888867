import { Grommet } from 'grommet';

import NotFoundBanner from '../components/NotFoundBanner';
import AppHeaderSkeleton from '../components/layout/AppHeaderSkeleton';
import { useColorScheme } from '../hooks';
import theme from '../theme';

function NotFound() {
  const colorScheme = useColorScheme(theme.defaultMode);
  return (
    <Grommet full theme={theme} themeMode={colorScheme ? 'light' : 'dark'} background="page-background" id="pageContainer">
      <AppHeaderSkeleton />
      <NotFoundBanner />
    </Grommet>
  );
}

export default NotFound;
