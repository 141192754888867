// wrapper to load theme based on preference
// *todo*  lazy load selected theme

import type { ThemeType } from 'grommet';

import { one } from './one';

const theme: ThemeType = one;

export default theme;
