import { lazy, useMemo } from 'react';

import { cardCatalog } from '../../../cards/catalog';
import { useCurrentColumns } from '../../../hooks';
import { configureTemplating, expandBody } from '../../../utils/templating';
import RenderedCard from './RenderedCard';

const AdaptiveCardContainer = lazy(() => import('../AdaptiveCard'));
const QNACard = lazy(() => import('../QnaCard'));
const ApiErrorCard = lazy(() => import('../ApiErrorCard'));
const ApiAuthCard = lazy(() => import('../ApiAuthCard'));
const GenericErrorCard = lazy(() => import('../GenericErrorCard'));
const LoginRequiredCard = lazy(() => import('../LoginRequiredCard'));
const NotificationCard = lazy(() => import('../NotificationCard'));
const ApiConfigCard = lazy(() => import('../ApiConfigCard'));
const NotFoundCard = lazy(() => import('../NotFoundCard'));

function ProcessedCard({
  metadata,
  data,
  draggable = null,
  drag = null,
  error = null,
  notification = false,
  iframe = false,
  shadow = true,
  sidebar = false,
  closeToNotifications = true,
  dismissable = false,
}) {
  configureTemplating();

  const adaptive = useMemo(() => {
    try {
      return JSON.parse(metadata.AdaptiveCardDefinition || '{}');
    } catch {
      return {};
    }
  }, [metadata.AdaptiveCardDefinition]);

  const expressions = useMemo(() => expandBody(adaptive.body, data?.Data), [adaptive.body, data?.Data]);

  // re-render when the board size changes
  const currentCols = useCurrentColumns((state) => state.currentColumns);

  let CardContent = null;

  // check if custom react component should be used to render specific card Id
  // uses notebook Name to prevent title collision; fall back to Card title if not found
  let customCard = false;

  // find the current width and height settings for this column width
  let currentLayout = false;
  let customDimensionCard = false;

  if (metadata.layout) {
    currentLayout = metadata.layout[`c${currentCols}`];
  }

  metadata.expressions = expressions;

  if (currentLayout && metadata.configuration) {
    // check config for a custom card name to be used at these dimensions
    customDimensionCard = metadata.configuration[`card${currentLayout.w}x${currentLayout.h}`] || false;
    metadata.currentCols = currentCols;
    metadata.currentLayout = currentLayout;
  }

  let { name } = metadata;

  if (customDimensionCard) {
    closeToNotifications = false;
  }

  let compact = false;

  if (!sidebar && customDimensionCard) {
    // render the custom dimensions card name instead if one was configured
    customCard = cardCatalog[customDimensionCard] || false;
    name = customDimensionCard;
    if (name === 'compact') compact = true;
  } else if (metadata.configuration?.card && cardCatalog[metadata.configuration.card]) {
    customCard = cardCatalog[metadata.configuration.card];
    if (metadata.configuration.card === 'compact') compact = true;
  }

  if (compact) {
    metadata.OriginalCard = cardCatalog[metadata.configuration.card] || false;
  }

  metadata.__compact = compact;

  if (customCard) {
    CardContent = customCard;
    metadata.Type = 'custom';
  } else {
    // Only append footer for non-custom cards (AC)
    const expButtonLabel = expressions?.buttonLabel?.trim();
    const expButtonUrl = expressions?.buttonUrl?.trim();

    const dataButtonLabel = data?.Data?.linkLabel;
    const dataButtonUrl = data?.Data?.link;

    if (expButtonLabel && expButtonUrl) {
      metadata.footer = {
        label: expButtonLabel,
        url: expButtonUrl,
      };
    } else if (dataButtonLabel && dataButtonUrl) {
      metadata.footer = {
        label: dataButtonLabel,
        url: dataButtonUrl,
      };
    }

    if (Array.isArray(metadata.footerActions) && metadata.footerActions.length) {
    // if no footer yet, first of action list becomes main .footer
      if (!metadata.footer) {
        metadata.footer = {
          label: metadata.footerActions[0].title,
          url: metadata.footerActions[0].url,
        };
      }

      // add an indicator that there's multiple footers in actions list
      if (metadata.footerActions.length > 1) {
        metadata.footer.hasMultiple = true;
      }
    }
  }

  // replace initial metadata with optional metadata returned from connector
  if (data?.Data?._card) {
    metadata = Object.assign(metadata, data.Data._card);

    if (data.Data._card?.type === 'status-list' && !metadata.BaseTemplate) {
      metadata.BaseTemplate = data.Data._card?.type;
    }
  }

  if (data && data.ErrorCode !== 0) {
    CardContent = ApiErrorCard;
    if (!data.Data) data.Data = {};
    data.Data.ErrorCode = data.ErrorCode;

    if (data.ErrorCode === 461) {
      // OAuth2 connector requires authorization
      CardContent = ApiAuthCard;
    }

    if (data.ErrorCode === 401) {
      // User needs to re-authorize with platform
      CardContent = LoginRequiredCard;
    }

    if (data.ErrorCode === 404 && data.Data?.ErrorCodeDetail?.startsWith('404.')) {
      CardContent = NotFoundCard;
    }

    if (data.ErrorCode === 464) {
      // Admin needs to configure connector
      CardContent = ApiConfigCard;
    }
  } else if (error?.message) {
    CardContent = ApiErrorCard;
    data = {
      ErrorCode: 500,
      Data: {
        ErrorCode: 500,
        ErrorText: error.message,
      },
    };
  } else if (metadata.Type === 'qacard') {
    CardContent = QNACard;
  } else if (notification) {
    CardContent = NotificationCard;
  } else if (typeof metadata.AdaptiveCardDefinition !== 'string') {
    CardContent = GenericErrorCard;

    if (data) {
      data.Data.title = data.Data.title || metadata.Title;
      data.Data.ErrorText = 'Unsupported card type';
      console.log('card type');
    }
  }

  // fallback to use generic AdaptiveCard
  if (CardContent == null) {
    CardContent = AdaptiveCardContainer;
  }

  if (compact && !metadata.OriginalCard) {
    metadata.OriginalCard = AdaptiveCardContainer;
  }

  return (
    <RenderedCard
      metadata={metadata}
      data={data}
      content={CardContent}
      draggable={draggable}
      drag={drag}
      notification={notification}
      iframe={iframe}
      shadow={shadow}
      sidebar={sidebar}
      name={name}
      closeToNotifications={closeToNotifications}
      dismissable={dismissable}
      hideFooter={compact}
    />
  );
}

export default ProcessedCard;
