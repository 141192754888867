import { pkceSettings } from '../config';
import { createAuthContext } from '../lib/react-pkce';

export const {
  AuthContext, Authenticated, useToken, withPkce,
} = createAuthContext({
  clientId: pkceSettings?.clientId,
  authorizeEndpoint: pkceSettings?.endpoints?.authorize,
  tokenEndpoint: pkceSettings?.endpoints?.token,
  scopes: pkceSettings?.scopes,
});
