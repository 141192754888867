import { LandingHeroContainer } from './styles';

const backgroundStyle = {
  height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
};

function LandingHero({ children }) {
  const { file } = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "Bridges_login_bg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  return (
    <BackgroundImage fluid={file.childImageSharp.fluid} style={backgroundStyle}>
      <LandingHeroContainer>
        {children}
      </LandingHeroContainer>
    </BackgroundImage>
  );
}

export default LandingHero;
