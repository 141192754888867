import ACData from 'adaptivecards-templating';

import { analyzeJson } from './analyzePayload';

function generateErrorCard(message) {
  // Default error for empty data
  let errorCard = {
    type: 'AdaptiveCard',
    $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
    version: '1.2',
    body: [
      {
        type: 'TextBlock',
        text: 'I did not detect any usable information in the data',
        wrap: true,
        weight: 'Bolder',
        id: 'CardGeneratorError',
      },
    ],
  };

  // Use if an error message was passed
  if (message !== undefined) {
    errorCard = {
      type: 'AdaptiveCard',
      $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
      version: '1.2',
      body: [
        {
          type: 'TextBlock',
          text: 'I\'m sorry, I could not generate a card',
          wrap: true,
          weight: 'Bolder',
          id: 'CardGeneratorError',
        },
        {
          type: 'TextBlock',
          text: `${message}`,
          wrap: true,
          size: 'Small',
          weight: 'Lighter',
        },
      ],
    };
  }
  return errorCard;
}

function generateSafePath(element, rootObject) {
  // Split path segments
  const strings = element.name
    .split('.');

  const path = [];
  // Loop over path segments and check if they are valid
  for (const word of strings) {
    const matchVar = word.match(/[a-zA-Z]+[a-zA-Z0-9_]*/);
    const matchArr = word.match(/[a-zA-Z]+[a-zA-Z0-9_]*\[\d+\]*/);
    // Valid paths

    if (element.name !== '' && (matchVar == word || matchArr == word)) {
      // If first safe path, push without dot notation
      if (path.length === 0) {
        path.push(`${word}`);
      // All other safe paths, push with dot notation
      } else {
        path.push(`.${word}`);
      }

    // Invalid paths
    // Check if the string has an array
    } else if (word.match(/\[\d+\]$/)) {
      // Split at the array start
      const array = word.split('[');
      // Construct a safe array path
      path.push(`['${array[0]}'][${array[1].charAt(0)}]`);
    } else {
      // Wrap in bracket notation
      path.push(`['${word}']`);
    }
  }

  // Check if the first path starts with bracket notation
  if (path[0].charAt(0).match(/\[/)) {
    // If there was no root object binded to $data, use $root
    if (rootObject === '') {
      path.splice(0, 0, '$root');
    } else {
      path.splice(0, 0, '$data');
    }
    // Try to prevent destructuring expression error
    let safeCountPath = path[0] + path[1];
    const safePath = path.join('');
    if (safeCountPath === safePath) {
      safeCountPath = undefined;
    }
    return { safePath, safeCountPath };
  }
  // Return safe path
  return { safePath: path.join('') };
}

export function filterAndSortAnalyzedJson(analyzedData) {
  // Clone data for mutation
  const data = analyzedData;
  // Counter for posType 0
  let posTypeCounter = 0;
  // Loop over elements to add sortPosition
  data.elements.forEach((element) => {
    // Define position with leading zeros and element position
    const position = String(element.pos).padStart(4, '0');

    if (element.posType > 0) {
      // Default 900 for additional unsorted elements
      if (element.type !== 'link') {
        element.sortPosition = `900-${position}`;
      }
    // posType 0 elements only
    } else {
      // Define specific sortPosition types
      switch (element.type) {
        case 'image':
          element.sortPosition = `050-${position}`;
          break;
        case 'email':
          element.sortPosition = `100-${position}`;
          break;
        // case 'title':
        //   element.sortPosition = `100-${position}`;
        //   break;
        case 'longtext':
          element.sortPosition = `200-${position}`;
          break;
        // case 'link':
        //   element.sortPosition = `650-${position}`;
        //   break;
        case 'button':
          element.sortPosition = `700-${position}`;
          break;
        default:
          // Exclude links from sortPosition, added later
          if (element.type !== 'link') {
            // Default 500 for unsorted posType 0 element
            element.sortPosition = `500-${position}`;
          }
          break;
      }
      // Increment counter
      posTypeCounter++;
    }

    // Set sortPosition for the first 3 title type elements
    if (element.type === 'title' && element.posType <= 2) {
      element.sortPosition = `075-${position}`;
    }

    const ignoredTypes = ['guid', 'integer', 'int32_large', 'int64', 'longword'];
    const ignoreElement = ignoredTypes.some((el) => element.type.includes(el));

    // Set duplicate value and ignored types sort position
    if (element.duplicateValue || ignoreElement) {
      element.sortPosition = `999-${position}`;
    }
  });

  // Add elements with sortPosition to a new array
  const filteredData = [];
  const linkElements = [];
  data.elements.forEach((element) => {
    if (element.sortPosition) {
      filteredData.push(element);
    }
    if (element.type === 'link') {
      linkElements.push(element);
    }
  });

  // Replace data with filtered elements
  data.elements = filteredData;

  // Sort elements by sortPosition
  function sortBySortPosition(a, b) {
    return a.sortPosition.localeCompare(b.sortPosition);
  }
  data.elements.sort(sortBySortPosition);

  // Set max for returned fields
  const resultLimit = Math.max(posTypeCounter, 10);

  // Remove any elements exceeding the resultLimit
  if (data.elements.length > resultLimit) {
    data.elements.splice(resultLimit);
  }

  // Add a max of 3 link elements to returned results
  if (linkElements.length > 0) {
    linkElements.splice(3);
    linkElements.forEach((element) => {
      data.elements.push(element);
    });
  }

  // console.log('Return from filterAndSortAnalyzedJson', data);
  return data;
}

export function generateCard(rawData) {
  // Copy the data for mutation
  let data = rawData;
  const { isList } = data;

  // Attempt to parse data
  if (typeof data === 'string') {
    if (data === '') {
      // Empty analyzed JSON editor window
      return generateErrorCard();
    }
    try {
      data = JSON.parse(rawData);
    } catch (e) {
      // Generate error card from invalid JSON
      console.error(e);
      return generateErrorCard(e);
    }
  }

  // Error message for a number because JSON.parse does not catch this
  if (typeof data === 'number') {
    return generateErrorCard(`SyntaxError: Unexpected token '${data}', "${data}" is not valid JSON`);
  }

  // Generate errorCard if success is false
  if (data.success === false) {
    return generateErrorCard(data.errorMessage);
  }

  // Sample card base template
  const cardDefinition = {
    type: 'AdaptiveCard',
    $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
    version: '1.2',
    body: [],
  };

  // Array found, use rootObject for $data binding
  if (data?.rootObject !== '') {
    const root = { type: 'Container', $data: `\${${data.rootObject}}`, items: [] };
    cardDefinition.body.push(root);
    // None found, dont use $data binding
  } else {
    const root = { type: 'Container', items: [] };
    cardDefinition.body.push(root);
  }

  // ActionSet template
  const actionSet = {
    type: 'ActionSet',
    actions: [],
    spacing: 'small',
  };

  data.elements.forEach((element) => {
    // Generate a safe path for the template engine
    const safeElement = generateSafePath(element, data.rootObject);
    // Check if a count path was provided to use with count()
    const safeCountPath = safeElement.safeCountPath ? `count(${safeElement.safeCountPath}) > 0 && ` : '';

    // Label prefix
    const prefixWithLabel = ['date', 'date-time', 'epoch', 'epoch_long', 'int32', 'number', 'shorttext'];
    const usePrefix = prefixWithLabel.includes(element.type);
    const prefix = usePrefix ? `${element.label} ` : '';

    // Dont add duplicates to card definition for string/title
    if ((element.type === 'title' || element.type === 'string') && element.duplicateValue === true) {
      return;
    }

    // Filter unwanted values that will not expand correctly
    if (element.name[0] !== '@' && element.name[0] !== '$' && element.name[0] !== '_') {
      let item;
      // Add elements to sample card template
      switch (element.type) {
        case 'string':
        case 'title':
        case 'email':
          item = {
            type: 'TextBlock', $when: `\${${safeCountPath}exists(${safeElement.safePath}) && greater(length(${safeElement.safePath}),0)}`, text: `${prefix}\${string(${safeElement.safePath})}`, wrap: true,
          };
          break;
        case 'date':
          item = {
            type: 'TextBlock', $when: `\${${safeCountPath}exists(${safeElement.safePath}) && greater(length(${safeElement.safePath}),0)}`, text: `${prefix}{{DATE(\${formatDateTime(${safeElement.safePath},'yyyy-MM-ddTHH:mm:ssZ')})}}`,
          };
          break;
        case 'date-time':
          item = {
            type: 'TextBlock', $when: `\${${safeCountPath}exists(${safeElement.safePath}) && greater(length(${safeElement.safePath}),0)}`, text: `${prefix}{{DATE(\${formatDateTime(${safeElement.safePath},'yyyy-MM-ddTHH:mm:ssZ')})}}, {{TIME(\${formatDateTime(${safeElement.safePath},'yyyy-MM-ddTHH:mm:ssZ')})}}`,
          };
          break;
        case 'epoch':
          item = {
            type: 'TextBlock', $when: `\${${safeCountPath}exists(${safeElement.safePath})}`, text: `${prefix}\${formatEpoch(int(${safeElement.safePath}), 'M/d/yyyy h:mm a')}`,
          };
          break;
        case 'epoch_long':
          item = {
            type: 'TextBlock', $when: `\${${safeCountPath}exists(${safeElement.safePath})}`, text: `${prefix}\${formatEpoch(div(int(${safeElement.safePath}),1000), 'M/d/yyyy h:mm a')}`,
          };
          break;
        case 'int32':
          item = {
            type: 'TextBlock', $when: `\${${safeCountPath}exists(${safeElement.safePath}) && (isFloat(${safeElement.safePath}) || isInteger(${safeElement.safePath}))}`, text: `${prefix}\${formatNumber(${safeElement.safePath},0)}`,
          };
          break;
        case 'number':
          item = {
            type: 'TextBlock', $when: `\${${safeCountPath}exists(${safeElement.safePath}) && (isFloat(${safeElement.safePath}) || isInteger(${safeElement.safePath}))}`, text: `${prefix}\${formatNumber(${safeElement.safePath},2)}`,
          };
          break;
        case 'boolean':
          item = {
            type: 'TextBlock', $when: `\${${safeCountPath}exists(${safeElement.safePath}) && isBoolean(${safeElement.safePath})}`, text: `${prefix}\${if(${safeElement.safePath},'☑','☐')} ${element.label}`,
          };
          break;
        case 'link':
          item = {
            type: 'Action.OpenUrl', $when: `\${${safeCountPath}exists(${safeElement.safePath}) && greater(length(${safeElement.safePath}),0)}`, title: `${element.label}`, url: `\${${safeElement.safePath}}`,
          };
          break;
        case 'image':
          item = {
            type: 'Image', url: `\${${safeElement.safePath}}`, $when: `\${${safeCountPath}exists(${safeElement.safePath}) && greater(length(${safeElement.safePath}),0)}`,
          };
          break;
        case 'longtext':
          item = {
            type: 'TextBlock', $when: `\${${safeCountPath}exists(${safeElement.safePath}) && greater(length(${safeElement.safePath}),0)}`, text: `${prefix}\${string(${safeElement.safePath})}`, wrap: true, maxLines: 3,
          };
          break;
        case 'shorttext':
          item = {
            type: 'TextBlock', $when: `\${${safeCountPath}exists(${safeElement.safePath}) && greater(length(${safeElement.safePath}),0)}`, text: `${prefix}\${string(${safeElement.safePath})}`, wrap: true,
          };
          break;
        default:
          break;
      }

      // Add item to card
      if (item) {
        if (item.type === 'Action.OpenUrl') {
          actionSet.actions.push(item);
        } else {
          cardDefinition.body[0].items.push(item);
        }
      }
    }
  });

  // Add actionSet if there are any link elements present
  if (actionSet.actions.length > 0) {
    cardDefinition.body[0].items.push(actionSet);
  }

  // Run only if items were added to the sample card
  if (cardDefinition.body[0].items.length > 0) {
    // Find first textblock
    const textBlocks = cardDefinition.body[0].items;
    const firstTextBlock = textBlocks.map((type) => type.type).indexOf('TextBlock');
    // Only run if a TextBlock was found
    if (firstTextBlock !== -1) {
      // Add weight Bolder to the first textblock
      cardDefinition.body[0].items[firstTextBlock].weight = 'Bolder';
    }
  }

  // Set small spacing excluding the first element
  if (cardDefinition.body[0].items.length > 0) {
    Object.keys(cardDefinition.body[0].items).forEach((key) => {
      if (key !== '0') {
        cardDefinition.body[0].items[key] = { ...cardDefinition.body[0].items[key], spacing: 'small' };
      }
    });
  }

  // Add separator if isList is true and data has been added
  if (isList && cardDefinition.body[0].items.length > 0) {
    const separator = {
      type: 'TextBlock', text: ' ', wrap: true, separator: true, spacing: 'medium',
    };
    cardDefinition.body[0].items.push(separator);

    if (data.rootObject) {
      // Add empty state
      const emptyState = {
        id: 'emptystate',
        type: 'Container',
        items: [
          {
            id: 'emptyStateDescription',
            type: 'TextBlock',
            text: 'This card is empty',
            wrap: true,
          },
        ],
        horizontalAlignment: 'Center',
        $when: `\${count(${data.rootObject})==0}`,
      };
      cardDefinition.body.push(emptyState);

      // Add summary view with rootObject name
      const summaryView = {
        type: 'Container',
        items: [
          {
            type: 'TextBlock',
            text: `\${count(${data.rootObject})} \${if(count(${data.rootObject}) != 1, '${data.rootObject}', '${data.rootObject}')}`,
            id: 'heading',
          },
        ],
        id: 'expressions',
        isVisible: false,
      };
      cardDefinition.body.push(summaryView);
    }
  }

  // No usable data found, show default error card
  if (cardDefinition.body[0].items.length === 0) {
    return generateErrorCard();
  }

  // console.log('Card definition from generateCard:', cardDefinition);
  // Error check before return
  try {
    // Validate card will render
    new ACData.Template(cardDefinition);
    // Return card
    return cardDefinition;
  } catch (e) {
    // Return error card
    console.error(e);
    return generateErrorCard(e);
  }
}

export function generateCardDefinition(data) {
  // Analyze payload data
  const analyzedJson = analyzeJson(data);
  // Filter and sort analyzed data
  const filterAndSortedJson = filterAndSortAnalyzedJson(analyzedJson);
  // Generate a card
  const generatedCard = generateCard(filterAndSortedJson);
  // Return generated card
  return generatedCard;
}
