import * as ACData from 'adaptivecards-templating';

export const configureTemplating = () => {
  ACData.GlobalSettings.getUndefinedFieldValueSubstitutionString = () => ' ';
};

export const expandExpression = (id: string, expression: string, data: any) => {
  if (!data) {
    return data;
  }

  const payload = {
    [id]: expression,
  };

  try {
    const template = new ACData.Template(payload);
    const expanded = template.expand({
      $root: data,
    });

    return {
      ...data,
      ...expanded,
    };
  } catch {
    return data;
  }
};

export const expandBody = (body: any[], data: any) => {
  if (!Array.isArray(body) || !body.length) {
    return data;
  }

  for (let i = 0; i < body.length; i++) {
    const entry = body[i];

    if (entry.id !== 'expressions' || entry.type !== 'Container' || !Array.isArray(entry.items) || !entry.items.length) {
      continue;
    }

    for (let j = 0; j < entry.items.length; j++) {
      const item = entry.items[j];
      data = expandExpression(item.id, item.text, data);
    }
  }

  return data;
};

export const expandUrl = (url: string, filterParams: string) => {
  const expanded = expandExpression('url', url, {
    _filter: filterParams,
  });

  // AC templating replaces empty values, but we need to remove the keys from the query
  const urlParts = expanded.url.split('?');
  let filteredQuery = '';
  [url] = urlParts;

  if (urlParts.length > 0) {
    const queryParams = new URLSearchParams(urlParts[1]);
    let first = true;

    queryParams.forEach((val, key) => {
      if (decodeURIComponent(val).trim()) {
        filteredQuery += `${(first ? '' : '&')}${key}=${val}`;
        first = false;
      }
    });
  }

  url += `?${filteredQuery}`;
  return url;
};

export default ACData;
