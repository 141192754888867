import type { ComponentType } from 'react';
import React, { Suspense, lazy, memo } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router';

import { pageMetadata } from '@/components/AdminOverlay';
import NotFoundBanner from '@/components/NotFoundBanner';
import PageLoader from '@/components/PageLoader';
import Providers from '@/components/Providers';
import SplashScreen from '@/components/SplashScreen';
import Index from '@/pages';
import NotFound from '@/pages/404';
import App from '@/pages/app';
import AuthComplete from '@/pages/authcomplete';
import Authenticate from '@/pages/authenticate';
import AuthError from '@/pages/autherror';
import Embedassistant from '@/pages/embedassistant';
import Embedcard from '@/pages/embedcard';
import Embedcreator from '@/pages/embedcreator';
import Login from '@/pages/login';
import Sitedown from '@/pages/sitedown';

const splash = <SplashScreen />;

const withSplashSuspense = (Component: React.LazyExoticComponent<ComponentType<any>>) => {
  return (props: any) => (
    <Suspense fallback={splash}>
      <Component {...props} />
    </Suspense>
  );
};

// Assistant
const ThemeDesigner = withSplashSuspense(lazy(() => import('@/views/assistant/ThemeDesigner')));
const ChangePassword = withSplashSuspense(lazy(() => import('@/views/assistant/ChangePassword')));
const FormDesigner = withSplashSuspense(lazy(() => import('@/views/assistant/FormDesigner')));
const ConnectorConfigViewer = withSplashSuspense(lazy(() => import('@/views/assistant/ConnectorConfigViewer')));
const AdaptiveCardGeneratorPlayground = withSplashSuspense(lazy(() => import('@/views/assistant/AdaptiveCardGeneratorPlayground')));
const AdaptiveExpressionPlayground = withSplashSuspense(lazy(() => import('@/views/assistant/AdaptiveExpressionPlayground')));
const InputCardGeneratorPlayground = withSplashSuspense(lazy(() => import('@/views/assistant/InputCardGeneratorPlayground')));
const SharepointPostMessage = withSplashSuspense(lazy(() => import('@/views/assistant/SharepointPostMessage')));
const MyCards = withSplashSuspense(lazy(() => import('@/views/assistant/MyCards')));
const MyConnectors = withSplashSuspense(lazy(() => import('@/views/assistant/MyConnectors')));
const Board = withSplashSuspense(lazy(() => import('@/views/assistant/Board')));
const CardFrame = withSplashSuspense(lazy(() => import('@/views/assistant/CardFrame')));
const Chat = withSplashSuspense(lazy(() => import('@/views/assistant/Chat')));
const JsxParserPlayground = withSplashSuspense(lazy(() => import('@/views/assistant/JsxParserPlayground')));

// Admin
const CardDesigner = withSplashSuspense(lazy(() => import('@/views/admin/CardDesigner/CardDesigner')));
const ConnectorConfiguration = withSplashSuspense(lazy(() => import('@/views/admin/ConnectorConfiguration')));
const DeleteAccount = withSplashSuspense(lazy(() => import('@/views/admin/DeleteAccount')));
const DemoMode = withSplashSuspense(lazy(() => import('@/views/admin/DemoMode')));
const GridView = withSplashSuspense(lazy(() => import('@/views/admin/GridView')));
const Notebook = withSplashSuspense(lazy(() => import('@/views/admin/Notebook')));
const Subscription = withSplashSuspense(lazy(() => import('@/views/admin/Subscription')));
const SubscriptionTest = withSplashSuspense(lazy(() => import('@/views/admin/Subscription/Plans')));
const SubscriptionBuy = withSplashSuspense(lazy(() => import('@/views/admin/Subscription/Buy')));
const PaddleCheckout = withSplashSuspense(lazy(() => import('@/views/admin/Subscription/PaddleCheckout')));
const Upgrade = withSplashSuspense(lazy(() => import('@/views/admin/Upgrade')));
const Connectors = withSplashSuspense(lazy(() => import('@/views/admin/Connectors')));
const Cors = withSplashSuspense(lazy(() => import('@/views/admin/Cors')));
const AdminIFrame = withSplashSuspense(lazy(() => import('@/views/admin/AdminIFrame')));
const MyProfile = withSplashSuspense(lazy(() => import('@/views/admin/MyProfile')));
const AdminGrid = withSplashSuspense(lazy(() => import('@/views/admin/AdminGrid')));
const Billing = withSplashSuspense(lazy(() => import('@/views/admin/Billing')));

// Testing
const CategorizationForm = withSplashSuspense(lazy(() => import('@/views/testing/CategorizationForm')));
const AppSelectorTest = withSplashSuspense(lazy(() => import('@/views/testing/AppSelectorTest')));
const ArrayForm = withSplashSuspense(lazy(() => import('@/views/testing/ArrayForm')));
const CardCreatorTest = withSplashSuspense(lazy(() => import('@/views/testing/CardCreatorTest')));
const FormTest = withSplashSuspense(lazy(() => import('@/views/testing/FormTest')));
const GoogleCseDemo = withSplashSuspense(lazy(() => import('@/views/testing/GoogleCseDemo')));
const HtmlForm = withSplashSuspense(lazy(() => import('@/views/testing/HtmlForm')));
const LookupTest = withSplashSuspense(lazy(() => import('@/views/testing/LookupTest')));
const Reset = withSplashSuspense(lazy(() => import('@/views/testing/Reset')));
const Tests = withSplashSuspense(lazy(() => import('@/views/testing')));
const IFrameBoard = withSplashSuspense(lazy(() => import('@/views/testing/IFrameBoard')));
const CardCreatorPopup = withSplashSuspense(lazy(() => import('@/views/testing/CardCreatorPopup')));
const DistributedCache = withSplashSuspense(lazy(() => import('@/views/testing/DistributedCache')));

const Version = withSplashSuspense(lazy(() => import('@/views/Version')));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="" Component={Index} />
      <Route path="login" Component={Login} />
      <Route path="app" Component={App}>
        <Route path="loader" Component={PageLoader} />
        <Route path="assistant/grid-board" element={null} />
        <Route path="assistant/board" element={null} />
        <Route path="assistant/card/*" element={null} />
        <Route path="assistant/themedesigner" Component={ThemeDesigner} />
        <Route path="assistant/changepassword" Component={ChangePassword} />
        <Route path="assistant/formdesigner" Component={FormDesigner} />
        <Route path="assistant/formdesigner/:viewId" Component={FormDesigner} />
        <Route path="assistant/connectorconfigviewer" Component={ConnectorConfigViewer} />
        <Route path="assistant/connectorconfigviewer/:viewId" Component={ConnectorConfigViewer} />
        <Route path="assistant/adaptivecardgeneratorplayground" Component={AdaptiveCardGeneratorPlayground} />
        <Route path="assistant/jsxparserplayground" Component={JsxParserPlayground} />
        <Route path="assistant/adaptiveexpressionplayground" Component={AdaptiveExpressionPlayground} />
        <Route path="assistant/inputcardgeneratorplayground" Component={InputCardGeneratorPlayground} />
        <Route path="assistant/sharepointpostmessage" Component={SharepointPostMessage} />
        <Route path="assistant/mycards" Component={MyCards} />
        <Route path="assistant/myconnectors" Component={MyConnectors} />
        <Route path="embedboard" Component={Board} />
        <Route path="embedcardframe" Component={CardFrame} />
        <Route path="assistant/chat" Component={Chat} />
        <Route path="designer/:entity" Component={CardDesigner} />
        <Route path="designer/:entity/:id" Component={CardDesigner} />
        <Route path="cardcreator" Component={CardCreatorTest} />
        <Route path="cardcreator/:id" Component={CardCreatorTest} />
        <Route path="connector/:name" Component={ConnectorConfiguration} />
        <Route path="admin/deleteaccount" Component={DeleteAccount} />
        <Route path="admin/demomode" Component={DemoMode} />
        <Route path="grid/:viewId" Component={GridView} />
        <Route path="notebook/:id" Component={Notebook} />
        <Route path="billing" Component={Billing} />
        <Route path="upgrade" Component={Upgrade} />
        <Route path="subscription" Component={Subscription} />
        <Route path="subscription-test" Component={SubscriptionTest} />
        <Route path="subscription/buy" Component={SubscriptionBuy} />
        <Route path="subscription/checkout/paddle" Component={PaddleCheckout} />
        <Route path="subscription/checkout/paddle/:paymentId" Component={PaddleCheckout} />
        <Route path={pageMetadata.connectors.path.replace('/app/', '')} Component={Connectors} />
        <Route path={pageMetadata.cors.path.replace('/app/', '')} Component={Cors} />
        <Route path={pageMetadata.profile.path.replace('/app/', '')} Component={MyProfile} />
        <Route path="admin/grid/:id" Component={AdminGrid} />
        <Route path="admin/:id" Component={AdminIFrame} />
        <Route path="testing" Component={Tests} />
        <Route path="testing/iframeboard" Component={IFrameBoard} />
        <Route path="testing/categorizationform" Component={CategorizationForm} />
        <Route path="testing/appselector" Component={AppSelectorTest} />
        <Route path="testing/arrayform" Component={ArrayForm} />
        <Route path="testing/formtest" Component={FormTest} />
        <Route path="testing/google-cse-demo" Component={GoogleCseDemo} />
        <Route path="testing/htmlform" Component={HtmlForm} />
        <Route path="testing/lookuptest" Component={LookupTest} />
        <Route path="testing/cardcreatorpopup" Component={CardCreatorPopup} />
        <Route path="testing/reset" Component={Reset} />
        <Route path="testing/cache" Component={DistributedCache} />
        <Route path="version" Component={Version} />
        <Route path="loader" element={null} />
        <Route path="callback" element={null} />
        <Route path="auth/*" element={null} />
        <Route path="modal/*" element={null} />
        <Route path="*" Component={NotFoundBanner} />
      </Route>
      <Route path="authcomplete" Component={AuthComplete} />
      <Route path="authenticate" Component={Authenticate} />
      <Route path="autherror" Component={AuthError} />
      <Route path="embedassistant" Component={Embedassistant} />
      <Route path="embedcard" Component={Embedcard} />
      <Route path="embedcreator" Component={Embedcreator} />
      <Route path="sitedown" Component={Sitedown} />
      <Route path="*" Component={NotFound} />
    </>,
  ),
);

function Root() {
  return (
    <Providers>
      <RouterProvider router={router} />
    </Providers>
  );
}

export default memo(Root);
