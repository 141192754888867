import { lazy } from 'react';

const WorldClock = lazy(() => import(/* webpackChunkName: "Cards" */ './WorldClock'));
const M365Events = lazy(() => import(/* webpackChunkName: "Cards" */ './M365Events'));
const TimeSheet = lazy(() => import(/* webpackChunkName: "Cards" */ './MyTimesheet'));
const M365Tasks = lazy(() => import(/* webpackChunkName: "Cards" */ './M365Tasks'));
const M365FollowedSites = lazy(() => import(/* webpackChunkName: "Cards" */ './M365FollowedSites'));
const M365OneNoteNotebooks = lazy(() => import(/* webpackChunkName: "Cards" */ './M365OneNoteNotebooks'));
const WebexConversations = lazy(() => import(/* webpackChunkName: "Cards" */ './WebexConversations'));
const Counter = lazy(() => import(/* webpackChunkName: "Cards" */ './Counter'));
const Compact = lazy(() => import(/* webpackChunkName: "Cards" */ './Compact'));
const ChartCard = lazy(() => import(/* webpackChunkName: "Chart" */ './ChartCard'));
const GridCard = lazy(() => import(/* webpackChunkName: "Cards" */ './GridCard'));
const IFrame = lazy(() => import(/* webpackChunkName: "IFrame" */ './IFrame'));
const KPICard = lazy(() => import(/* webpackChunkName: "ApexCharts" */ './KPICard'));
const LineChartCard = lazy(() => import(/* webpackChunkName: "ApexCharts" */ './LineChartCard'));
const BarChartCard = lazy(() => import(/* webpackChunkName: "ApexCharts" */ './BarChartCard'));
const PieChartCard = lazy(() => import(/* webpackChunkName: "ApexCharts" */ './PieChartCard'));

const catalog = {};

catalog.worldtime = WorldClock;
catalog['Today\'s Events'] = M365Events;
catalog['m365-events'] = M365Events;
catalog['msgraph-events'] = M365Events;
catalog['outlook-events'] = M365Events;
catalog['todays-events'] = M365Events;
catalog['My Timesheet'] = TimeSheet;
catalog['m365-tasks'] = M365Tasks;
catalog['m365-followed-sites'] = M365FollowedSites;
catalog['m365-onenote-notebooks'] = M365OneNoteNotebooks;
catalog['msgraph-tasks'] = M365Tasks;
catalog['msgraph-followed-sites'] = M365FollowedSites;
catalog['msgraph-onenote-notebooks'] = M365OneNoteNotebooks;
catalog['webex-conversations'] = WebexConversations;
catalog.counter1x1 = Counter;
catalog.counter = Counter;
catalog.ChartCard = ChartCard;
catalog.GridCard = GridCard;
catalog.compact = Compact;
catalog.iframe = IFrame;
catalog.ApexSparkline = KPICard;
catalog.KPICard = KPICard;
catalog.LineChartCard = LineChartCard;
catalog.BarChartCard = BarChartCard;
catalog.PieChartCard = PieChartCard;

export const cardCatalog = catalog;
