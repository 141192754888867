import { Box, Spinner } from 'grommet/components';
import { merge } from 'lodash';
import {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';

import config from '../config';
import { usePlatformMutation, usePlatformQueryClient } from '../hooks';
import { extractDateProperties } from '../hooks/useNotificationAnalysis';
import { ErrorReporter } from '../utils';
import { analyzeJson } from '../utils/generator/analyzePayload';
import { generateCardDefinition } from '../utils/generator/generateCard';

const ConnectorModal = lazy(() => import('../views/admin/CardDesigner/ConnectorModal'));
const fallback = (
  <Box alignSelf="center" justify="center">
    <Spinner />
  </Box>
);

function CardCreator({ id = 'new', onSave, initiallyExpanded = false }) {
  const [guid, setGuid] = useState(id);

  const { mutateAsync: fetchPayload, isPending: isFetchingPayload } = usePlatformMutation(`/card/adaptive/${guid || id}`);
  const { mutateAsync: savePayload, isPending: isSavingPayload } = usePlatformMutation('/card/adaptive', { method: 'POST' });
  const { mutateAsync: fetchData, isPending: isFetchingData } = usePlatformMutation(`/gateway/card/${guid}`);

  const { invalidateQueries } = usePlatformQueryClient();

  const [payload, setPayload] = useState();

  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [expanded, setExpanded] = useState(id === 'new' && initiallyExpanded);
  const [canShow, setCanShow] = useState(false);
  const [error, setError] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    fetchPayload().then((res) => {
      if (res.ErrorCode === 0) {
        setPayload(res.Data);
      } else {
        setError(`${res.ErrorCode}: ${res.Data.ErrorText}`);
      }
    });
  }, [fetchPayload]);

  useEffect(() => {
    const connector = searchParams.get('connector');
    const data = searchParams.get('data');
    if (payload) {
      if (connector || data) {
        setExpanded(false);
        setPayload((prev) => merge(prev, {
          properties: {
            notebook_config: {
              connector,
            },
            endpoint: {
              _id: data,
            },
          },
        }));
      }

      setCanShow(true);
    }
  }, [payload, searchParams]);

  const saveCb = useCallback(async (data) => {
    setCanShow(false);

    const res = await savePayload({
      id,
      title: data.title,
      content: data.template,
      properties: data.properties,
    });

    await invalidateQueries('/userworkplace/usercards');
    await invalidateQueries(`/userworkplace/usercard/${res.Data.guid}`);

    if (id === 'new' && !res.Data?.skipGenerating && (!data?.properties?.endpoint?._id.startsWith('notebook:') || (data?.properties?.endpoint?._id.endsWith(':_custom') || data?.properties?.endpoint?._id.endsWith(':hello')))) {
      setGuid(res.Data.guid);
      return;
    }

    if (window.opener) {
      window.opener.postMessage({
        '@type': 'card-created',
        id,
        ...res.Data,
        instanceId: searchParams.get('instanceId'),
      }, '*');
    }

    if (onSave) {
      onSave();
    } else {
      navigate(config.defaultPath);
    }
  }, [id, invalidateQueries, navigate, onSave, savePayload]);

  const hasGeneratedRef = useRef(false);

  useEffect(() => {
    if (guid === 'new' || id !== 'new') {
      console.log('Skipping generation, ID was not "new"');
      return;
    }

    if (hasGeneratedRef.current) {
      return;
    }

    console.log('Attempting card payload generation');

    hasGeneratedRef.current = true;

    fetchPayload().then((pl) => {
      let data = pl.Data;
      console.log('Fetching card data to generate adaptive payload');

      fetchData().then(async (res) => {
        if (res.ErrorCode === 0) {
          const content = generateCardDefinition(res.Data);
          const analysed = analyzeJson(res.Data);

          if (data.properties.configuration) {
            if (analysed.isList) {
              data.properties.configuration.notificationMode = 'listDate';
              data.properties.configuration.rootObject = analysed.rootObject;
              data.properties.configuration.fieldName = extractDateProperties(analysed.elements).defaultField;
            }

            for (let i = 0; i < content.body.length; i++) {
              if (content.body[i].id === 'expressions') {
                data.properties.configuration.card1x1 = 'compact';
                data.properties.configuration.card2x1 = 'compact';
              }
            }
          } else {
            const message = `Configuration property was unexpectedly not defined, so could not update properties on newly created card. Data was ${JSON.stringify(data)}`;
            console.warn(message);
            ErrorReporter.error(message);
          }

          console.log('Saving generated card payload');

          const finalPayload = await savePayload({
            id: guid,
            title: data.title,
            content,
            properties: data.properties,
          });

          data = finalPayload.Data;
        } else {
          console.error('Card data was not usable as it had a non-zero error code');
        }

        if (window.opener) {
          window.opener.postMessage({
            '@type': 'card-created',
            guid,
            'id': guid,
            ...data,
          }, '*');
        }

        if (onSave) {
          onSave();
        } else {
          navigate(config.defaultPath);
        }
      });
    });
  }, [fetchData, fetchPayload, guid, id, navigate, onSave, savePayload]);

  if (error) {
    return (
      <Box margin="medium" align="center" justify="center">
        An error occurred fetching this card:
        {` ${error}`}
      </Box>
    );
  }

  return (
    <Box margin="medium">
      {payload && canShow && !isFetchingData && !isFetchingPayload && !isSavingPayload
        ? (
            <Suspense fallback={fallback}>
              <ConnectorModal payload={payload} onSave={saveCb} initiallyExpanded={expanded} />
            </Suspense>
          )
        : fallback}
    </Box>
  );
}

export default CardCreator;
