import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import type { DehydrateOptions } from '@tanstack/react-query';
import { QueryClient } from '@tanstack/react-query';
import type { PersistQueryClientOptions } from '@tanstack/react-query-persist-client';

type QueryError = Error & { cause: number };

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const retry = [429, 504].includes((error as QueryError)?.cause) && failureCount < 6;
        console.log(`retry ${retry}`);
        return retry;
      },
      retryDelay: (attemptIndex) => {
        const gap = 1000 * 2 ** (1 + Math.min(attemptIndex, 3));
        const delay = gap + Math.floor(Math.random() * gap);
        console.log(`retry# ${attemptIndex}: ${delay}`);
        return delay;
      },
    },
  },
});

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

export const dehydrateOptions: DehydrateOptions = {
  shouldDehydrateQuery: ({ queryKey, state }) => {
    if ([429].includes((state.error as QueryError)?.cause)) {
      return false;
    }

    if (!queryKey.length) {
      return true;
    }

    if ((queryKey[0] as string).startsWith('/cardcatalog/connectorstatus:')) {
      return false;
    }

    return true;
  },
};

export const persistOptions: Omit<PersistQueryClientOptions, 'queryClient'> = { persister, dehydrateOptions };

broadcastQueryClient({
  queryClient,
  broadcastChannel: 'adenin-digital-assistant',
});
