import { Buffer } from 'buffer';

import { Close } from 'grommet-icons';
import {
  Box,
  Button,
  Grommet,
  Layer,
  Notification,
  Spinner,
} from 'grommet/components';
import {
  Suspense,
  lazy,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { dispatch } from 'use-bus';

import { useIsMutating } from '@tanstack/react-query';

import config, { hostUrl } from '../../config';
import { useSessionStore } from '../../hooks';
import UpgradeArrow from '../../icons/upgrade_arrow.svg?react';
import theme from '../../theme';
import { isSSR } from '../../utils';
import Splash from '../SplashScreen';
import { isAdminPage } from '../layout/AdminMenu';
import { iframeURLChange } from './utils';

const AdminMenu = lazy(() => import('../layout/AdminMenu'));

const DemoMode = lazy(() => import('../../views/admin/DemoMode'));
const DeleteAccount = lazy(() => import('../../views/admin/DeleteAccount'));
const GridView = lazy(() => import('../../views/admin/GridView'));
const ConnectorConfiguration = lazy(() => import('../../views/admin/ConnectorConfiguration'));

const StyledIFrame = styled.iframe`
  height: -webkit-fill-available;
  height: 100%;
  border-radius: 4px;
`;

const AbsoluteContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

function IFrameLayer({ show, url, onClose: closeCb }) {
  const iframeRef = useRef();
  const modalRef = useRef(null);

  const [width, setWidth] = useState(isSSR ? 1201 : window.innerWidth);

  const activeMutationCount = useIsMutating();
  const session = useSessionStore((state) => state.session);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isSSR) {
      const resize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', resize);
      return () => window.removeEventListener('resize', resize);
    }
  }, []);

  useEffect(() => {
    if (!url || !show) {
      return;
    }

    let path = url;

    if (path.startsWith('http')) {
      const urlObj = new URL(url);
      path = urlObj.pathname;
    }

    const modalPart = path.replace('/app/modal', '');

    history.replaceState({}, '', `/app/modal${modalPart}`);

    dispatch({ type: 'modalpath.change', path: modalPart });
  }, [show, url]);

  useEffect(() => {
    const messageListener = (e) => {
      const msg = e.data;
      if (msg.href) {
        if (msg.target === '_blank') {
          window.open(msg.href, '_blank');
        } else {
          window.location.href = msg.href;
        }
      }
    };

    window.addEventListener('message', messageListener);
    return () => window.removeEventListener('message', messageListener);
  }, []);

  const full = width <= 1200;
  let style = {
    width: '100%',
  };

  if (!full) {
    style = {
      ...style,
      height: 'calc(100vh - 30px)',
      minWidth: '700px',
      maxWidth: '1100px',
      maxHeight: '900px',
      overflowY: 'auto',
    };
  }

  const onClose = () => {
    if (closeCb) {
      closeCb();
      return;
    }

    dispatch('iframe.close');
    navigate(config.defaultPath);
  };

  let AdminModule = null;
  let needsViewId = false;
  let idPropName = 'viewId';
  let viewId = '';

  if (url?.includes('/app/admin/demomode')) {
    AdminModule = DemoMode;
  } else if (url?.includes('/app/admin/deleteaccount')) {
    AdminModule = DeleteAccount;
  } else if (url?.includes('/app/connector/')) {
    needsViewId = true;
    idPropName = 'name';
    AdminModule = ConnectorConfiguration;
  } else if (url?.includes('/app/grid')) {
    needsViewId = true;
    AdminModule = GridView;
  }

  if (needsViewId) {
    const parts = url.split('/');
    viewId = parts[parts.length - 1];

    if (viewId.includes('?')) {
      viewId = viewId.substring(0, viewId.indexOf('?'));
    }
  }

  const moduleProps = {
    [idPropName]: viewId,
  };

  let finalUrl = null;

  if (url?.toLowerCase().includes('/app/channels')) {
    finalUrl = `${config.directoryUrl}/digital-assistant/channels`;

    if (url?.toLowerCase().includes('sharepoint')) {
      finalUrl += '/sharepoint';
    }

    if (url?.toLowerCase().includes('viva')) {
      finalUrl += '/viva-connections';
    }

    if (url?.toLowerCase().includes('teams')) {
      finalUrl += '/microsoft-teams';
    }

    if (url?.toLowerCase().includes('chatgpt')) {
      finalUrl += '/chatgpt';
    }

    if (url?.toLowerCase().includes('wordpress')) {
      finalUrl += '/wordpress';
    }

    if (url?.toLowerCase().includes('alexa')) {
      finalUrl += '/alexa';
    }

    if (url?.toLowerCase().includes('slack')) {
      finalUrl += '/slack-app';
    }

    if (url?.toLowerCase().includes('cisco-webex')) {
      finalUrl += '/cisco-webex';
    }

    if (url?.toLowerCase().includes('chrome-extension')) {
      finalUrl += '/chrome-extension';
    }

    if (url?.toLowerCase().includes('workplace')) {
      finalUrl += '/workplace-by-facebook-bot';
    }

    finalUrl += '?branding=app&';

    if (url.indexOf('?') > -1) {
      finalUrl += url.substring(url.indexOf('?') + 1);
    }
  }

  if (url?.toLowerCase().includes('actemplatestart')) {
    finalUrl = `${config.directoryUrl}/blog/adaptive-card-templates-for-businesses/?branding=app`;
  }

  if (url?.toLowerCase().includes('/app/directory')) {
    finalUrl = `${config.directoryUrl}/digital-assistant/apps`;
    if (url.indexOf('?') > -1) {
      const queryParams = new URLSearchParams(url.substring(url.indexOf('?')));
      if (queryParams.has('app')) {
        finalUrl += `/${queryParams.get('app')}`;
        queryParams.delete('app');
      }
      queryParams.set('branding', 'app');
      finalUrl += `?${queryParams.toString()}`;
    } else {
      finalUrl += '?branding=app';
    }
  }

  const [showFeatureBanner, setShowFeatureBanner] = useState(false);

  if (iframeRef.current && !finalUrl && show) {
    iframeURLChange(iframeRef.current, (newHref) => {
      if (newHref?.toLowerCase().includes('roles') && !session?.usageStatus?.Plan?.Features?.ManageRoles) {
        setShowFeatureBanner(true);
      } else {
        setShowFeatureBanner(false);
      }

      if (newHref?.toLowerCase().includes('designer') || newHref.toLowerCase().includes('notebookeditor')) {
        window.location.href = newHref;
        return;
      }

      const refPath = iframeRef.current.contentWindow.location.pathname
        + iframeRef.current.contentWindow.location.search.replace('&frame=1', '').replace('?frame=1', '')
        + iframeRef.current.contentWindow.location.hash;

      history.replaceState({}, '', `/app/modal${refPath}`);

      if (newHref?.includes('/app/admin') || newHref?.includes('/app/grid')) {
        dispatch({ type: 'iframe.open', url: hostUrl + refPath });
      }

      if (newHref?.includes(config.defaultPath)) {
        dispatch('iframe.close');
        navigate(config.defaultPath);
      }
    });
  }

  return show
    ? (
        <Layer background={location.pathname.includes('admin') ? 'white' : '#EFEFEF'} full={full} onEsc={onClose} onClickOutside={onClose} modal ref={modalRef} style={style}>
          {showFeatureBanner && (
            <Notification
              status="unknown"
              global
              icon={<UpgradeArrow />}
              message="Upgrade to unlock this feature. Roles and more"
              actions={[{ href: '/app/subscription', label: 'Get feature', margin: { horizontal: 'small' } }]}
            />
          )}
          {full && (
            <AbsoluteContainer>
              <Button icon={<Close size="16px" />} onClick={onClose} />
            </AbsoluteContainer>
          )}
          {AdminModule == null
            ? (
                <StyledIFrame
                  id="spa-iframe-modal"
                  ref={iframeRef}
                  src={finalUrl || `${hostUrl}/app/loader?url=${Buffer.from(url).toString('base64')}`}
                  title={url}
                  scrolling="yes"
                  allow="clipboard-read; clipboard-write *"
                  sandbox="allow-top-navigation allow-forms allow-scripts allow-same-origin allow-popups"
                  frameBorder="0"
                />
              )
            : (
                <Grommet
                  theme={theme}
                  themeMode="light"
                  background={location.pathname.includes('admin') ? 'white' : 'page-background'}
                  id="pageContainer"
                  style={isAdminPage(url) && !url.toLowerCase().includes('grid') ? undefined : { minHeight: '100%', overflow: 'auto' }}
                >
                  <Suspense fallback={<Splash />}>
                    <Box flex direction="row" fill>
                      {isAdminPage(url) && <AdminMenu />}
                      <AdminModule {...moduleProps} />
                    </Box>
                  </Suspense>
                  {activeMutationCount > 0 && url?.includes('demomode') && (
                    <Layer animate={false} background="rgba(0,0,0,0)" full target={modalRef.current}>
                      <Box fill align="center" justify="center">
                        <Spinner size="large" color="brand" />
                      </Box>
                    </Layer>
                  )}
                </Grommet>
              )}
        </Layer>
      )
    : null;
}

export default IFrameLayer;
