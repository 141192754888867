const success = [
  'Here is what I found for you',
  'Here it is',
  'Check out what I have found for you',
  'Let me see, this is what I got',
  'Here is a Card I found for this',
  'That\'s easy, here is what I got',
  'I think this is what you have asked me for',
  'I have found just what you were asking for',
];

const ambiguous = [
  'I found a few similar questions',
  'I\'ve got a few options for you',
  'These are the commands I can help you with',
  'Pick which one of these you want',
  'Which one of these would you like me to answer?',
];

const failed = [
  'I\'m sorry, but I couldn\'t find anything. Please try and ask me differently',
  'There were no results for this question. Can you ask me again?',
  'Apologies, but I didn\'t get any results for this. Can you ask me something else?',
  'I searched every one of my circuits, but I\'m afraid I couldn\'t find anything for this specific question',
  'Sorry, I didn\'t understand this. I\'m still learning',
];

export default { success, ambiguous, failed };
