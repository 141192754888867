import {
  Box,
  Grommet,
  Image,
  Text,
} from 'grommet';

import robboError from '../images/robo_404_800px.png';
import theme from '../theme';
import { isSSR } from '../utils';

export default function Sitedown() {
  return (
    <Grommet full theme={theme} themeMode="light" background="page-background" id="pageContainer">
      <Box fill flex align="center" justify="center" gap="small" pad="medium">
        <Box flex="shrink">
          <Image src={robboError} width={350} fit="contain" alt="Error" />
        </Box>
        <Text weight={500} size="large" textAlign="center" style={{ minHeight: '28px' }}>
          {!isSSR ? window?.__sitedownMessage : ''}
        </Text>
      </Box>
    </Grommet>
  );
}
