import {
  Component,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import useBus from 'use-bus';

import config, { apiUrl, defaultFetchOpts } from '../../config';
import { useSessionStore } from '../../hooks/store';
import authorize from './helpers/authorize';
import { fetchToken } from './helpers/fetchToken';
import { getCodeFromLocation } from './helpers/getCodeFromLocation';
import { getVerifierState } from './helpers/getVerifierState';
import { removeCodeFromLocation } from './helpers/removeCodeFromLocation';
import { removeStateFromStorage } from './helpers/removeStateFromStorage';

export default ({
  clientId,
  clientSecret,
  authorizeEndpoint,
  tokenEndpoint,
  scopes = [],
  storage = typeof window !== 'undefined' ? sessionStorage : undefined,
  fetch = typeof window !== 'undefined' ? window.fetch : undefined,
  busyIndicator = null,
}) => {
  const context = createContext({});
  const { Provider } = context;

  class Authenticated extends Component {
    componentDidMount() {
      const { ensureAuthenticated } = this.context;
      ensureAuthenticated();
    }

    componentDidUpdate() {
      this.componentDidMount();
    }

    render() {
      const { token } = this.context;
      const { children } = this.props;

      if (config.useTokenAuth && !token) {
        return busyIndicator;
      }
      return children;
    }
  }

  Authenticated.contextType = context;

  const useToken = () => {
    const { token } = useContext(context);

    if (!config.useTokenAuth) {
      return { access_token: null };
    }

    if (!token) {
      console.warn('Trying to useToken() while not being authenticated.\nMake sure to useToken() only inside of an <Authenticated /> component.');
    }
    return token;
  };

  return {
    AuthContext: ({ children }) => {
      const [token, setToken] = useState(null);
      const setSession = useSessionStore((state) => state.setSession);

      const storedToken = storage?.getItem('pkce_token');

      if (storedToken && storedToken !== 'null' && JSON.stringify(token) !== storedToken) {
        setToken(JSON.parse(storedToken));
      }

      useEffect(() => {
        storage?.setItem('pkce_token', token ? JSON.stringify(token) : null);
      }, [token]);

      useBus('pkce.clear', async (event) => {
        storage?.setItem('pkce_token', null);

        if (event.logoutUrl) {
          location.href = event.logoutUrl;
        } else {
          location.reload();
        }
      });

      // if we have no token, but code and verifier are present,
      // then we try to swap code for token
      useEffect(() => {
        if (config.useTokenAuth && !token) {
          const code = getCodeFromLocation({ location: window.location });
          const state = getVerifierState({ clientId, storage });

          if (location.hostname === 'localhost') {
            console.log('PKCE token requested');
          }

          if (code && state) {
            fetchToken({
              clientId,
              clientSecret,
              tokenEndpoint,
              code,
              state,
              fetch,
            })
              .then(async (newToken) => {
                defaultFetchOpts.headers.Authorization = `Bearer ${newToken.access_token}`;

                const response = await fetch(`${apiUrl}/session/myprofile`, defaultFetchOpts);
                const data = await response.json();

                window.$at.session = data.Data;

                setSession(data.Data);
                setToken(newToken);

                if (location.hostname === 'localhost') {
                  console.log('PKCE token received', newToken);
                }
              })
              .then(async () => {
                removeCodeFromLocation();
                removeStateFromStorage({ clientId, storage });
                const params = new URLSearchParams(window.location.search);
                if (params.has('returnUrl') && !params.get('returnUrl').includes('callback')) {
                  location.href = params.get('returnUrl');
                } else {
                  location.href = config.defaultPath || '/app/assistant/board';
                }
              })
              .catch((e) => {
                console.error(e);
              });
          }
        }
      }, [setSession, storedToken?.access_token, token]);

      const ensureAuthenticated = () => {
        if (!config.useTokenAuth) return;
        const code = getCodeFromLocation({ location: window.location });
        if (!token && !code) {
          authorize({ authorizeEndpoint, clientId, scopes });
        }
      };

      return <Provider value={{ token, ensureAuthenticated }}>{children}</Provider>;
    },
    Authenticated,
    useToken,
    withPkce(WrappedComponent) {
      return function ComponentWithPkce(props) {
        return (
          <Authenticated>
            <WrappedComponent {...props} />
          </Authenticated>
        );
      };
    },
  };
};
